import { PlanId, PlanUnit, SubscriptionPeriod } from '../typings/plan';
import { DTPlanId, SelectedReferralLeadsPlan } from '../typings/plan';

const getPlanDetails = (selectedPlan: SelectedReferralLeadsPlan) => {
  let planId;
  let subscriptionPeriod;

  if (selectedPlan.planId === DTPlanId.STANDARD) {
    planId = PlanId.STANDARD;
  } else if (selectedPlan.planId === DTPlanId.PRO) {
    planId = PlanId.PRO;
  } else if (selectedPlan.planId === DTPlanId.ENTERPRISE) {
    planId = PlanId.ENTERPRISE;
  }

  if (selectedPlan.period === 1 && selectedPlan.unit === PlanUnit.MONTH) {
    subscriptionPeriod = SubscriptionPeriod.MONTHLY;
  } else if (
    selectedPlan.period === 3 &&
    selectedPlan.unit === PlanUnit.MONTH
  ) {
    subscriptionPeriod = SubscriptionPeriod.QUARTERLY;
  } else if (selectedPlan.period === 1 && selectedPlan.unit === PlanUnit.YEAR) {
    subscriptionPeriod = SubscriptionPeriod.YEARLY;
  }
  return { planId, subscriptionPeriod };
};

export default getPlanDetails;
