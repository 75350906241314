import { get, post } from '../../../infra/rest';
import {
  ReferralPartnerOnboardingBusinessDetails,
  ReferralPartnerOnboardingPersonalDetails,
  ReferralPartnerOnboardingBankDetails,
  GetChannelPartnerReferralDetailsResponse,
} from '../types';

export const postReferralBusinessDetails = async (
  payload: ReferralPartnerOnboardingBusinessDetails,
  isInternational: boolean
): Promise<{
  success: boolean;
} & GetChannelPartnerReferralDetailsResponse> => {
  const form = new FormData();
  form.append('contactName', payload.businessPersonName ?? '');
  form.append('contactNumber', payload.businessContactDetails ?? '');
  form.append('contactEmail', payload.businessEmailId ?? '');
  form.append('companyName', payload.companyName ?? '');
  if (!isInternational && payload.gstNumber?.trim() !== '') {
    form.append('gstNumber', payload.gstNumber ?? '');
  }
  if (payload.gstCertificate && payload.hasGst) {
    form.append(
      typeof payload.gstCertificate === 'string'
        ? 'gstCertificateUrl'
        : 'gstCertificate',
      payload.gstCertificate
    );
  }else{
    form.append('gstCertificateUrl', '');
    form.append('gstCertificate', '');
  }

  if (payload.msmeRegistrationCertificate && payload.hasMsmeRegistration) {
    form.append(
      typeof payload.msmeRegistrationCertificate === 'string'
        ? 'msmeCertificateUrl'
        : 'msmeCertificate',
      payload.msmeRegistrationCertificate
    );
  }else{
    form.append('msmeCertificateUrl', '');
    form.append('msmeCertificate', '');
  }

  if (payload.noEstablishmentDeclaration) {
    form.append(
      typeof payload.noEstablishmentDeclaration === 'string'
        ? 'noPermEstProofUrl'
        : 'noPermEstProof',
      payload.noEstablishmentDeclaration
    );
  }

  if (payload.form10f) {
    form.append(
      typeof payload.form10f === 'string' ? 'form10FProofUrl' : 'form10FProof',
      payload.form10f
    );
  }

  return post<{
    success: boolean;
  } & GetChannelPartnerReferralDetailsResponse>(
    `/v1/channel-partner/referral/business-details`,
    {
      body: form,
      useDefaultHeaders: false,
    },
    true
  );
};

export const postReferralPersonalDetails = async (
  payload: ReferralPartnerOnboardingPersonalDetails,
  isInternational: boolean,
  isBusiness: boolean
): Promise<{
  success: boolean;
} & GetChannelPartnerReferralDetailsResponse> => {
  const form = new FormData();

  form.append('fullName', payload.fullName ?? '');
  form.append('email', payload.email ?? '');
  form.append('address', payload.address ?? '');
  form.append('countryCode', payload.countryCode ?? '');
  form.append('state', payload.state ?? '');
  form.append('city', payload.city ?? '');
  form.append('pinCode', payload.pincode ?? '');

  if (isInternational) {
    form.append('taxIdNumber', payload.taxIdentificationNumber ?? '');
    if (payload.taxIdentificationDocument) {
      form.append(
        typeof payload.taxIdentificationDocument === 'string'
          ? 'taxIdProofUrl'
          : 'taxIdProof',
        payload.taxIdentificationDocument
      );
    }
  } else {
    form.append('panNumber', payload.panNumber ?? '');
    if (payload.pan) {
      form.append(
        typeof payload.pan === 'string' ? 'panProofUrl' : 'panProof',
        payload.pan
      );
    }
  }
  form.append('referralPartnerType', isBusiness ? 'BUSINESS' : 'INDIVIDUAL');

  if (payload.addressProof) {
    form.append(
      typeof payload.addressProof === 'string'
        ? 'addressProofUrl'
        : 'addressProof',
      payload.addressProof
    );
  }

  return post<{
    success: boolean;
  } & GetChannelPartnerReferralDetailsResponse>(
    `/v1/channel-partner/referral/details`,
    {
      body: form,
      useDefaultHeaders: false,
    },
    true
  );
};

export const postReferralBankDetails = async (
  payload: ReferralPartnerOnboardingBankDetails,
  isInternational: boolean
): Promise<{
  success: boolean;
} & GetChannelPartnerReferralDetailsResponse> => {
  const form = new FormData();

  form.append('bankName', payload.bankName ?? '');
  form.append('bankAddress', payload.bankAddress ?? '');
  form.append('bankAccountNumber', payload.bankAccountNumber ?? '');

  if (isInternational) {
    form.append('swiftCode', payload.swiftCode ?? '');
  } else {
    form.append('ifscCode', payload.ifscCode ?? '');
  }

  if (!isInternational && payload.cancelChequeScan) {
    form.append(
      typeof payload.cancelChequeScan === 'string'
        ? 'cancelledChequeUrl'
        : 'cancelledCheque',
      payload.cancelChequeScan
    );
  }

  if (isInternational && payload.additionalInformation) {
    form.append('additionalInfo', payload.additionalInformation);
  }

  if (isInternational && payload.accountHolderName) {
    form.append('accountHolderName', payload.accountHolderName);
  }

  return post<{
    success: boolean;
  } & GetChannelPartnerReferralDetailsResponse>(
    `/v1/channel-partner/referral/bank-details`,
    {
      body: form,
      useDefaultHeaders: false,
    },
    true
  );
};

export const getReferralPartnerAllDetails =
  async (): Promise<GetChannelPartnerReferralDetailsResponse> => {
    return get<GetChannelPartnerReferralDetailsResponse>(
      `/v1/channel-partner/referral/details`,
      undefined,
      true
    );
  };
