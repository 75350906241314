import isEqual from 'lodash.isequal';

type MappingType<T> = {
  [K in keyof T]: string;
};

interface CompareChangesOptions<TApiResponse, TEditForm> {
  apiResponse: TApiResponse | null | undefined;
  editForm: TEditForm | null | undefined;
  mapping: Partial<Record<keyof TApiResponse, keyof TEditForm>>;
  customMappings?: {
    // For handling special cases like computed fields
    [K in keyof TEditForm]?: (apiResponse: TApiResponse) => unknown;
  };
  // For handling fields that should be copied as-is when missing in mapping
  copyMissingFields?: boolean;
  // For handling null/undefined values consistently
  treatEmptyAsNull?: boolean;
}

/**
 * Generic function to compare changes between API response and edit form data
 * @param options Configuration options for the comparison
 * @returns boolean indicating if there are changes
 */
export const compareChanges = <TApiResponse extends Record<string, any>, TEditForm extends Record<string, any>>({
  apiResponse,
  editForm,
  mapping,
  customMappings = {},
  copyMissingFields = false,
  treatEmptyAsNull = true,
}: CompareChangesOptions<TApiResponse, TEditForm>): boolean => {
  // Early return if either object is missing
  if (!apiResponse || !editForm) {
    return false;
  }

  // Initialize saved state object
  const savedState: Partial<Record<keyof TEditForm, unknown>> = {};

  // Process standard mappings
  Object.entries(mapping).forEach(([apiKey, formKey]) => {
    if (formKey && apiKey in apiResponse) {
      const value = apiResponse[apiKey as keyof TApiResponse];
      savedState[formKey as keyof TEditForm] = treatEmptyAsNull && (value === undefined || value === '') 
        ? null 
        : value;
    }
  });

  // Process custom mappings
  Object.entries(customMappings).forEach(([formKey, mapFn]) => {
    if (mapFn) {
      savedState[formKey as keyof TEditForm] = mapFn(apiResponse);
    }
  });

  // Copy missing fields if enabled
  if (copyMissingFields) {
    Object.keys(editForm).forEach((key) => {
      const formKey = key as keyof TEditForm;
      if (!(formKey in savedState)) {
        const value = apiResponse[formKey as keyof TApiResponse];
        savedState[formKey] = treatEmptyAsNull && (value === undefined || value === '') 
          ? null 
          : value;
      }
    });
  }

  // Compare each field
  return Object.keys(editForm).some((key) => {
    const formKey = key as keyof TEditForm;
    const editValue = treatEmptyAsNull && (editForm[formKey] === undefined || editForm[formKey] === '') 
      ? null 
      : editForm[formKey];
    const savedValue = savedState[formKey];
    if(!editValue && !savedValue) return false;
    return !isEqual(editValue, savedValue);
  });
}; 