export const GREEN = '#29a471';

export const ERROR_COLOR = '#ff1744';

export interface Country {
  id: string;
  countryCode: string;
  label: string;
  symbol: string;
}

export interface Geo {
  geo: {
    city: string;
    country: string;
    countryCode: string;
    currency: string;
    region: string;
    regionName: string;
    status: string;
    timezone: string;
    zip: string;
    currencyCode: string;
  };
}

export enum ChannelPartnerStateType {
  SET_PARTNER_DETAILS = 'SET_PARTNER_DETAILS',
  SET_PARTNER_BILLING = 'SET_PARTNER_BILLING',
  WAITING_FOR_REVIEW = 'WAITING_FOR_REVIEW',
  SETUP_RECHARGE = 'SETUP_RECHARGE',
  RECHARGE_DUE = 'RECHARGE_DUE',
  NO_ACTION = 'NO_ACTION',
  APPLY_FOR_REVIEW = 'APPLY_FOR_REVIEW',
}
export interface ChannelPartnerState {
  channelPartnerId: string;
  dateCreated: string;
  dateUpdated: string;
  state: ChannelPartnerStateType;
  approvalStatus: string | null;
  currentStateMetadata: {
    SET_PARTNER_BILLING: ChannelPartnerStateResponseBillingMetaType;
    SET_PARTNER_DETAILS: ChannelPartnerStateResponseDetailsMetaType;
  };
}
export interface ChannelPartnerStateResponseDetailsMetaType {
  appName: string;
  name: string;
  logoUrl: string;
  ownerId: string;
  domain: string;
  supportNumber: string;
}
export interface ChannelPartnerStateResponseBillingMetaType {
  countryCode: string;
  state: string;
  city: string;
  pinCode: string;
  email: string;
  billingTaxNumber: string;
  fullName: string;
  address: string;
}
export const ERROR_BORDER = '#ef5350';

export enum GEO_IP_STORAGE_KEYS {
  GEO_DATA = 'GEO_DATA',
}

export enum ChannelPartnerType {
  WHITELABEL = 'WHITELABEL',
  REFERRAL = 'REFERRAL'
}

export interface ChannelPartnerConfigType {
  id: string;
  name: string;
  email: string;
  city: string;
  countryCode: string;
  domain: string;
  walletCurrency: string;
  taxType: string;
  billingTaxNumber: string;
  supportNumber: string;
  logoUrl: string;
  pinCode: string;
  state: string;
  appName: string;
  dateCreated: string;
  dateUpdated: string;
  ownerId: string;
  phone: string;
  partnerType: ChannelPartnerType;
}
