import { atom } from 'jotai';
import {
  OnboardingBillingDetails,
  OnboardingCompanyDetails,
} from '../../../onboarding/types';
import {
  getCountryCodeFromLocalStorage,
  getCountryFromCountryCode,
} from '../../../../utils/countries-mapper';
import { createRequestAtom } from '../../../../utils/atoms/request-atom';
import { ChannelPartnerState } from '../../../../types';

export const editWhitelabelPartnerWebsiteInfoAtom =
  atom<OnboardingCompanyDetails>({
    name: '',
    domain: '',
    supportNumber: '',
    logo: null,
    savedCountry: getCountryFromCountryCode(
      getCountryCodeFromLocalStorage() ?? 'IN'
    )!,
  });

export const whitelabelPartnerProfileDetailsAtom =
  createRequestAtom<ChannelPartnerState | undefined>();

export const editWhitelabelPartnerBillingDetailsAtom =
  atom<OnboardingBillingDetails>({
    countryCode: 'IN',
    address: '',
    state: '',
    city: '',
    pinCode: '',
    email: '',
    billingTaxNumber: '',
    fullName: '',
  });

export const editWhitelabelPartnerProfileErrorAtom = atom<boolean>(false);

export const editWhitelabelPartnerProfileLoadingAtom = atom<boolean>(false);

export type ErrorFields = Record<string, string | null>;

export const errorFieldsInitialState: ErrorFields = {
  name: null,
  domain: null,
  supportNumber: null,
  logo: null,
  countryCode: null,
  address: null,
  state: null,
  city: null,
  pinCode: null,
  email: null,
  billingTaxNumber: null,
  fullName: null,
};

export const errorFieldsAtom = atom<ErrorFields>(errorFieldsInitialState);