import { SxProps } from '@mui/material';

export const DISABLED_INPUT_STYLE: SxProps = {
  // Base styles for the disabled input container
  background: 'linear-gradient(0deg, #F7F7F7 0%, #F7F7F7 100%), #FFF',
  cursor: 'not-allowed',
  borderRadius: '8px',

  // Styles for the input element when disabled
  '& .MuiInputBase-input.Mui-disabled': {
    background: 'linear-gradient(0deg, #F7F7F7 0%, #F7F7F7 100%), #FFF',
    cursor: 'not-allowed',
    borderRadius: '8px',
  },

  // Styles for the outlined input to ensure coorect border
  '& .MuiOutlinedInput-root.Mui-disabled': {
    '& fieldset': {
      borderColor: '#E0E0E9', // coorect border for disabled state
      borderWidth: '1px', // Ensure border is visible
    },
    '&:hover fieldset': {
      borderColor: '#E0E0E9', // Maintain border on hover
    },
  },
};