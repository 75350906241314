/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Typography } from '@mui/material';

const CountBadge = ({ totalCount }: { totalCount: number }) => {
  return (
    <div
      css={css`
        display: flex;
        padding: 2px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 32px;
        background: rgba(0, 122, 90, 0.1);
      `}
    >
      <Typography
        css={css`
          color: #3f906d;
          font-family: Inter;
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
        `}
      >
        {totalCount} total
      </Typography>
    </div>
  );
};

export default CountBadge;
