import { put } from '../../../infra/rest';
import { OnboardingBillingDetails, OnboardingCompanyDetails } from '../types';

export const putCompanyDetails = async (
  partnerId: string,
  country: string,
  payload: OnboardingCompanyDetails
): Promise<{ success: boolean }> => {
  const form = new FormData();
  let errorString = '';
  if (!payload.logo) {
    errorString = 'Logo is required';
  } else {
    if (typeof payload.logo === 'string') {
      form.append('logoUrl', payload.logo!);
    } else {
      form.append('logo', payload.logo!, 'file');
    }
  }

  if (!payload.name) {
    errorString += ' Name is required';
  }
  form.append('name', payload.name);

  if (!payload.supportNumber) {
    errorString += ' Invalid phone';
  }
  form.append('supportNumber', country + payload.supportNumber);

  if (!payload.domain) {
    errorString += ' domain must be a URL address';
  }
  form.append('domain', payload.domain);

  return put<{ success: boolean }>(
    `/v1/channel-partner/${partnerId}/details`,
    {
      body: form,
      useDefaultHeaders: false,
    },
    true
  );
};

export const putCompanyBillingDetails = async (
  partnerId: string,
  payload: OnboardingBillingDetails
): Promise<{ success: boolean }> => {
  return put<{ success: boolean }>(
    `/v1/channel-partner/${partnerId}/billing`,
    {
      body: JSON.stringify({ ...payload, currencyCode: 'INR' }),
    },
    true
  );
};
