import { Country } from '../../../types';

export interface OnboardingCompanyDetails {
  name: string;
  domain: string;
  supportNumber: string;
  logo: File | string | null;
  savedCountry: Country;
}

export interface OnboardingBillingDetails {
  countryCode: string;
  state: string;
  city: string;
  pinCode: string;
  email: string;
  billingTaxNumber: string;
  address: string;
  fullName: string;
}

export interface RegionOptionData {
  id: string;
  name: string;
  cityId?: string;
}
export interface CountriesOptionAPIData {
  allCountries?: RegionOptionData[];
  allStates?: RegionOptionData[];
  allCities?: RegionOptionData[];
}

export interface SuccessResponse {
  success: boolean;
}

export enum ReferralPartnerStateType {
  SET_PARTNER_DETAILS = 'SET_PARTNER_DETAILS',
  SET_PARTNER_BILLING = 'SET_PARTNER_BILLING',
  SET_BUSINESS_DETAILS = 'SET_BUSINESS_DETAILS',
  WAITING_FOR_REVIEW = 'WAITING_FOR_REVIEW',
  NO_ACTION = 'NO_ACTION',
  APPLY_FOR_REVIEW = 'APPLY_FOR_REVIEW',
}
