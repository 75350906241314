import { deleteReq, post } from '../../../infra/rest';
import {
  CreateReferralPartnerLeadDetailsResponseType,
  SaveNewReferralPartnerLeadDetailsRequesType,
} from '../typings';

export const deleteReferralLeadApi = async ({
  leadId,
  channelPartnerId,
}: {
  leadId: string;
  channelPartnerId: string;
}): Promise<void> => {
  const url = `/v1/channel-partner/leads/${leadId}`;
  return deleteReq<void>(
    url,
    {
      useDefaultHeaders: false,
      body: JSON.stringify({
        channelPartnerId,
        leadId
      }),
    },
    true,
    false,
    false,
    true,
    true
  );
};

export const postNewReferralPartnerLeadDetails = async (
  payload: SaveNewReferralPartnerLeadDetailsRequesType
): Promise<CreateReferralPartnerLeadDetailsResponseType> => {
  return post<CreateReferralPartnerLeadDetailsResponseType>(
    `/v1/channel-partner/referral/customer`,
    {
      body: JSON.stringify(payload),
    },
    true
  );
};
