/* eslint-disable react-hooks/exhaustive-deps */

/** @jsxImportSource @emotion/react */
import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import CustomSelect, { CustomSelectOptions } from '../../components/input/CustomSelect';
import WithLabel from '../../components/input/WithLabel';
import { getAddressDetails } from '../onboarding/requests/shippingDetails';
import InfoOutlineIcon from '@mui/icons-material/InfoOutlined';
import { css } from '@emotion/react';

interface CountryStateCitySelectProps {
  children: React.ReactNode;
  countryCode: string | undefined;
  state: string | undefined;
  cityError?: boolean;
  stateError?: boolean;
  countryError?: boolean;
  city: string | undefined;
  onChange: ({
    countryCode,
    state,
    city,
  }: {
    countryCode?: string;
    state?: string;
    city?: string;
  }) => void;
  disableCountrySelect?: boolean;
  showDisbledCountryInfo?: boolean;
}

const CountryStateCitySelect: React.FC<CountryStateCitySelectProps> = ({
  children,
  countryCode,
  state,
  city,
  onChange,
  cityError,
  stateError,
  countryError,
  disableCountrySelect,
  showDisbledCountryInfo = false
}) => {
  const [countries, setCountries] = useState<CustomSelectOptions[] | []>([]);
  const [states, setStates] = useState<CustomSelectOptions[] | []>([]);
  const [cities, setCities] = useState<CustomSelectOptions[] | []>([]);
  const [selectedItems, setSelectedItems] = useState({
    countryCode: countryCode ?? '',
    state: state ?? '',
    city: city ?? '',
  });
  const [isUserChange, setIsUserChange] = useState(false);

  const handleSelectChange = (key: string, value: string) => {
    setIsUserChange(true);
    if (key === 'countryCode') {
      setSelectedItems({
        countryCode: value,
        state: '',
        city: '',
      });
      setStates([]);
      setCities([]);
    } else if (key === 'state') {
      setSelectedItems({
        ...selectedItems,
        [key]: value,
        city: '',
      });
      setCities([]);
    } else {
      setSelectedItems({
        ...selectedItems,
        [key]: value,
      });
    }
  };

  // Sync props with state only when props change and it's not a user change
  useEffect(() => {
    if (!isUserChange) {
      const newSelectedItems = {
        countryCode: countryCode ?? '',
        state: state ?? '',
        city: city ?? '',
      };
      // Only update if props actually changed
      if (
        newSelectedItems.countryCode !== selectedItems.countryCode ||
        newSelectedItems.state !== selectedItems.state ||
        newSelectedItems.city !== selectedItems.city
      ) {
        setSelectedItems(newSelectedItems);
      }
    }
  }, [countryCode, state, city, isUserChange]);

  // Call onChange only when selectedItems changes due to user interaction
  useEffect(() => {
    if (isUserChange) {
      onChange({
        countryCode: selectedItems.countryCode,
        state: selectedItems.state,
        city: selectedItems.city,
      });
      setIsUserChange(false);
    }
  }, [selectedItems, onChange]);

  const selectedStateId = useMemo(() => {
    return states.find((item) => item.label === selectedItems.state)?.value;
  }, [selectedItems.state, states]);

  // Fetch countries only once on mount
  useEffect(() => {
    const getSetCountryOptions = async () => {
      const response = await getAddressDetails({});
      if (Array.isArray(response?.allCountries)) {
        setCountries(
          response.allCountries.map((country) => ({
            label: country.name,
            value: country.id,
          }))
        );
      }
    };
    getSetCountryOptions();
  }, []);

  // Fetch states when country changes
  useEffect(() => {
    const getSetStates = async () => {
      if (!selectedItems.countryCode) return;
      
      const response = await getAddressDetails({
        countryId: selectedItems.countryCode,
      });
      if (Array.isArray(response?.allStates)) {
        setStates(
          response.allStates.map((state) => ({
            label: state.name,
            value: state.id,
          }))
        );
      }
    };
    getSetStates();
  }, [selectedItems.countryCode]);

  // Fetch cities when state changes
  useEffect(() => {
    const getSetCities = async () => {
      if (!selectedItems.countryCode || !selectedStateId) return;

      try {
        const response = await getAddressDetails({
          countryId: selectedItems.countryCode,
          stateId: selectedStateId,
        });

        if (Array.isArray(response?.allCities)) {
          setCities(
            response.allCities.map((city) => ({
              label: city.name,
              value: city.cityId!,
            }))
          );
        }
      } catch (e) {
        console.log(e);
      }
    };
    getSetCities();
  }, [selectedItems.countryCode, selectedStateId]);

  const disabledCountrySelect = useMemo(() => {
    return countries.length === 0 || disableCountrySelect;
  }, [countries, disableCountrySelect]);

  const disabledStateSelect = useMemo(() => {
    return states.length === 0;
  }, [states]);

  const disabledCitySelect = useMemo(() => {
    return cities.length === 0;
  }, [cities]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <WithLabel label="Country">
            <CustomSelect
              error={countryError}
              helperText={countryError ? 'Please select a country' : ''}
              options={countries}
              disabled={disabledCountrySelect}
              label={'Select Country'}
              value={selectedItems.countryCode}
              onOptionSelect={(value) =>
                handleSelectChange('countryCode', value)
              }
            />
          </WithLabel>
          {showDisbledCountryInfo && (
            <Box
              css={css`
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 6px;
                align-self: flex-start;
                padding-top: 8px;
              `}
            >
              <InfoOutlineIcon
                style={{
                  fontSize: '16px',
                  color: '#7A7A7A',
                }}
              />
              <Typography
                css={css`
                  color: var(--Neutral-Secondary, #7a7a7a);
                  font-family: Inter;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                `}
              >
                Country is linked to your account and can't be edited.
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={6}>
          <WithLabel label="State">
            <CustomSelect
              error={stateError}
              helperText={stateError ? 'Please select a state' : ''}
              options={states.map((item) => ({
                value: item.label,
                label: item.label,
              }))}
              disabled={disabledStateSelect}
              value={selectedItems.state}
              label={'Select State'}
              onOptionSelect={(value) => handleSelectChange('state', value)}
            />
          </WithLabel>
        </Grid>
        <Grid item xs={6}>
          <WithLabel label="City">
            <CustomSelect
              error={cityError}
              helperText={cityError ? 'Please select a city' : ''}
              options={cities.map((item) => ({
                value: item.label,
                label: item.label,
              }))}
              value={selectedItems.city}
              disabled={disabledCitySelect}
              label={'Select City'}
              onOptionSelect={(value) => handleSelectChange('city', value)}
            />
          </WithLabel>
        </Grid>
        <Grid item xs={6}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CountryStateCitySelect;
