export enum TaxTypes {
  GST = 'GST',
}

export enum PlanUnit {
  MONTH = 'month',
  YEAR = 'year',
  QUARTER = 'quarter',
}

export type PlanPrice = {
  id: string;
  unit: PlanUnit;
  period?: number;
  amount: number;
  display: boolean;
  discountAmount: number;
  discountPercent: string;
  showTrial?: boolean;
  numOfDays?: number;
} & (
  | { tax: false }
  | {
      tax: true;
      taxType: TaxTypes.GST;
      taxPercent: number;
      taxAmount: number;
    }
);

export interface ProductPlans {
  id: string;
  price: Array<PlanPrice>;
  currencyCode: string;
  numUsers: number;
  displayName: string;
}

export enum PlanPaymentServices {
  RAZORPAY = 'RAZORPAY',
  RAZORPAY_SMART_COLLECT = 'RAZORPAY_SMART_COLLECT',
  STRIPE = 'STRIPE',
  CHARGEBEE = 'CHARGEBEE',
  PHONEPE = 'PHONEPE',
  EASEBUZZ = 'EASEBUZZ',
}

export interface RazorpayPaymentService {
  type: PlanPaymentServices.RAZORPAY;
  default?: boolean;
  data: {
    apiKey: string;
  };
}

export interface StripePaymentService {
  type: PlanPaymentServices.STRIPE;
  default?: boolean;
  data: { publishApiKey: string };
}

export interface ChargebeePaymentService {
  default?: boolean;
  type: PlanPaymentServices.CHARGEBEE;
  data: {
    siteName: string;
    publishableKey: string;
  };
}
export interface EasebuzzPaymentService {
  default?: boolean;
  type: PlanPaymentServices.EASEBUZZ;
  data: {
    key: string;
    accessKey: string;
    theme: string;
  };
}

export type PaymentService =
  | RazorpayPaymentService
  | StripePaymentService
  | ChargebeePaymentService
  | EasebuzzPaymentService;

export type GetProductPlans =
  | {
      plans: Array<ProductPlans>;
      yearlyOff: number;
      paymentService: PaymentService;
      couponDetails: {
        valid: boolean;
        validTill?: string;
      };
    }
  | { countryCodeNotSupported: true; googleFormLink: string };

export type GetProductPlansForKnownCountry = {
  plans: Array<ProductPlans>;
  yearlyOff: number;
  paymentService: PaymentService;
  couponDetails: {
    valid: boolean;
    validTill?: string;
  };
};

export type SelectedReferralLeadsPlan = {
  id: string | null;
  numUsers: number;
  label: string;
  unit: PlanUnit;
  period: number;
  planId: DTPlanId | null;
  currencyCode: string | null;
};

export enum PlanId {
  STANDARD = 'STANDARD',
  PRO = 'PRO',
  ENTERPRISE = 'ENTERPRISE',
}

export enum SubscriptionPeriod {
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
}

export enum DTPlanId {
  STANDARD = 'DT_STANDARD',
  PRO = 'DT_PRO',
  ENTERPRISE = 'ENTERPRISE',
}
