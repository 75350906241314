/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, Chip, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { totalReferralLeadsAtom } from '../../state';
import CountBadge from '../../../../components/count-badge';

const LeadsSubHeader = () => {
  const totalLeads = useAtomValue(totalReferralLeadsAtom);

  return (
    <Box
      width={'100%'}
      css={css`
        height: 56px;
        width: 100%;
        background-color: #fff;
        border-bottom: 1px solid #e0e0ea;
        display: flex;
        align-items: center;
        padding: 0px 20px;
      `}
    >
      <Box display={'flex'} gap={'8px'} alignItems="center">
        <Typography
          css={css`
            color: #2d2d2d;
            font-size: 16px;
            font-weight: 700;
          `}
        >
          Leads
        </Typography>
        <CountBadge totalCount={totalLeads} />
      </Box>
    </Box>
  );
};

export default LeadsSubHeader;
