import { post } from '../../../infra/rest';
import {
  GetLeadsPaginationOptions,
  GetReferralPartnerLeadsPaginatedResponse,
  FilterRequestData,
} from '../typings';

let controller = new AbortController();

const getLeadsAPI = async (
  filter?: FilterRequestData,
  paginationData?: GetLeadsPaginationOptions
) => {
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
  return post<GetReferralPartnerLeadsPaginatedResponse>(
    `/v1/channel-partner/leads`,
    {
      body: JSON.stringify({
        searchFilter: filter?.search,
        sortFilter: filter?.sort,
        paginationOptions: paginationData,
      }),
    },
    true,
    false,
    false,
    false,
    false,
    controller.signal
  );
};

export default getLeadsAPI;
