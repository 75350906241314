export function urlCreator({
  baseUrl,
  origin = '',
  query = {},
  params = {},
}: {
  baseUrl: string;
  origin?: string;
  query?: Record<string, string | number | boolean | undefined | null>;
  params?: Record<string, string | number | boolean>;
}): string {
  const queryStr = Object.entries(query).reduce((acc, [key, value]) => {
    if (value === undefined || value === null || !value.toString().length) {
      return acc;
    }

    const encodeQuery = `${key}=${encodeURI(value.toString())}`;

    return acc ? acc + `&${encodeQuery}` : `?${encodeQuery}`;
  }, '');

  const urlWithParams = Object.entries(params).reduce((acc, [key, value]) => {
    return acc.replace(`:${key}`, encodeURI(value.toString()));
  }, baseUrl);

  return origin + urlWithParams + queryStr;
}

export function urlReplacer(url: string, replacerArray: string[][]): string {
  return replacerArray.reduce((acc, [key, value]) => {
    const val = acc.replace(key, value);
    return val;
  }, url);
}
