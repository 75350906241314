export const personalDetailsApiFormDataKeysToStateKeysMapping = {
  fullName: 'fullName',
  email: 'email',
  address: 'address',
  countryCode: 'countryCode',
  state: 'state',
  city: 'city',
  pinCode: 'pincode',
  taxIdNumber: 'taxIdentificationNumber',
  taxIdProofUrl: 'taxIdentificationDocument',
  taxIdProof: 'taxIdentificationDocument',
  panNumber: 'panNumber',
  panProofUrl: 'pan',
  panProof: 'pan',
  referralPartnerType: null,
  addressProofUrl: 'addressProof',
  addressProof: 'addressProof',
};

export const businessDetailsApiFormDataKeysToStateKeysMapping = {
  contactName: 'businessPersonName',
  contactNumber: 'businessContactDetails',
  contactEmail: 'businessEmailId',
  companyName: 'companyName',
  gstNumber: 'gstNumber',
  gstCertificateUrl: 'gstCertificate',
  gstCertificate: 'gstCertificate',
  msmeCertificateUrl: 'msmeRegistrationCertificate',
  msmeCertificate: 'msmeRegistrationCertificate',
  noPermEstProofUrl: 'noEstablishmentDeclaration',
  noPermEstProof: 'noEstablishmentDeclaration',
  form10FProofUrl: 'form10f',
  form10FProof: 'form10f',
};

export const getBankDetailsApiFormDataKeysToStateKeysMapping = (
  isInternational: boolean
) => {
  return {
    bankName: 'bankName',
    bankAddress: 'bankAddress',
    bankAccountNumber: 'bankAccountNumber',
    swiftCode: 'swiftCode',
    ifscCode:'ifscCode',
    cancelledChequeUrl: 'cancelChequeScan',
    cancelledCheque: 'cancelChequeScan',
    additionalInfo: 'additionalInformation',
    accountHolderName: 'accountHolderName',
  };
};
