/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Box, Button, Card, Grid, Typography } from '@mui/material';
import WithLabel from '../../../../components/input/WithLabel';
// import { getChannelDetails } from '../../../../utils/getSetChannelDetails';
import { useAtomValue } from 'jotai';
import { orgProfileAtom } from '../../states';
import { formatToLocalDate, handleCopyToClipBoard } from '../../../../utils';
import useNotifications from '../../../../hooks/useNotifications';

const BasicInfo = () => {
  // const channelPartnerData = getChannelDetails();
  const orgProfileValue = useAtomValue(orgProfileAtom);
  const { addNotification } = useNotifications();

  const handleNumberCopy = () => {
    if (orgProfileValue?.data?.org?.phone) {
      handleCopyToClipBoard(
        orgProfileValue?.data?.org?.phone,
        () =>
          addNotification({
            message: 'Login Number copied',
            type: 'success',
          }),
        () =>
          addNotification({
            message: 'Error in Login Number copy',
            type: 'error',
          })
      );
    }
  };

  const handleOrganisationIdCopy = () => {
    if (orgProfileValue?.data?.org?.id) {
      handleCopyToClipBoard(
        orgProfileValue?.data?.org?.id,
        () =>
          addNotification({
            message: 'Organisation ID copied',
            type: 'success',
          }),
        () =>
          addNotification({
            message: 'Error in Organisation ID copy',
            type: 'error',
          })
      );
    }
  };

  return (
    <Box>
      <Card>
        <Grid container>
          <Grid item xs>
            <Typography
              css={css`
                color: #2d2d2d;
                font-size: 16px;
                font-weight: 500;
              `}
            >
              Basic info
            </Typography>
            <Typography
              css={css`
                color: #7a7a7a;
                font-size: 14px;
                font-weight: 500;
              `}
            >
              Description
            </Typography>
          </Grid>
          <Grid item xs gap={'12px'}>
            <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
              <WithLabel label="Name">
                <Typography
                  css={css`
                    color: #2d2d2d;
                    font-size: 14px;
                    font-weight: 400;
                  `}
                >
                  {orgProfileValue.data?.org?.name}
                </Typography>
              </WithLabel>
              <WithLabel label="Login Number">
                <Grid
                  container
                  css={css`
                    border: 1px solid #e0e0e9;
                    border-radius: 12px;
                    align-items: center;
                    padding: 0 12px;
                  `}
                >
                  <Grid item xs>
                    <Typography color={'#2d2d2d'}>
                      {orgProfileValue?.data?.org?.phone}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button onClick={handleNumberCopy}>Copy</Button>
                  </Grid>
                </Grid>
              </WithLabel>
              <WithLabel label="Organisation ID">
                <Grid
                  container
                  css={css`
                    border: 1px solid #e0e0e9;
                    border-radius: 12px;
                    align-items: center;
                    padding: 0 12px;
                  `}
                >
                  <Grid item xs>
                    <Typography color={'#2d2d2d'}>
                      {orgProfileValue?.data?.org?.id}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button onClick={handleOrganisationIdCopy}>Copy</Button>
                  </Grid>
                </Grid>
              </WithLabel>
              <WithLabel label="Created on">
                <Typography
                  css={css`
                    color: #2d2d2d;
                    font-size: 14px;
                    font-weight: 400;
                  `}
                >
                  {formatToLocalDate(
                    orgProfileValue?.data?.org?.dateCreated ?? ''
                  )}
                </Typography>
              </WithLabel>

              {/* <WithLabel label="Logo">
                <img
                  height={'48px'}
                  width={'48px'}
                  css={css`
                    border-radius: 12px;
                  `}
                  src="https://via.placeholder.com/150"
                  alt="logo"
                />
              </WithLabel> */}
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default BasicInfo;
