export enum ROUTES {
  onboardPage = '/v1/onboard',
  homePage = '/',
  loginPage = '/login',
  organisationPage = '/v1/organisations',
  orgProfilePage = '/v1/organisations/profile',
  addOrganisation = '/v1/organisation/add',
  walletPage = '/v1/wallet',
  REFERRAL_PARTNER_ONBOARDING = '/v1/referral-partner-onboarding',
  REFERRAL_PARTNER_ONBOARDING_PERSONAL_DETAILS = '/personal-details',
  REFERRAL_PARTNER_ONBOARDING_BUSINESS_DETAILS = '/business-details',
  REFERRAL_PARTNER_ONBOARDING_BANK_DETAILS = '/bank-details',
  REFERRAL_PARTNER_ONBOARDING_REVIEW = '/review',
  REFERRAL_PARTNER_ONBOARDING_WAITING_REVIEW = '/waiting-review',
  LEADS_PAGE = '/v1/leads',
  PAYOUT_COMMISSIONS_PAGE = '/v1/payout-commissions',
  INVOICE_WORKING_PAGE = '/v1/payout-commissions/working/:payoutCommissionId',
  EDIT_WHITELABEL_PARTNER_PROFILE = '/v1/edit-profile/whitelabel',
  EDIT_REFERRAL_PARTNER_PROFILE = '/v1/edit-profile/referral',
}
