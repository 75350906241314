/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Box,
  Button,
  ButtonBase,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import useNotifications from '../../../../../hooks/useNotifications';
import { useCallback, useMemo, useState } from 'react';
import {
  editReferralPartnerPersonalDetailsAtom,
  referralPartnerProfileDetailsAtom,
} from '../../states';
import { useAtom, useAtomValue } from 'jotai';
import { postReferralPersonalDetails } from '../../../../referral-partner-onboarding/requests';
import { ReferralPartnerType } from '../../../../referral-partner-onboarding/types';
import { handleApiError } from '../../../../../utils/error-handler';
import { personalDetailsApiFormDataKeysToStateKeysMapping } from '../../../../referral-partner-onboarding/constants';
import { getCountryFromCountryCode } from '../../../../../utils/countries-mapper';
import WithLabel from '../../../../../components/input/WithLabel';
import CountryStateCitySelect from '../../../../CountryStateCitySelect';
import { FileUploadField } from '../../../../../components/input/FileUploadField';
import arePersonalDetailsChanged from '../../utils/are-personal-details-changes';
import BottomActionBar from '../../../whitelabel/components/bottom-action-bar';

const EditReferralPartnerPersonalDetails = () => {
  const [
    editReferralPartnerPersonalDetails,
    setEditReferralPartnerPersonalDetails,
  ] = useAtom(editReferralPartnerPersonalDetailsAtom);
  const [{ data: referralPartnerProfileDetails },setReferralPartnerProfileDetailsData] = useAtom(
    referralPartnerProfileDetailsAtom
  );
  const { addNotification } = useNotifications();
  const [isApiError, setIsApiError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isInternational =
    editReferralPartnerPersonalDetails?.countryCode !== 'IN';

  type ErrorFields = Record<string, string | null>;

  const errorFieldsInitialState: ErrorFields = {
    fullName: null,
    email: null,
    address: null,
    pincode: null,
    countryCode: null,
    state: null,
    city: null,
    taxIdentificationNumber: null,
    panNumber: null,
    addressProof: null,
    pan: null,
    taxIdentificationDocument: null,
  };
  const [errorFields, setErrorFields] = useState<ErrorFields>(
    errorFieldsInitialState
  );

  const areFieldsChanged = useMemo(() => {
    if (referralPartnerProfileDetails) {
      return arePersonalDetailsChanged(
        referralPartnerProfileDetails,
        editReferralPartnerPersonalDetails
      );
    }
    return false;
  }, [referralPartnerProfileDetails, editReferralPartnerPersonalDetails]);

  const resetFields = () => {
    setEditReferralPartnerPersonalDetails({
      address: referralPartnerProfileDetails?.address,
      countryCode: referralPartnerProfileDetails?.countryCode ?? 'IN',
      state: referralPartnerProfileDetails?.state,
      city: referralPartnerProfileDetails?.city,
      pincode: referralPartnerProfileDetails?.pinCode,
      email: referralPartnerProfileDetails?.email,
      taxIdentificationNumber: referralPartnerProfileDetails?.taxIdNumber,
      addressProof: referralPartnerProfileDetails?.addressProofUrl,
      fullName: referralPartnerProfileDetails?.fullName,
      panNumber: referralPartnerProfileDetails?.panNumber,
      pan: referralPartnerProfileDetails?.panProofUrl,
      taxIdentificationDocument: referralPartnerProfileDetails?.taxIdProofUrl,
      country: getCountryFromCountryCode(referralPartnerProfileDetails?.countryCode || 'IN'),
    });
    setErrorFields(errorFieldsInitialState);
  };

  const handleFileUpload = (file: File | null, type: string) => {
    setErrorFields(prev => ({
      ...prev,
      [type]: null
    }));

    setEditReferralPartnerPersonalDetails({
      ...editReferralPartnerPersonalDetails,
      [type]: file,
    });
  };

  const saveAndHandleNext = async () => {
    try {
      setIsApiError(false);
      setIsLoading(true);
      const response = await postReferralPersonalDetails(
        editReferralPartnerPersonalDetails,
        isInternational,
        referralPartnerProfileDetails?.referralPartnerType ===
          ReferralPartnerType.BUSINESS
      );
      setIsLoading(false);
      if (response.success) {
        addNotification({
          message: 'Profile updated successfully',
          type: 'success',
        });
        setReferralPartnerProfileDetailsData((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            ...response,
          },
        }));
        return;
      }
      setIsApiError(true);
      addNotification({
        message: 'Something went wrong, Please try again',
        type: 'error',
      });
    } catch (e) {
      setIsLoading(false);
      setIsApiError(true);
      handleApiError({
        error: e,
        mappingObject:
          personalDetailsApiFormDataKeysToStateKeysMapping as unknown as Record<
            string,
            string
          >,
        errorFieldsInitialState,
        setErrorFields,
        addNotification,
        isInternational,
      });
    }
  };

  const handleSelectChange = useCallback(
    ({
      countryCode,
      state,
      city,
    }: {
      countryCode?: string;
      state?: string;
      city?: string;
    }) => {
      setErrorFields(prev => ({
        ...prev,
        countryCode: null,
        state: null,
        city: null
      }));

      const isCountryChanging = countryCode !== editReferralPartnerPersonalDetails?.countryCode;
      const newCountryCode = countryCode ?? '';
      const isNewCountryInternational = newCountryCode !== 'IN';

      const taxFields = isCountryChanging ? {
        taxIdentificationNumber: isNewCountryInternational ? '' : undefined,
        taxIdentificationDocument: isNewCountryInternational ? null : undefined,
        panNumber: isNewCountryInternational ? undefined : '',
        pan: isNewCountryInternational ? undefined : null,
        addressProof: isNewCountryInternational ? undefined : null,
      } : {};

      setEditReferralPartnerPersonalDetails({
        ...editReferralPartnerPersonalDetails,
        countryCode: newCountryCode,
        state: state ?? '',
        city: city ?? '',
        country: getCountryFromCountryCode(newCountryCode || 'IN')!,
        ...taxFields
      });
    },
    [editReferralPartnerPersonalDetails, setEditReferralPartnerPersonalDetails]
  );

  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorFields(prev => ({
      ...prev,
      [e.target.name]: null
    }));

    setEditReferralPartnerPersonalDetails({
      ...editReferralPartnerPersonalDetails,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Box
      css={css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        padding-bottom: ${areFieldsChanged ? '104px' : '24px'};
      `}
    >
      <Box
        css={css`
          display: flex;
          width: 100%;
          padding: 18px 20px;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          border-radius: 8px;
          border: 1px solid var(--Neutral-Stroke, #e0e0e9);
          background: #fff;
        `}
      >
        <Typography
          css={css`
            color: #2d2d2d;
            font-size: 16px;
            font-weight: 600;
          `}
        >
          Profile and Identification Details
        </Typography>
        <FormGroup
          css={css`
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;
          `}
        >
          <WithLabel label="Full Name">
            <TextField
              placeholder="Enter your first and last name"
              name="fullName"
              value={editReferralPartnerPersonalDetails?.fullName}
              error={!!errorFields.fullName}
              helperText={errorFields.fullName || ''}
              onChange={handleTextInputChange}
            />
          </WithLabel>
          <WithLabel label="Email ID">
            <TextField
              placeholder="Enter your email"
              name="email"
              value={editReferralPartnerPersonalDetails?.email}
              error={!!errorFields.email}
              helperText={errorFields.email || ''}
              onChange={handleTextInputChange}
            />
          </WithLabel>
          <WithLabel label="Address">
            <TextField
              placeholder="Enter your address"
              name="address"
              value={editReferralPartnerPersonalDetails?.address}
              error={!!errorFields.address}
              helperText={errorFields.address || ''}
              onChange={handleTextInputChange}
              multiline
              rows={2}
            />
          </WithLabel>
          <CountryStateCitySelect
            countryCode={editReferralPartnerPersonalDetails?.countryCode}
            state={editReferralPartnerPersonalDetails?.state}
            city={editReferralPartnerPersonalDetails?.city}
            cityError={!!errorFields.city}
            stateError={!!errorFields.state}
            countryError={!!errorFields.countryCode}
            onChange={handleSelectChange}
            disableCountrySelect
            showDisbledCountryInfo
          >
            <WithLabel label="Postal code">
              <TextField
                name="pincode"
                type="number"
                error={!!errorFields.pincode}
                helperText={errorFields.pincode || ''}
                value={editReferralPartnerPersonalDetails?.pincode}
                placeholder="Enter your postal code"
                onChange={handleTextInputChange}
              />
            </WithLabel>
          </CountryStateCitySelect>
          <FileUploadField
            label={
              isInternational
                ? 'Upload company address proof'
                : 'Upload address proof'
            }
            helperText={
              isInternational
                ? 'Upload 1 supported file: PDF or image. Max 10 MB.'
                : "Aadhaar Card, Passport, Driving license, Voter's Identity Card </br> Upload 1 supported file: PDF or image. Max 10 MB."
            }
            onChange={(file) => handleFileUpload(file, 'addressProof')}
            accept="image/*,.pdf"
            errorMsg={errorFields.addressProof || ''}
            file={editReferralPartnerPersonalDetails?.addressProof}
          />
          {!isInternational ? (
            <>
              <WithLabel label="PAN">
                <TextField
                  placeholder="Enter PAN number"
                  name="panNumber"
                  value={editReferralPartnerPersonalDetails?.panNumber}
                  error={!!errorFields.panNumber}
                  helperText={errorFields.panNumber || ''}
                  onChange={handleTextInputChange}
                />
              </WithLabel>
              <FileUploadField
                label="Upload PAN"
                helperText="Upload 1 supported file: PDF or image. Max 10 MB."
                onChange={(file) => handleFileUpload(file, 'pan')}
                accept="image/*,.pdf"
                errorMsg={errorFields.pan || ''}
                file={editReferralPartnerPersonalDetails?.pan}
              />
            </>
          ) : (
            <>
              <WithLabel label="Tax Identification Number">
                <TextField
                  placeholder="Enter Tax Identification Number"
                  name="taxIdentificationNumber"
                  value={
                    editReferralPartnerPersonalDetails?.taxIdentificationNumber
                  }
                  error={!!errorFields.taxIdentificationNumber}
                  helperText={errorFields.taxIdentificationNumber || ''}
                  onChange={handleTextInputChange}
                />
              </WithLabel>
              <FileUploadField
                label="Upload Tax Identification Document"
                helperText="Upload 1 supported file: PDF or image. Max 10 MB."
                onChange={(file) =>
                  handleFileUpload(file, 'taxIdentificationDocument')
                }
                accept="image/*,.pdf"
                errorMsg={errorFields.taxIdentificationDocument || ''}
                file={
                  editReferralPartnerPersonalDetails?.taxIdentificationDocument
                }
              />
            </>
          )}
        </FormGroup>
      </Box>
      <BottomActionBar
        isLoadingAPI={isLoading}
        handleSubmit={saveAndHandleNext}
        onCancel={resetFields}
        show={areFieldsChanged}
      />
    </Box>
  );
};

export default EditReferralPartnerPersonalDetails;
