/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from '@mui/icons-material/Close';
import TypographyWithEllipsisTooltip from '../typography-with-ellipsis';

const helperTextStyles = css`
  color: var(--Neutral-Secondary-Text, #585858);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const errorTextStyles = css`
  color: #d32f2f;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 3px;
`;

export const labelStyles = css`
  color: var(--Neutral-Secondary-Text, #585858);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export interface FileUploadFieldProps {
  label?: string;
  helperText?: string;
  errorMsg?: string;
  onChange: (file: File | null) => void;
  accept: string;
  direction?: 'horizontal' | 'vertical';
  children?: React.ReactNode;
  file?: File | string | null;
}

export const FileUploadField: React.FC<FileUploadFieldProps> = ({
  label,
  onChange,
  helperText,
  errorMsg,
  accept,
  direction = 'horizontal',
  children,
  file,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | string | null>(null);

  useEffect(() => {
    if (file !== undefined) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
    }
  }, [file]);

  const handleFileChange = (file: File) => {
    setSelectedFile(file);
    onChange(file);
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    onChange(null);
  };

  const getFileTypeFromMime = (file: File | string) => {
    if (!file) return 'image';
    if (file instanceof File) {
      if (file.type === 'application/pdf') {
        return 'pdf';
      }
      return 'image';
    }
    // For file URLs, check the extension
    const extension = file.split('?')[0].split('.').pop()?.toLowerCase();
    if (extension === 'pdf') {
      return 'pdf';
    }
    return 'image';
  };

  const getFileName = (file: File | string) => {
    if (file instanceof File) {
      return file.name;
    }
    
    try {
      // Remove query parameters and get the base URL
      const urlWithoutParams = file.split('?')[0];
      // Get the last part of the path
      const fullFileName = urlWithoutParams.split('/').pop();
      
      if (!fullFileName) return 'file';

      // If filename is too long, truncate it
      const maxLength = 20;
      if (fullFileName.length > maxLength) {
        const extension = fullFileName.split('.').pop();
        const name = fullFileName.split('.')[0];
        return `${name}${extension ? `.${extension}` : ''}`;
      }
      
      return fullFileName;
    } catch (e) {
      return 'file';
    }
  };

  const renderUploadButton = () => (
    <Button
      variant="outlined"
      component="label"
      css={css`
        padding: 8 22px;
        border-radius: 4px;
        color: #538d6f;
        border-color: #538d6f;
        font-size: 14px;
        font-weight: 500;
        text-transform: none;
        &:hover {
          border-color: #538d6f;
          background: rgba(83, 141, 111, 0.04);
        }
        width: max-content;
      `}
    >
      Upload a file
      <input
        type="file"
        hidden
        accept={accept}
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file) handleFileChange(file);
        }}
      />
    </Button>
  );

  const renderSelectedFile = () => (
    <Box
      css={css`
        display: flex;
        height: 40px;
        padding: 12px 16px 12px 12px;
        align-items: center;
        gap: 16px;
        border-radius: 8px;
        border: 1px solid var(--Neutral-Stroke, #e0e0e9);
        background: #fff;
        max-width: 175px;
      `}
    >
      <Box
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
        `}
      >
        {getFileTypeFromMime(selectedFile!) === 'pdf' ? (
          <PictureAsPdfIcon
            css={css`
              width: 24px;
              height: 24px;
              color: #3f906d;
            `}
          />
        ) : (
          <ImageIcon
            css={css`
              width: 24px;
              height: 24px;
              color: #3f906d;
            `}
          />
        )}
        <TypographyWithEllipsisTooltip
          css={css`
            color: var(--Neutral-Primary, #2d2d2d);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            max-width: 80px;
          `}
        >
          {getFileName(selectedFile!)}
        </TypographyWithEllipsisTooltip>
      </Box>
      <Box
        onClick={handleRemoveFile}
        css={css`
          cursor: pointer;
          display: flex;
          align-items: center;
        `}
      >
        <CloseIcon
          css={css`
            color: #7a7a7a;
            width: 24px;
            height: 24px;
          `}
        />
      </Box>
    </Box>
  );

  return (
    <Box
      css={css`
        display: flex;
        padding: 8px 0px;
        justify-content: space-between;
        flex-direction: ${direction === 'vertical' ? 'column' : 'row'};
        gap: 8px;
      `}
    >
      <Box
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
        `}
      >
        {label && <Typography css={labelStyles}>{label}</Typography>}
        {helperText && (
          <Typography
            css={helperTextStyles}
            dangerouslySetInnerHTML={{ __html: helperText }}
          />
        )}
        {errorMsg && (
          <Typography css={errorTextStyles}>{errorMsg}</Typography>
        )}
      </Box>
      <Box
        css={css`
          display: flex;
          align-items: center;
          gap: 16px;
        `}
      >
        {selectedFile ? renderSelectedFile() : renderUploadButton()}
        {children}
      </Box>
    </Box>
  );
};
