/** @jsxImportSource @emotion/react */
import { Box, Button, ButtonBase, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import withHeader from '../../../../HOC/WithHeader';
import { isReferralPartnerCustomer } from '../../../../utils';
import { ROUTES } from '../../../../routes/auth-routes/routes';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../../../hooks/useQuery';
import { ChannelPartnerState } from '../../../../types';
import {
  editWhitelabelPartnerBillingDetailsAtom,
  editWhitelabelPartnerProfileErrorAtom,
  editWhitelabelPartnerProfileLoadingAtom,
  editWhitelabelPartnerWebsiteInfoAtom,
  errorFieldsAtom,
  whitelabelPartnerProfileDetailsAtom,
} from '../state';
import { getChannelDetails } from '../../../../utils/getSetChannelDetails';
import { getOnboardingState } from '../../../../routes/requests/getOnboardingStatus';
import { useAtom, useSetAtom } from 'jotai';
import { getCountryFromMobileNumber } from '../../../../utils/countries-mapper';
import Loader from '../../../../components/loader';
import WhitelabelPartnerWebsiteInfo from './website-info';
import WhitelabelPartnerBillingDetails from './billing-details';
import useNotifications from '../../../../hooks/useNotifications';
import {
  putCompanyBillingDetails,
  putCompanyDetails,
} from '../../../onboarding/requests/companyDetails';
import areDetailsChanged from '../utils/are-details-changed';
import BottomActionBar from './bottom-action-bar';
import { onboardingCompanyDetailsAtom } from '../../../onboarding/states';
import { handleApiError } from '../../../../utils/error-handler';
import { errorFieldsInitialState } from '../state';

const EditWhitelabelPartnerProfile = () => {
  const isReferralPartner = isReferralPartnerCustomer();
  const navigate = useNavigate();
  const [
    editWhitelabelPartnerWebsiteInfo,
    setEditWhitelabelPartnerWebsiteInfo,
  ] = useAtom(editWhitelabelPartnerWebsiteInfoAtom);
  const setOnboardingCompanyDetails = useSetAtom(onboardingCompanyDetailsAtom);
  const [
    editWhitelabelPartnerBillingDetails,
    setEditWhitelabelPartnerBillingDetails,
  ] = useAtom(editWhitelabelPartnerBillingDetailsAtom);
  const [isApiError, setIsApiError] = useAtom(
    editWhitelabelPartnerProfileErrorAtom
  );
  const [isLoadingAPI, setIsLoadingAPI] = useAtom(
    editWhitelabelPartnerProfileLoadingAtom
  );
  const setErrorFields = useSetAtom(errorFieldsAtom);
  const { addNotification } = useNotifications();
  const whitelabelPartnerProfileDetailGetter = useCallback(() => {
    const channelPartnerDetails = getChannelDetails();
    if (channelPartnerDetails?.id) {
      return getOnboardingState(channelPartnerDetails?.id);
    }

    return Promise.resolve(undefined);
  }, []);

  const {
    requestData: { loading, error, data: whitelabelPartnerProfileDetailsData },
    refreshData: refreshWhitelabelPartnerProfileDetails,
  } = useQuery<ChannelPartnerState | undefined>({
    requestAtom: whitelabelPartnerProfileDetailsAtom,
    queryFunction: whitelabelPartnerProfileDetailGetter,
    cacheData: true,
  });

  const getSetWhitelabelPartnerProfileDetails = useCallback(() => {
    if (whitelabelPartnerProfileDetailsData?.currentStateMetadata) {
      const partnerDetailsData =
        whitelabelPartnerProfileDetailsData?.currentStateMetadata
          ?.SET_PARTNER_DETAILS;
      const partnerBillingData =
        whitelabelPartnerProfileDetailsData?.currentStateMetadata
          ?.SET_PARTNER_BILLING;
      if (partnerDetailsData) {
        const savedCountry = getCountryFromMobileNumber(
          partnerDetailsData?.supportNumber
        );
        let savedSupportNumber = partnerDetailsData?.supportNumber;
        if (savedCountry) {
          if (savedSupportNumber) {
            savedSupportNumber = savedSupportNumber.substring(
              savedCountry.symbol.length - 1
            );
          }
        }
        setEditWhitelabelPartnerWebsiteInfo({
          name: partnerDetailsData?.name,
          domain: partnerDetailsData?.domain,
          supportNumber: savedSupportNumber,
          logo: partnerDetailsData?.logoUrl,
          savedCountry: getCountryFromMobileNumber(
            partnerDetailsData?.supportNumber
          ),
        });
        setOnboardingCompanyDetails((prev) => ({
          ...prev,
          logo:
            whitelabelPartnerProfileDetailsData?.currentStateMetadata
              ?.SET_PARTNER_DETAILS?.logoUrl ?? null,
        }));
      }
      if (partnerBillingData) {
        setEditWhitelabelPartnerBillingDetails({
          countryCode: partnerBillingData?.countryCode,
          state: partnerBillingData?.state,
          city: partnerBillingData?.city,
          pinCode: partnerBillingData?.pinCode,
          email: partnerBillingData?.email,
          billingTaxNumber: partnerBillingData?.billingTaxNumber,
          fullName: partnerBillingData?.fullName,
          address: partnerBillingData?.address,
        });
      }
    }
  }, [
    whitelabelPartnerProfileDetailsData,
    setEditWhitelabelPartnerWebsiteInfo,
    setEditWhitelabelPartnerBillingDetails,
  ]);

  const handleSubmit = async () => {
    try {
      setIsApiError(false);
      setIsLoadingAPI(true);
      const channelDetails = getChannelDetails();
      if (channelDetails?.id) {
        const companyDetailsResponse = await putCompanyDetails(
          channelDetails?.id,
          (
            editWhitelabelPartnerWebsiteInfo.savedCountry.symbol ?? '+91'
          ).substring(1),
          editWhitelabelPartnerWebsiteInfo
        );
        const billingDetailsResponse = await putCompanyBillingDetails(
          channelDetails?.id,
          editWhitelabelPartnerBillingDetails
        );
        if (companyDetailsResponse.success && billingDetailsResponse.success) {
          addNotification({
            message: 'Profile updated successfully',
            type: 'success',
          });
          refreshWhitelabelPartnerProfileDetails?.();
          return;
        }
        addNotification({
          message: 'An error occurred. Please try again',
          type: 'error',
        });
      }
      setIsLoadingAPI(false);
    } catch (e) {
      setIsApiError(true);
      console.log(e);
      setIsLoadingAPI(false);
      handleApiError({
        error: e,
        mappingObject: {
          name: 'name',
          domain: 'domain',
          supportNumber: 'supportNumber',
          logo: 'logo',
          countryCode: 'countryCode',
          address: 'address',
          state: 'state',
          city: 'city',
          pinCode: 'pinCode',
          email: 'email',
          billingTaxNumber: 'billingTaxNumber',
          fullName: 'fullName',
        },
        errorFieldsInitialState,
        setErrorFields,
        addNotification,
      });
    } finally {
      setIsLoadingAPI(false);
    }
  };

  useEffect(() => {
    if (isReferralPartner) {
      navigate(ROUTES.homePage);
    }
  }, [isReferralPartner, navigate]);

  useEffect(() => {
    if (!loading && whitelabelPartnerProfileDetailsData) {
      getSetWhitelabelPartnerProfileDetails();
    }
  }, [loading, whitelabelPartnerProfileDetailsData]);

  const areFieldsChanged = useMemo(() => {
    if (whitelabelPartnerProfileDetailsData) {
      return areDetailsChanged(
        whitelabelPartnerProfileDetailsData,
        editWhitelabelPartnerWebsiteInfo,
        editWhitelabelPartnerBillingDetails
      );
    }
    return false;
  }, [
    whitelabelPartnerProfileDetailsData,
    editWhitelabelPartnerWebsiteInfo,
    editWhitelabelPartnerBillingDetails,
  ]);

  if (loading) {
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          height: calc(100vh - 64px);
          width: 100%;
        `}
      >
        <Loader size={32} secondary={'Loading...'} />
      </div>
    );
  }
  return (
    <>
      <Box
        css={css`
          display: flex;
          padding: 40px 80px;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;
          background: var(--Neutral-Background, #fbfbfc);
          width: 100%;
          padding-bottom: ${areFieldsChanged ? '104px' : '24px'};
          transition: padding-bottom 0.3s ease-in-out;
        `}
      >
        <Box
          css={css`
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            align-self: stretch;
            width: 100%;
          `}
        >
          <Box
            css={css`
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 20px;
              max-width: 800px;
              width: 100%;
            `}
          >
            <Typography
              css={css`
                color: var(--Neutral-Primary, #2d2d2d);
                font-family: Inter;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%;
                letter-spacing: -0.24px;
              `}
            >
              Edit Profile
            </Typography>
            <WhitelabelPartnerWebsiteInfo />
            <WhitelabelPartnerBillingDetails />
          </Box>
        </Box>
      </Box>
      <BottomActionBar
        show={areFieldsChanged}
        isLoadingAPI={isLoadingAPI}
        handleSubmit={handleSubmit}
        onCancel={() => {
          getSetWhitelabelPartnerProfileDetails();
          setErrorFields(errorFieldsInitialState);
        }}
      />
    </>
  );
};

export default withHeader(EditWhitelabelPartnerProfile);
