/** @jsxImportSource @emotion/react */
import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import { DISABLED_INPUT_STYLE } from '../../modules/edit-profile/whitelabel/constants';

export interface CustomSelectOptions {
  value: string;
  label: string;
}
interface CustomSelectProps {
  options: CustomSelectOptions[];
  label: string;
  value: string | undefined;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  onOptionSelect: (value: string) => void;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  label,
  disabled,
  onOptionSelect,
  value,
  error,
  helperText,
}) => {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select
          value={value}
          size="small"
          displayEmpty
          disabled={disabled}
          error={error}
          sx={DISABLED_INPUT_STYLE}
          style={{
            border: disabled ? '1px solid #E0E0E9' : 'none',
          }}
        >
          <MenuItem value="" disabled>
            <Typography
              css={css`
                color: #9a9a9a;
                font-size: 14px;
              `}
            >
              {label}
            </Typography>
          </MenuItem>
          {(options ?? []).map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              onClick={() => onOptionSelect(option.value)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {!disabled && error && (
        <Typography
          css={css`
            color: var(--error-main);
            font-size: 12px;
          `}
        >
          {helperText}
        </Typography>
      )}
    </Box>
  );
};

export default CustomSelect;
