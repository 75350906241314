import { getCountryFromCountryCode } from '../../../../utils/countries-mapper';
import { personalDetailsApiFormDataKeysToStateKeysMapping } from '../../../referral-partner-onboarding/constants';
import {
  GetChannelPartnerReferralDetailsResponse,
  ReferralPartnerOnboardingPersonalDetails,
} from '../../../referral-partner-onboarding/types';
import { compareChanges } from '../../../../utils/compare-changes';

const arePersonalDetailsChanged = (
  referralPartnerProfileDetails: GetChannelPartnerReferralDetailsResponse,
  editReferralPartnerPersonalDetails: ReferralPartnerOnboardingPersonalDetails
): boolean => {
  // Convert the mapping to the correct type
  const apiMapping = personalDetailsApiFormDataKeysToStateKeysMapping;
  const mapping: Partial<Record<keyof GetChannelPartnerReferralDetailsResponse, keyof ReferralPartnerOnboardingPersonalDetails>> = {};
  
  // Type-safe way to build the mapping
  Object.entries(apiMapping).forEach(([key, value]) => {
    if (value && typeof value === 'string' && value in editReferralPartnerPersonalDetails) {
      mapping[key as keyof GetChannelPartnerReferralDetailsResponse] = value as keyof ReferralPartnerOnboardingPersonalDetails;
    }
  });

  return compareChanges({
    apiResponse: referralPartnerProfileDetails,
    editForm: editReferralPartnerPersonalDetails,
    mapping,
    customMappings: {
      // Add special mappings
      country: (api) => getCountryFromCountryCode(api.countryCode || ''),
      // Handle optional fields that should be considered equal when both are empty
      taxIdentificationDocument: (api) => api.taxIdProofUrl || null,
      pan: (api) => api.panProofUrl || null,
      panNumber: (api) => api.panNumber || null,
      taxIdentificationNumber: (api) => api.taxIdNumber || null,
      addressProof: (api) => api.addressProofUrl || null,
    },
    treatEmptyAsNull: true
  });
};

export default arePersonalDetailsChanged;
