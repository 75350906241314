import { getBankDetailsApiFormDataKeysToStateKeysMapping } from '../../../referral-partner-onboarding/constants';
import {
  GetChannelPartnerReferralDetailsResponse,
  ReferralPartnerOnboardingBankDetails,
} from '../../../referral-partner-onboarding/types';
import { compareChanges } from '../../../../utils/compare-changes';

const areBankDetailsChanged = (
  referralPartnerProfileDetails: GetChannelPartnerReferralDetailsResponse,
  editReferralPartnerBankDetails: ReferralPartnerOnboardingBankDetails
): boolean => {
  const isInternational = referralPartnerProfileDetails?.countryCode !== 'IN';
  
  // Convert the mapping to the correct type
  const apiMapping = getBankDetailsApiFormDataKeysToStateKeysMapping(isInternational);
  const mapping: Partial<Record<keyof GetChannelPartnerReferralDetailsResponse, keyof ReferralPartnerOnboardingBankDetails>> = {};
  
  // Type-safe way to build the mapping
  Object.entries(apiMapping).forEach(([key, value]) => {
    if (value in editReferralPartnerBankDetails) {
      mapping[key as keyof GetChannelPartnerReferralDetailsResponse] = value as keyof ReferralPartnerOnboardingBankDetails;
    }
  });

  return compareChanges({
    apiResponse: referralPartnerProfileDetails,
    editForm: editReferralPartnerBankDetails,
    mapping,
    customMappings: {
      // Add any special field mappings
      ifscCode: (api) => api.ifscCode,
      swiftCode: (api) => api.swiftCode,
    },
    treatEmptyAsNull: true
  });
};

export default areBankDetailsChanged;
