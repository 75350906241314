/** @jsxImportSource @emotion/react */
import {
  TextField,
  InputAdornment,
  Grid,
  Button,
  MenuItem,
  Select,
  OutlinedInput,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SwapVertRoundedIcon from '@mui/icons-material/SwapVertRounded';
import { css } from '@emotion/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes/auth-routes/routes';
import { useSetAtom } from 'jotai';
import {
  organisationsSearchTermAtom,
  organisationsSortTermAtom,
} from '../states';
import {
  OrgSortAtomReducer,
  OrgSortTypes,
} from '../states/reducers/sort.reducers';
import { useReducerAtom } from 'jotai/utils';
import { isReferralPartnerCustomer } from '../../../utils';

const SearchAndFilters = () => {
  const navigate = useNavigate();
  const [selectedSort, dispatch] = useReducerAtom(
    organisationsSortTermAtom,
    OrgSortAtomReducer
  );
  const setSearchValue = useSetAtom(organisationsSearchTermAtom);

  useEffect(() => {
    return () => {
      setSearchValue('');
      dispatch({ type: OrgSortTypes.reset });
    };
  }, [setSearchValue, dispatch]);

  const options = [
    {
      name: 'Newest first',
      value: OrgSortTypes.newest_first,
    },
    {
      name: 'Oldest first',
      value: OrgSortTypes.oldest_first,
    },
    {
      name: 'A-Z',
      value: OrgSortTypes.aToz,
    },
    {
      name: 'Z-A',
      value: OrgSortTypes.zToa,
    },
  ];

  const isReferralPartner = isReferralPartnerCustomer();

  return (
    <Grid container gap={'12px'} mb={'12px'} alignItems="center">
      {/* search */}
      <Grid item xs={2.5}>
        <TextField
          id="search-input"
          placeholder="Search by name"
          variant="outlined"
          onChange={(e) => setSearchValue(e.target.value)}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {/* sort */}
      <Grid item xs={2.5}>
        <Select
          id="org-search-input-id"
          variant="outlined"
          size="small"
          notched={false}
          value={selectedSort.key}
          onChange={(e) => dispatch({ type: e.target.value as OrgSortTypes })}
          css={css`
            background: #fff;
          `}
          displayEmpty
          input={
            <OutlinedInput
              fullWidth
              startAdornment={
                <InputAdornment position="start">
                  <SwapVertRoundedIcon />
                </InputAdornment>
              }
              label="Select"
            />
          }
        >
          <MenuItem value="" disabled>
            <Typography color={'#aeaeae'}>Sort</Typography>
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs />
      <Grid item>
        {!isReferralPartner ? <Button
          onClick={() => navigate(ROUTES.addOrganisation)}
          css={css`
            border-radius: 8px;
          `}
          variant="contained"
        >
          Add Organisation
        </Button> : null}
      </Grid>
    </Grid>
  );
}

export default SearchAndFilters;
