import { useState, useEffect, useCallback } from 'react';
import requestConfig from '../../config/request';
import { getFirebaseToken } from '../infra/auth/firebase-auth';
import { get } from '../infra/rest';

interface UseRequestArgs {
  url: string;
  options?: RequestInit;
  manual?: boolean;
  useAuth?: boolean;
}

function useRequest<T>({
  url,
  options = {
    method: 'GET',
  },
  manual = false,
  useAuth = false,
}: UseRequestArgs) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<T | null>(null);

  const load = useCallback(
    async ({
      signal,
      options: loadOptions = {},
    }: {
      signal?: AbortSignal;
      options?: RequestInit;
    }) => {
      setLoading(true);
      setError(null);

      try {
        const data = await get<T>(
          url,
          {
            ...options,
            ...loadOptions,
            signal,
          },
          useAuth, // isAuthRequired
          false, // isPublicApi
          false, // hasFullUrl
          false, // ignoreDefaultHeaders
          false, // ignoreSentry
          signal
        );
        
        setData(data);
        setLoading(false);
        return data;
      } catch (error: any) {
        if ('name' in error && error.name === 'AbortError') {
          return;
        }

        if ('message' in error && error.message) {
          setError(error.message);
          setLoading(false);
          return;
        }

        console.error(error);
        setLoading(false);
        setError(error);
      }
    },
    [url, useAuth]
  );

  useEffect(() => {
    if (!manual && !data) {
      const controller = new AbortController();
      load({ signal: controller.signal });

      return () => {
        controller.abort();
        setError(null);
      };
    }
  }, [manual, load, data]);

  return { loading, error, data, load };
}

export default useRequest;
