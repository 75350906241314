/** @jsxImportSource @emotion/react */
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  css,
  Box,
  Typography,
  TableFooter,
  IconButton,
} from '@mui/material';
import React, { ForwardedRef, forwardRef, useCallback, useState } from 'react';
import { isReferralPartnerCustomer } from '../../../utils';

import StatusBadge from '../../../components/badge';
import ProfileCell from './ProfileCell';
import { getChannelDetails } from '../../../utils/getSetChannelDetails';
import getOrganisationAPI from '../request/getOrganisation';
import {
  OrgListCustomersProps,
  OrgListCustomersResponsePaginationArgs,
} from '../../home/types';
import Loader from '../../../components/loader';
import { formatCurrency } from '../../../utils/currencyFormatter';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  organisationsListAtom,
  organisationsSearchTermAtom,
  organisationsSortTermAtom,
  organisationsTotalOrgs,
} from '../states';
import { walletConfigAtom } from '../../wallet/state';
import usePaginatedQuery, {
  UsePaginatedQueryResult,
} from '../../../hooks/usePaginatedQuery';
import { TableVirtuoso } from 'react-virtuoso';
import { ROUTES } from '../../../routes/auth-routes/routes';
import { useNavigate } from 'react-router-dom';
import { MoreVert } from '@mui/icons-material';
import useNotifications from '../../../hooks/useNotifications';
import { deleteOrgApi } from '../request';
import { formatShortDate } from '../../../utils/date';
import DeleteOrgConfirmationModal from './DeleteOrgConfirmationModal';
import RowActionMenu from './RowActionMenu';

const TableComponents = {
  Scroller: forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => (
    <TableContainer {...props} ref={ref} />
  )),
  Table: (props: any) => (
    <Table {...props} style={{ borderCollapse: 'separate' }} />
  ),
  TableHead: TableHead as any,
  TableRow: TableRow as any,
  TableBody: forwardRef((props, ref: ForwardedRef<HTMLTableSectionElement>) => (
    <TableBody {...props} ref={ref} />
  )),
  TableFoot: forwardRef((props, ref: ForwardedRef<HTMLTableSectionElement>) => (
    <TableFooter
      {...props}
      ref={ref}
      style={{
        position: 'unset',
      }}
    />
  )),
};

const OrgListTable = () => {
  const orgSearchValue = useAtomValue(organisationsSearchTermAtom);
  const orgSortValue = useAtomValue(organisationsSortTermAtom);
  const userWallet = useAtomValue(walletConfigAtom);
  const setTotalOrgs = useSetAtom(organisationsTotalOrgs);
  const navigation = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOrg, setSelectedOrg] = useState<OrgListCustomersProps | null>(
    null
  );
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const { addNotification } = useNotifications();
  const isReferralPartner = isReferralPartnerCustomer();

  const handleActionClick = (
    event: React.MouseEvent<HTMLElement>,
    org: OrgListCustomersProps
  ) => {
    setSelectedOrg(org);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedOrg(null);
    setAnchorEl(null);
  };

  const getter = useCallback(
    async (paginationData?: OrgListCustomersResponsePaginationArgs) => {
      const channelDetails = getChannelDetails();
      const response = await getOrganisationAPI(
        channelDetails?.id,
        {
          search: orgSearchValue,
          sort: {
            sortBy: orgSortValue.sortFilter,
            direction: orgSortValue.direction,
          },
        },
        paginationData
      );
      setTotalOrgs(response.paginationOptions.totalCount);
      return {
        data: response.customers,
        paginationData: {
          hasMore: response.paginationOptions.hasMore,
          nextClientId: response.paginationOptions.nextClientId,
          nextClientValue: response.paginationOptions.nextClientValue,
          totalCount: response.paginationOptions.totalCount,
        },
      } as UsePaginatedQueryResult<
        OrgListCustomersProps,
        OrgListCustomersResponsePaginationArgs
      >;
    },
    [
      orgSearchValue,
      orgSortValue.sortFilter,
      orgSortValue.direction,
      setTotalOrgs,
    ]
  );

  const {
    requestData: { loading, error, data: orgCustomers, isFetchingMore },
    fetchMore: fetchMoreCustomerSegments,
  } = usePaginatedQuery<
    OrgListCustomersProps,
    OrgListCustomersResponsePaginationArgs
  >({
    requestAtom: organisationsListAtom,
    getter: getter,
    cacheData: false,
  });

  const handleDeleteConfirmationModalCancelClick = () => {
    setShowDeleteConfirmationModal(false);
    handleClose();
  };

  const handleDeleteConfirmationModalOkClick = async () => {
    if (!selectedOrg) {
      return;
    }
    try {
      await deleteOrgApi({
        orgId: selectedOrg.id,
      });

      orgCustomers?.splice(
        orgCustomers.findIndex((org) => org.id === selectedOrg.id),
        1
      );

      addNotification({
        message: 'Org deleted successfully',
        type: 'success',
      });
    } catch (e) {
      console.log(e);
      addNotification({
        message: (e as Error).message || 'Something went wrong',
        type: 'error',
      });
    } finally {
      setShowDeleteConfirmationModal(false);
      handleClose();
    }
  };

  const renderTableRows = () => {
    if (loading) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={9}>
              <Box
                css={css`
                  height: 300px;
                  width: 100%;
                `}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={'8px'}
              >
                <Loader />
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
    let emptyTableInfo = !orgSearchValue
      ? 'No organisation added yet'
      : 'No organisation found';
    if (error) {
      emptyTableInfo = 'An error occurred. Please try again';
    }
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={9}>
            <Box
              css={css`
                height: 300px;
                margin: auto;
              `}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={'8px'}
            >
              <Typography
                css={css`
                  color: rgba(0, 0, 0, 0.87);
                  font-size: 14px;
                  font-weight: 400;
                `}
              >
                {emptyTableInfo}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const renderHeader = () => {
    const columns = [
      'Name',
      'Created on',
      'Org ID',
      'Status',
      'Members',
      'WABA',
      'Credits',
      'Ends on',
      ...(isReferralPartner ? [] : ['Action']),
    ];
    return (
      <TableRow
        css={css`
          background-color: #fff;
        `}
      >
        {columns.map((column, index) => (
          <TableCell key={index}>
            <Typography
              align={
                column === 'Name' || column === 'Action' ? 'left' : 'center'
              }
              css={css`
                font-size: 14px;
                font-weight: 500;
                color: '#2d2d2d';
              `}
            >
              {column}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const getDataRowMap = useCallback(
    (index: number, orgCustomer: OrgListCustomersProps) => {
      const handleOnClick = () => {
        navigation(`${ROUTES.orgProfilePage}?orgId=${orgCustomer.id}`);
      };
      return (
        <>
          <TableCell
            component="th"
            scope="row"
            onClick={handleOnClick}
            style={{ cursor: 'pointer', paddingRight: '0px', width: '250px' }}
          >
            <ProfileCell
              name={orgCustomer.name}
              primaryTextStyles={{
                overflow: 'auto',
                whiteSpace: 'wrap',
                wordBreak: 'break-word',
              }}
            />
          </TableCell>
          <TableCell
            onClick={handleOnClick}
            style={{ cursor: 'pointer' }}
            align="center"
            width={150}
          >
            {orgCustomer.dateCreated
              ? formatShortDate(orgCustomer.dateCreated)
              : '-'}
          </TableCell>
          <TableCell
            onClick={handleOnClick}
            style={{ cursor: 'pointer' }}
            align="center"
          >
            {orgCustomer.id}
          </TableCell>
          <TableCell
            onClick={handleOnClick}
            style={{ cursor: 'pointer' }}
            align="center"
          >
            <StatusBadge type={orgCustomer.status} />
          </TableCell>
          <TableCell
            onClick={handleOnClick}
            style={{ cursor: 'pointer' }}
            align="center"
          >
            {orgCustomer.memberCount}
          </TableCell>
          <TableCell
            onClick={handleOnClick}
            style={{ cursor: 'pointer' }}
            align="center"
          >
            {orgCustomer.wabaCount}
          </TableCell>
          <TableCell
            onClick={handleOnClick}
            style={{ cursor: 'pointer' }}
            align="center"
          >
            {formatCurrency(
              orgCustomer.credits ?? 0,
              orgCustomer.currencyCode ?? userWallet?.data?.currencyCode
            )}
          </TableCell>
          <TableCell
            onClick={handleOnClick}
            style={{ cursor: 'pointer' }}
            align="center"
          >
            {orgCustomer.subscriptionEndsOn
              ? formatShortDate(orgCustomer.subscriptionEndsOn)
              : '-'}
          </TableCell>
          {!isReferralPartner && (
            <TableCell>
              <IconButton
                aria-describedby={!!anchorEl ? 'simple-menu' : undefined}
                id="simple-button"
                onClick={(e) => handleActionClick(e, orgCustomer)}
                style={{ cursor: 'pointer' }}
                aria-label="more"
                aria-controls={!!anchorEl ? 'long-menu' : undefined}
                aria-expanded={!!anchorEl ? 'true' : undefined}
                aria-haspopup="true"
              >
                <MoreVert />
              </IconButton>
            </TableCell>
          )}
        </>
      );
    },
    [userWallet?.data?.currencyCode, navigation, anchorEl, isReferralPartner]
  );

  const renderLoaderInFooter = () => {
    if (isFetchingMore) {
      return (
        <TableRow>
          <TableCell colSpan={7}>
            <Box
              css={css`
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              <Loader />
            </Box>
          </TableCell>
        </TableRow>
      );
    }
    return null;
  };

  if (
    !loading &&
    !error &&
    Array.isArray(orgCustomers) &&
    orgCustomers.length > 0
  ) {
    return (
      <>
        <TableVirtuoso
          style={{
            height: `70vh`,
            width: '100%',
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            backgroundColor: '#fff',
          }}
          css={css`
            &::-webkit-scrollbar {
              display: none; /* Hide scrollbar for WebKit browsers */
            }
            -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
            scrollbar-width: none; /* Hide scrollbar for Firefox */
          `}
          data={orgCustomers}
          fixedHeaderContent={renderHeader}
          totalCount={(orgCustomers ?? []).length}
          itemContent={getDataRowMap}
          endReached={fetchMoreCustomerSegments}
          components={{
            ...TableComponents,
            EmptyPlaceholder: () => <>...</>,
          }}
          fixedFooterContent={renderLoaderInFooter}
        />
        <RowActionMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          showDeleteConfirmationModal={showDeleteConfirmationModal}
          handleDeleteClick={() => setShowDeleteConfirmationModal(true)}
        />
        <DeleteOrgConfirmationModal
          open={showDeleteConfirmationModal}
          onCancelClick={handleDeleteConfirmationModalCancelClick}
          onOkClick={handleDeleteConfirmationModalOkClick}
          selectedOrg={selectedOrg}
        />
      </>
    );
  }

  return (
    <TableContainer
      component={Paper}
      css={css`
        border-radius: 12px;
      `}
    >
      <Table sx={{ minWidth: 650 }} aria-label="org table">
        <TableHead>{renderHeader()}</TableHead>
        {renderTableRows()}
      </Table>
    </TableContainer>
  );
};

export default OrgListTable;
