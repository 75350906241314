import { ChannelPartnerState, ChannelPartnerStateResponseDetailsMetaType, ChannelPartnerStateResponseBillingMetaType } from '../../../../types';
import { getCountryFromMobileNumber } from '../../../../utils/countries-mapper';
import {
  OnboardingBillingDetails,
  OnboardingCompanyDetails,
} from '../../../onboarding/types';
import isEqual from 'lodash.isequal';

const areDetailsChanged = (
  whitelabelPartnerProfileDetails: ChannelPartnerState,
  editWhitelabelPartnerWebsiteInfo: OnboardingCompanyDetails,
  editWhitelabelPartnerBillingDetails: OnboardingBillingDetails
) => {
  if (
    !whitelabelPartnerProfileDetails ||
    !editWhitelabelPartnerWebsiteInfo ||
    !editWhitelabelPartnerBillingDetails
  ) {
    return false;
  }

  // Get the saved details from the profile
  const savedDetails = whitelabelPartnerProfileDetails.currentStateMetadata;
  if (!savedDetails) {
    return true; // If no saved details, consider it as changed
  }

  const savedCountry = getCountryFromMobileNumber(
    savedDetails.SET_PARTNER_DETAILS?.supportNumber
  );
  let savedSupportNumber = savedDetails.SET_PARTNER_DETAILS?.supportNumber;
  if (savedCountry) {
    if (savedSupportNumber) {
      savedSupportNumber = savedSupportNumber.substring(
        savedCountry.symbol.length - 1
      );
    }
  }

  // Map website info fields
  const savedWebsiteInfo: Partial<OnboardingCompanyDetails> = {
    name: savedDetails.SET_PARTNER_DETAILS?.name,
    domain: savedDetails.SET_PARTNER_DETAILS?.domain,
    supportNumber: savedSupportNumber,
    logo: savedDetails.SET_PARTNER_DETAILS?.logoUrl,
    savedCountry: savedCountry,
  };

  // Map billing details fields
  const savedBillingInfo: Partial<OnboardingBillingDetails> = {
    countryCode: savedDetails.SET_PARTNER_BILLING?.countryCode,
    state: savedDetails.SET_PARTNER_BILLING?.state,
    city: savedDetails.SET_PARTNER_BILLING?.city,
    pinCode: savedDetails.SET_PARTNER_BILLING?.pinCode,
    email: savedDetails.SET_PARTNER_BILLING?.email,
    billingTaxNumber: savedDetails.SET_PARTNER_BILLING?.billingTaxNumber,
    address: savedDetails.SET_PARTNER_BILLING?.address,
    fullName: savedDetails.SET_PARTNER_BILLING?.fullName,
  };
  // Return true if fields are different, false if they are the same
  return (
    !isEqual(savedWebsiteInfo, editWhitelabelPartnerWebsiteInfo) ||
    !isEqual(savedBillingInfo, editWhitelabelPartnerBillingDetails)
  );
};

export default areDetailsChanged;
