/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import React, { FC } from 'react';

interface ActionButtonProps extends IconButtonProps {
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  tooltip?: string;
  background?: string;
  iconColor?: string;
}

const ActionButton: FC<ActionButtonProps> = ({
  tooltip,
  Icon,
  background = '#fff',
  iconColor = '#000',
  ...props
}) => {
  return (
    <div
      css={css`
        display: flex;
        padding: 6px;
        justify-content: center;
        align-items: center;
        border-radius: 7px;
        border: 1px solid #e0e0e9;
        background: ${background};
        height: 32px;
        width: 32px;
      `}
    >
      <IconButton<'div'>
        component="div"
        css={css`
          margin: 0px 8px;
          border-radius: 7px;
          height: 32px;
          width: 32px;
        `}
        {...(props as any)}
      >
        <Tooltip title={tooltip} placement="top">
          <Icon
            css={css`
              height: 18px;
              width: 18px;
              * {
                stroke: ${iconColor};
              }
            `}
          />
        </Tooltip>
      </IconButton>
    </div>
  );
};

export default ActionButton;
