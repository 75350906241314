/** @jsxImportSource @emotion/react */
import { Box, Button, FormGroup, TextField, Typography } from '@mui/material';
import { css } from '@emotion/react';
import { editWhitelabelPartnerBillingDetailsAtom, editWhitelabelPartnerProfileErrorAtom, editWhitelabelPartnerProfileLoadingAtom, errorFieldsAtom } from '../../state';
import { useCallback, useMemo, useState } from 'react';
import useNotifications from '../../../../../hooks/useNotifications';
import { putCompanyBillingDetails } from '../../../../onboarding/requests/companyDetails';
import { getChannelDetails } from '../../../../../utils/getSetChannelDetails';
import WithLabel from '../../../../../components/input/WithLabel';
import CountryStateCitySelect from '../../../../CountryStateCitySelect';
import { useAtom } from 'jotai';
import isEmail from 'validator/lib/isEmail';

const WhitelabelPartnerBillingDetails = () => {
  const [
    editWhitelabelPartnerBillingDetails,
    setEditWhitelabelPartnerBillingDetails,
  ] = useAtom(editWhitelabelPartnerBillingDetailsAtom);
  const [isApiError, setIsApiError] = useAtom(editWhitelabelPartnerProfileErrorAtom);
  const [isLoadingAPI, setIsLoadingAPI] = useAtom(editWhitelabelPartnerProfileLoadingAtom);
  const { addNotification } = useNotifications();
  const [errorFields, setErrorFields] = useAtom(errorFieldsAtom);
  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorFields((prev) => ({
      ...prev,
      [e.target.name]: null,
    }));
    setEditWhitelabelPartnerBillingDetails({
      ...editWhitelabelPartnerBillingDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = useCallback(
    ({
      countryCode,
      state,
      city,
    }: {
      countryCode?: string;
      state?: string;
      city?: string;
    }) => {
      setEditWhitelabelPartnerBillingDetails({
        ...editWhitelabelPartnerBillingDetails,
        countryCode: countryCode ?? '',
        state: state ?? '',
        city: city ?? '',
      });
    },
    [
      editWhitelabelPartnerBillingDetails,
      setEditWhitelabelPartnerBillingDetails,
    ]
  );

  return (
    <Box
      css={css`
        display: flex;
        width: 100%;
        max-width: 800px;
        padding: 18px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        border-radius: 8px;
        border: 1px solid var(--Neutral-Stroke, #e0e0e9);
        background: #fff;
      `}
    >
      <Typography
        css={css`
          color: #2d2d2d;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        `}
      >
        Billing details
      </Typography>
      <FormGroup
        css={css`
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: 100%;
        `}
      >
        <WithLabel label="Full name">
          <TextField
            name="fullName"
            error={!!errorFields.fullName}
            helperText={errorFields.fullName}
            value={editWhitelabelPartnerBillingDetails.fullName}
            placeholder="Enter your first and last name"
            onChange={handleTextInputChange}
          />
        </WithLabel>
        <WithLabel label="Email ID">
          <TextField
            name="email"
            error={!!errorFields.email}
            helperText={errorFields.email}
            value={editWhitelabelPartnerBillingDetails.email}
            placeholder="Enter your email"
            onChange={handleTextInputChange}
          />
        </WithLabel>
        <WithLabel label="Address">
          <TextField
            value={editWhitelabelPartnerBillingDetails.address}
            name="address"
              error={!!errorFields.address}
            helperText={errorFields.address}
            placeholder="Enter your address"
            onChange={handleTextInputChange}
          />
        </WithLabel>
        <CountryStateCitySelect
          countryCode={editWhitelabelPartnerBillingDetails.countryCode}
          state={editWhitelabelPartnerBillingDetails.state}
          city={editWhitelabelPartnerBillingDetails.city}
          cityError={!!errorFields.city}
          stateError={!!errorFields.state}
          countryError={!!errorFields.countryCode}
          onChange={handleSelectChange}
          disableCountrySelect
          showDisbledCountryInfo
        >
          <WithLabel label="Postal code">
            <TextField
              name="pinCode"
              type="number"
              error={!!errorFields.pinCode}
              helperText={errorFields.pinCode}
              value={editWhitelabelPartnerBillingDetails.pinCode}
              placeholder="Enter your postal code"
              onChange={handleTextInputChange}
            />
          </WithLabel>
        </CountryStateCitySelect>
        {editWhitelabelPartnerBillingDetails.countryCode === 'IN' && (
          <WithLabel label="Business GST number (optional)">
            <TextField
              value={editWhitelabelPartnerBillingDetails.billingTaxNumber}
              name="billingTaxNumber"
              error={!!errorFields.billingTaxNumber}
              helperText={errorFields.billingTaxNumber}
              placeholder="Enter your GST number"
              onChange={handleTextInputChange}
            />
          </WithLabel>
        )}
      </FormGroup>
    </Box>
  );
};

export default WhitelabelPartnerBillingDetails;
