import { atom } from 'jotai';
import {
  getCountryFromCountryCode,
  getCountryCodeFromLocalStorage,
} from '../../../utils/countries-mapper';
import {
  ReferralPartnerOnboardingBankDetails,
  ReferralPartnerOnboardingBusinessDetails,
  ReferralPartnerOnboardingPersonalDetails,
  ReferralPartnerType,
} from '../types';

export const referralPartnerPersonalDetailsAtom =
  atom<ReferralPartnerOnboardingPersonalDetails>({
    fullName: '',
    email: '',
    address: '',
    country: getCountryFromCountryCode(
      getCountryCodeFromLocalStorage() ?? 'IN'
    )!,
    state: '',
    city: '',
    pincode: '',
    pan: null,
    panNumber: '',
    countryCode: getCountryFromCountryCode(
      getCountryCodeFromLocalStorage() ?? 'IN'
    )!.countryCode,
    taxIdentificationNumber: '',
    taxIdentificationDocument: null,
    addressProof: null,
  });

export const referralPartnerBankDetailsAtom =
  atom<ReferralPartnerOnboardingBankDetails>({
    bankName: '',
    bankAddress: '',
    bankAccountNumber: '',
    ifscCode: '',
    cancelChequeScan: null,
    swiftCode: '',
    accountHolderName: '',
    additionalInformation: '',
  });

export const referralPartnerTypeAtom = atom<ReferralPartnerType>(
  ReferralPartnerType.INDIVIDUAL
);

export const referralPartnerBusinessDetailsAtom =
  atom<ReferralPartnerOnboardingBusinessDetails>({
    companyName: '',
    businessPersonName: '',
    businessContactDetails: '',
    businessEmailId: '',
    gstNumber: '',
    gstCertificate: null as File | null,
    hasMsmeRegistration: false,
    msmeRegistrationCertificate: null as File | null,
    hasGst: false,
    noEstablishmentDeclaration: null as File | null,
    form10f: null as File | null,
  });
