import { useEffect, useMemo } from 'react';
import { urlCreator } from '../utils/url-helpers';
import useRequest from './useRequest';
import {
  GetProductPlans,
  GetProductPlansForKnownCountry,
  PlanUnit,
} from '../modules/referral-leads/typings/plan';

// Cache interface
interface CacheEntry {
  data: GetProductPlans;
  timestamp: number;
}

// Cache configuration
const CACHE_EXPIRATION_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds
const plansCache: Record<string, CacheEntry> = {};

const usePlanData = (countryCode: string | null) => {
  const planUrl = useMemo(() => {
    return urlCreator({
      baseUrl: '/v2/plan/all',
      query: {
        countryCode: countryCode,
        showHiddenPlans: true,
      },
      params: {},
    });
  }, [countryCode]);

  const { loading, error, data, load } = useRequest<GetProductPlans>({
    url: planUrl,
    options: {
      method: 'GET',
    },
    manual: true,
  });

  // Effect to reload data when planUrl changes
  useEffect(() => {
    const loadData = async () => {
      // Check cache first
      const cacheKey = countryCode || 'default';
      const cachedData = plansCache[cacheKey];
      const now = Date.now();

      // If we have valid cached data, use it
      if (cachedData && (now - cachedData.timestamp) < CACHE_EXPIRATION_TIME) {
        return;
      }

      // Otherwise load fresh data
      const freshData = await load({});
      if (freshData) {
        plansCache[cacheKey] = {
          data: freshData,
          timestamp: now,
        };
      }
    };

    loadData();
  }, [planUrl, load, countryCode]);

  const plans = useMemo(() => {
    let plansData = (data as GetProductPlansForKnownCountry)?.plans;

    if (data && !('countryCodeNotSupported' in data)) {
      plansData = data.plans;
    }

    return (plansData ?? []).map((plan) => {
      return {
        ...plan,
        price: plan.price.map((price) => {
          if (price.unit === PlanUnit.YEAR) {
            return {
              ...price,
              amount: price.amount / 12,
              discountAmount: price.discountAmount / 12,
            };
          }

          if (price.unit === PlanUnit.MONTH && price.period === 3) {
            return {
              ...price,
              amount: price.amount / price.period,
              discountAmount: price.discountAmount / price.period,
            };
          }

          return price;
        }),
      };
    });
  }, [data]);

  // Get cached data if available
  const cacheKey = countryCode || 'default';
  const cachedData = plansCache[cacheKey];
  const now = Date.now();
  const isCacheValid = cachedData && (now - cachedData.timestamp) < CACHE_EXPIRATION_TIME;

  return { 
    loading: loading && !isCacheValid, 
    error, 
    plans, 
    data: isCacheValid ? cachedData.data : data,
    reload: () => {
      // Clear cache and reload
      delete plansCache[cacheKey];
      return load({});
    }
  };
};

export default usePlanData;
