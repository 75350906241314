/** @jsxImportSource @emotion/react */
/* eslint-disable react-hooks/exhaustive-deps */

import { Routes, useLocation, useNavigate } from 'react-router-dom';
import getRoutesV1 from './v1';
import { useCallback, useEffect, useRef } from 'react';
import { getOnboardingState } from '../requests/getOnboardingStatus';
import { getChannelDetails } from '../../utils/getSetChannelDetails';
import { ROUTES } from './routes';
import { ChannelPartnerState, ChannelPartnerStateType, ChannelPartnerType } from '../../types';
import { useSetAtom } from 'jotai';
import {
  onboardingBillingDetailsAtom,
  onboardingCompanyDetailsAtom,
} from '../../modules/onboarding/states';
import { getCountryFromMobileNumber } from '../../utils/countries-mapper';
import useGetSetDefaultOnboardingState from '../../hooks/useGetSetDefaultOnboardingState';
import { referralPartnerBankDetailsAtom, referralPartnerBusinessDetailsAtom, referralPartnerPersonalDetailsAtom, referralPartnerTypeAtom } from '../../modules/referral-partner-onboarding/states';
import { GetChannelPartnerReferralDetailsResponse, ReferralPartnerType } from '../../modules/referral-partner-onboarding/types';
import { getReferralPartnerAllDetails } from '../../modules/referral-partner-onboarding/requests';
import { ReferralPartnerStateType } from '../../modules/onboarding/types';
export default function AppProtectedRoutes() {
  const navigate = useNavigate();
  const location = useLocation();
  const setOnboardingDetails = useSetAtom(onboardingCompanyDetailsAtom);
  const setOnboardingBillingDetails = useSetAtom(onboardingBillingDetailsAtom);
  const setReferralPartnerBankDetails = useSetAtom(referralPartnerBankDetailsAtom);
  const setReferralPartnerBusinessDetails = useSetAtom(referralPartnerBusinessDetailsAtom);
  const setReferralPartnerPersonalDetails = useSetAtom(referralPartnerPersonalDetailsAtom);
  const currentStep = new URLSearchParams(location.search).get('step');
  const { getSetDefaultState } = useGetSetDefaultOnboardingState();
  const setReferralPartnerType = useSetAtom(referralPartnerTypeAtom);
  const previousPath = useRef(location.pathname); 

  const setReferralPartnerOnboardingAtomData = useCallback(
    (response: GetChannelPartnerReferralDetailsResponse) => {
      if (response) {
        const isInternational = response?.countryCode !== 'IN';
        setReferralPartnerPersonalDetails({
          address: response.address,
          countryCode: response.countryCode ?? 'IN',
          state: response.state,
          city: response.city,
          pincode: response.pinCode,
          email: response.email,
          taxIdentificationNumber: response?.taxIdNumber,
          addressProof: response?.addressProofUrl,
          fullName: response?.fullName,
          panNumber: response?.panNumber,
          pan: response?.panProofUrl,
          taxIdentificationDocument: response?.taxIdProofUrl,
        });

        if (response.bankAccountNumber) {
          setReferralPartnerBankDetails({
            accountHolderName: response?.accountHolderName,
            bankAccountNumber: response?.bankAccountNumber,
            bankAddress: response?.bankAddress,
            bankName: response?.bankName,
            cancelChequeScan: response?.cancelledChequeUrl,
            swiftCode: response?.swiftCode,
            ifscCode: response?.ifscCode ,
            additionalInformation: isInternational
              ? response?.additionalInfo
              : '',
          });
        }

        if (response.referralPartnerType === ReferralPartnerType.BUSINESS) {
          setReferralPartnerBusinessDetails({
            companyName: response?.companyName,
            businessPersonName: response?.contactName,
            businessContactDetails: response?.contactNumber,
            businessEmailId: response?.contactEmail,
            hasGst: response?.gstCertificateUrl ? true : false,
            gstCertificate: response?.gstCertificateUrl,
            gstNumber: response?.gstNumber,
            form10f: response?.form10FProofUrl,
            hasMsmeRegistration: response?.msmeCertificateUrl ? true : false,
            msmeRegistrationCertificate: response?.msmeCertificateUrl,
            noEstablishmentDeclaration: response?.noPermEstProofUrl,
          });
        }
      }
    },
    [
      setReferralPartnerPersonalDetails,
      setReferralPartnerBankDetails,
      setReferralPartnerBusinessDetails,
    ]
  );

  const setOnboardingAtomData = useCallback(
    (response: ChannelPartnerState) => {
      if (response.currentStateMetadata) {
        const partnerDetailsData =
          response?.currentStateMetadata?.SET_PARTNER_DETAILS;
        const partnerBillingData =
          response?.currentStateMetadata?.SET_PARTNER_BILLING;
        if (partnerDetailsData) {
          const savedCountry = getCountryFromMobileNumber(
            partnerDetailsData?.supportNumber
          );
          let savedSupportNumber = partnerDetailsData?.supportNumber;
          if (savedCountry) {
            if (savedSupportNumber) {
              savedSupportNumber = savedSupportNumber.substring(
                savedCountry.symbol.length - 1
              );
            }
          }
          setOnboardingDetails({
            name: partnerDetailsData?.name,
            domain: partnerDetailsData?.domain,
            supportNumber: savedSupportNumber,
            logo: partnerDetailsData?.logoUrl,
            savedCountry: getCountryFromMobileNumber(
              partnerDetailsData?.supportNumber
            ),
          });
        }
        if (partnerBillingData) {
          setOnboardingBillingDetails({
            countryCode: partnerBillingData?.countryCode,
            state: partnerBillingData?.state,
            city: partnerBillingData?.city,
            pinCode: partnerBillingData?.pinCode,
            email: partnerBillingData?.email,
            billingTaxNumber: partnerBillingData?.billingTaxNumber,
            fullName: partnerBillingData?.fullName,
            address: partnerBillingData?.address,
          });
        } else {
          getSetDefaultState();
        }
      }
    },
    [setOnboardingDetails, setOnboardingBillingDetails]
  );

  useEffect(() => {
    const channelDetails = getChannelDetails();
    if(!channelDetails || channelDetails?.partnerType === ChannelPartnerType.REFERRAL) return;
    let timeOut: NodeJS.Timeout | null = null;
    const redirectNextState = async (isRetry?: boolean) => {
      // setIsLoading(!isRetry);
      const channelDetails = getChannelDetails();
      if (channelDetails?.id) {
        try {
          const response = await getOnboardingState(channelDetails?.id);
          if (response) {
            setOnboardingAtomData(response);
          }
          if (!response?.state) {
            navigate(ROUTES.onboardPage);
          } else if (response?.state === ChannelPartnerStateType.NO_ACTION) {
            if (window.location.pathname === ROUTES.onboardPage) {
              navigate(ROUTES.homePage, { state: { welcomePopup: true } });
            }
          } else {
            if (response?.state !== currentStep) {
              navigate(ROUTES.onboardPage + `?step=${response.state}`);
            }
          }
          if (response?.state === ChannelPartnerStateType.WAITING_FOR_REVIEW) {
            timeOut = setTimeout(() => {
              redirectNextState(true);
            }, 10000);
          }
        } catch (e) {
          // setIsLoading(false);
          console.log(e);
        }
      }
    };
    redirectNextState();
    if (currentStep === ChannelPartnerStateType.WAITING_FOR_REVIEW) {
      redirectNextState(true);
    }
    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, [navigate, currentStep, setOnboardingAtomData]);

  useEffect(() => {
    const channelDetails = getChannelDetails();
    if(!channelDetails || channelDetails?.partnerType === ChannelPartnerType.WHITELABEL) return;
    let timeOut: NodeJS.Timeout | null = null;
    const currentPath = location.pathname;

    const redirectReferralPartnerOnboardingNextState = async (isRetry?: boolean) => {
      // Skip the redirect if we're moving backwards in the onboarding flow
      const isMovingBackward = isBackwardNavigation(previousPath.current, currentPath);
      if (isMovingBackward && !isRetry) {
        return;
      }

      try {
        const response = await getReferralPartnerAllDetails();
        if (response) {
          setReferralPartnerOnboardingAtomData(response);
          setReferralPartnerType(
            response?.referralPartnerType ?? ReferralPartnerType.INDIVIDUAL
          );
        }

        const isInReferralPartnerOnboardingFlow = currentPath.includes(ROUTES.REFERRAL_PARTNER_ONBOARDING);

        if (!response?.channelPartnerState) {
          navigate(`${ROUTES.REFERRAL_PARTNER_ONBOARDING}${ROUTES.REFERRAL_PARTNER_ONBOARDING_PERSONAL_DETAILS}`);
          return;
        }

        if (response?.channelPartnerState === ReferralPartnerStateType.NO_ACTION) {
          if (isInReferralPartnerOnboardingFlow) {
            navigate(ROUTES.homePage);
          }
          return;
        }

        const validPathForState = getValidPathForState(response?.channelPartnerState);
        if (validPathForState && !isInReferralPartnerOnboardingFlow) {
          navigate(`${ROUTES.REFERRAL_PARTNER_ONBOARDING}${validPathForState}`);
          return;
        }

        if (response?.channelPartnerState === ReferralPartnerStateType.WAITING_FOR_REVIEW) {
          timeOut = setTimeout(() => {
            redirectReferralPartnerOnboardingNextState(true);
          }, 10000);
        }
      } catch (e) {
        console.log(e);
      }
    };

    const getValidPathForState = (state?: ReferralPartnerStateType) => {
      switch (state) {
        case ReferralPartnerStateType.SET_PARTNER_DETAILS:
          return ROUTES.REFERRAL_PARTNER_ONBOARDING_PERSONAL_DETAILS;
        case ReferralPartnerStateType.SET_BUSINESS_DETAILS:
          return ROUTES.REFERRAL_PARTNER_ONBOARDING_BUSINESS_DETAILS;
        case ReferralPartnerStateType.SET_PARTNER_BILLING:
          return ROUTES.REFERRAL_PARTNER_ONBOARDING_BANK_DETAILS;
        case ReferralPartnerStateType.APPLY_FOR_REVIEW:
          return ROUTES.REFERRAL_PARTNER_ONBOARDING_REVIEW;
        case ReferralPartnerStateType.WAITING_FOR_REVIEW:
          return ROUTES.REFERRAL_PARTNER_ONBOARDING_WAITING_REVIEW;
        default:
          return null;
      }
    };

    // Helper function to determine if navigation is backward in the flow
    const isBackwardNavigation = (prevPath: string, currentPath: string) => {
      const onboardingPaths = [
        ROUTES.REFERRAL_PARTNER_ONBOARDING_PERSONAL_DETAILS,
        ROUTES.REFERRAL_PARTNER_ONBOARDING_BUSINESS_DETAILS,
        ROUTES.REFERRAL_PARTNER_ONBOARDING_BANK_DETAILS,
        ROUTES.REFERRAL_PARTNER_ONBOARDING_REVIEW,
        ROUTES.REFERRAL_PARTNER_ONBOARDING_WAITING_REVIEW
      ];

      const prevIndex = onboardingPaths.findIndex(path => prevPath.includes(path));
      const currentIndex = onboardingPaths.findIndex(path => currentPath.includes(path));

      return prevIndex > currentIndex && prevIndex !== -1 && currentIndex !== -1;
    };

    redirectReferralPartnerOnboardingNextState();
    if (currentPath === ReferralPartnerStateType.WAITING_FOR_REVIEW) {
      redirectReferralPartnerOnboardingNextState(true);
    }

    // Update previous path after handling navigation
    previousPath.current = currentPath;

    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, [location.pathname, navigate, setReferralPartnerOnboardingAtomData, setReferralPartnerType]);

  // if (isLoading) {
  //   return (
  //     <Box height={'100vh'} width={'100%'}>
  //       <Loader size={32} secondary={'Loading...'} />;
  //     </Box>
  //   );
  // }

  return <Routes>{getRoutesV1()}</Routes>;
}
