/** @jsxImportSource @emotion/react */
import {
  Box,
  Button,
  ButtonBase,
  css,
  FormGroup,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { Dispatch, useState } from 'react';
import WithLabel from '../../../../components/input/WithLabel';
import PhoneDropdown from '../../../countryPicker';
import { Country } from '../../../../types';
import {
  leadsListAtom,
  leadsSearchTermAtom,
  LeadsSortAtomReducer,
  leadsSortTermAtom,
  newReferralPartnerLeadAtom,
  newReferralPartnerLeadFormErrorFieldsInitialState,
  newReferralPartnerLeadFormErrorsAtom,
  plansDataLoadingAtom,
  selectedReferralLeadsPlanAtom,
  totalReferralLeadsAtom,
} from '../../state';
import { SetStateAction, useAtom, useAtomValue, useSetAtom } from 'jotai';
import LeadPlanTabs from '../plans-tabs';
import { postNewReferralPartnerLeadDetails } from '../../request';
import useNotifications from '../../../../hooks/useNotifications';
import { useReducerAtom, useResetAtom } from 'jotai/utils';
import getPlanDetails from '../../utils/get-plan-details';
import { handleApiError } from '../../../../utils/error-handler';
import { referralLeadsApiFormDataKeysToStateKeysMapping } from '../../constants';
import { LeadsSortTypes } from '../../typings';
interface AddLeadModalProps {
  open: boolean;
  handleClose: () => void;
}

interface LeadFormData {
  fullName: string;
  email: string;
  phoneNumber: string;
  gstNumber: string;
  saleAmount: string;
  country: Country;
}

const AddLeadModal: React.FC<AddLeadModalProps> = ({ open, handleClose }) => {
  const [formData, setFormData] = useAtom(newReferralPartnerLeadAtom);
  const [formErrors, setFormErrors] = useAtom(
    newReferralPartnerLeadFormErrorsAtom
  );
  const setLeadsListData = useSetAtom(leadsListAtom);
  const resetFormErrors = useResetAtom(newReferralPartnerLeadFormErrorsAtom);
  const plansDataLoading = useAtomValue(plansDataLoadingAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [isApiError, setIsApiError] = useState(false);
  const { addNotification } = useNotifications();
  const selectedPlan = useAtomValue(selectedReferralLeadsPlanAtom);
  const setTotalLeads = useSetAtom(totalReferralLeadsAtom);
  const [_, dispatch] = useReducerAtom(
    leadsSortTermAtom,
    LeadsSortAtomReducer
  );
  const setSearchValue = useSetAtom(leadsSearchTermAtom);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCountryChange = (selectedCountry: Country) => {
    setFormData({
      ...formData,
      country: selectedCountry,
    });
  };

  const handleSubmit = async () => {
    try {
      const planDetails = getPlanDetails(selectedPlan);
      if(!planDetails.planId || !planDetails.subscriptionPeriod) return;
      setIsApiError(false);
      setIsLoading(true);
      resetFormErrors();
      const response = await postNewReferralPartnerLeadDetails({
        fullName: formData.fullName,
        email: formData.email,
        phoneNumber: formData.country.symbol + formData.phoneNumber,
        gstNumber: formData.gstNumber ? formData.gstNumber : undefined,
        countryCode: formData.country.countryCode,
        pitchedPlan:planDetails.planId,
        subscriptionPeriod:planDetails.subscriptionPeriod,
        quotedPrice: Number(formData.saleAmount) > 0 ? Number(formData.saleAmount) : undefined,
        remarks: formData.remarks ? formData.remarks : undefined,
      });
      setIsLoading(false);
      if (response.lead) {
        const newLead = {
          id: response.lead.id,
          name: response.lead.name,
          email: response.lead.email,
          phone: response.lead.phone,
          gstNumber: response.lead.gstNumber,
          pitchedPlan: response.lead.pitchedPlan,
          subscriptionPeriod: response.lead.subscriptionPeriod,
          quotedPrice: response.lead.quotedPrice,
          countryCode: response.lead.countryCode,
          planId: response.lead.planId,
          currencyCode: response.lead.currencyCode,
          dateCreated: response.lead.dateCreated,
          remarks: response.lead.remarks,
        }
        setLeadsListData((prev) => ({
          ...prev,
          data: [newLead, ...(prev?.data || [])],
        }));
        setTotalLeads((prev) => prev + 1);
        setSearchValue('');
        dispatch({ type: LeadsSortTypes.reset });
        handleClose();
        addNotification({
          message: 'Lead added successfully',
          type: 'success',
        });
      } else {
        addNotification({
          message: 'Something went wrong',
          type: 'error',
        });
      }
    } catch (e) {
      setIsLoading(false);
      setIsApiError(true);
      handleApiError({
        error: e,   
        mappingObject: referralLeadsApiFormDataKeysToStateKeysMapping as unknown as Record<string, string>,
        errorFieldsInitialState: newReferralPartnerLeadFormErrorFieldsInitialState,
        setErrorFields: setFormErrors as unknown as Dispatch<SetStateAction<Record<string, string | null>>>,
        addNotification
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: 500,
          width: '100%',
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            height: 57,
            py: 1,
            px: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #e0e0e0',
            bgcolor: 'background.paper',
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}
        >
          <Typography variant="h6" component="h2" fontWeight={600}>
            Add Lead
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            overflowY: 'auto',
            p: 2,
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Box flex={1}>
              <FormGroup
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 12px;
                  margin: 6px 0;
                `}
              >
                <WithLabel label="Full Name">
                  <TextField
                    InputProps={{
                      style: { paddingRight: '48px' },
                    }}
                    fullWidth
                    name="fullName"
                    value={formData.fullName}
                    placeholder="Enter full name"
                    onChange={handleInputChange}
                    error={!!formErrors.fullName}
                    helperText={formErrors.fullName}
                  />
                </WithLabel>

                <WithLabel label="Email ID">
                  <TextField
                    fullWidth
                    name="email"
                    type="email"
                    value={formData.email}
                    placeholder="Enter email"
                    onChange={handleInputChange}
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                  />
                </WithLabel>

                <WithLabel label="Phone Number">
                  <Box display={'flex'} gap={1}>
                    <PhoneDropdown
                      containerHeight={'28px'}
                      ignoreCountryCode
                      value={formData.country}
                      onChange={handleCountryChange}
                    />
                    <TextField
                      fullWidth
                      name="phoneNumber"
                      type="number"
                      value={formData.phoneNumber}
                      placeholder="Enter your phone number"
                      onChange={handleInputChange}
                      error={!!formErrors.phoneNumber}
                      helperText={formErrors.phoneNumber}
                    />
                  </Box>
                </WithLabel>

                {formData.country.countryCode === 'IN' && (
                  <WithLabel label="GST number (optional)">
                    <TextField
                      fullWidth
                      name="gstNumber"
                      value={formData.gstNumber}
                      placeholder="Enter your GST number"
                      onChange={handleInputChange}
                      error={!!formErrors.gstNumber}
                      helperText={formErrors.gstNumber}
                    />
                  </WithLabel>
                )}
                <LeadPlanTabs countryCode={formData.country.countryCode} />
                <WithLabel label="Sale Amount (optional)">
                  <TextField
                    fullWidth
                    name="saleAmount"
                    type="number"
                    value={formData.saleAmount}
                    placeholder="Enter the amount you are selling the plan for"
                    onChange={handleInputChange}
                    error={!!formErrors.saleAmount}
                    helperText={formErrors.saleAmount}
                  />
                </WithLabel>
                <WithLabel label="Remarks (optional)">
                  <TextField
                    name="remarks"
                    value={formData.remarks}
                    placeholder="Write additional information here"
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    error={!!formErrors.remarks}
                    helperText={formErrors.remarks || ''}
                  />
                </WithLabel>
              </FormGroup>
            </Box>
          </Box>
        </Box>

        <Box
          css={css`
            border-top: 1px solid #e0e0e0;
            display: flex;
            padding: 12px 20px;
            justify-content: flex-end;
            align-items: center;
            gap: 12px;
            align-self: stretch;
          `}
        >
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <ButtonBase
              onClick={handleClose}
              css={css`
                display: flex;
                height: 40px;
                padding: 12px 16px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 4px;
                border: 1px solid #e0e0e9;
                background: #fbfbfc;
              `}
            >
              Cancel
            </ButtonBase>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={isLoading || plansDataLoading}
            >
              Add Lead
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddLeadModal;
