import { Country } from '../../../types';
import { PlanId, SubscriptionPeriod } from './plan';

export interface CreateReferralPartnerLeadResponse {
  leadId: string;
  orgName: string;
  channelPartnerId: string;
  userId: string;
}

export interface ReferralPartnerLeadFormData {
  fullName: string;
  orgName: string;
  email: string;
  phoneNumber: string;
  gstNumber: string;
  saleAmount: string;
  country: Country;
  remarks: string;
}

export interface ReferralPartnerLeadFormErrorData {
  fullName: string | null;
  email: string | null;
  phoneNumber: string | null;
  gstNumber: string | null;
  saleAmount: string | null;
  country: Country | null;
  remarks: string | null;
}

export interface SaveNewReferralPartnerLeadDetailsRequesType {
  fullName: string;
  email: string;
  phoneNumber: string;
  gstNumber?: string;
  countryCode: string;
  pitchedPlan: PlanId;
  subscriptionPeriod: SubscriptionPeriod;
  quotedPrice?: number;
  remarks?: string;
}

export interface CreateReferralPartnerLeadDetailsResponseType {
  lead: GetReferralPartnerLeadsProps;
  channelPartnerId: string;
  userId: string;
}

export interface LeadsSortTermAtomType {
  sortFilter: SortFields;
  direction: SortDirection;
  key: string;
}

export enum LeadsSortTypes {
  newest_first = 'newest_first',
  oldest_first = 'oldest_first',
  aToz = 'aToz',
  zToa = 'zToa',
  reset = 'reset',
}

export interface LeadsSortActionsType {
  type: LeadsSortTypes;
}

// Types for leads data
export interface Lead {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  gstNumber: string;
  createdOn: string;
  plan: string;
  sellingPrice: string;
}

export interface GetReferralPartnerLeadsProps {
  id: string;
  name: string;
  dateCreated: string;
  gstNumber: string;
  pitchedPlan: string;
  quotedPrice: number;
  countryCode: string;
  phone: string;
  email: string;
  subscriptionPeriod: SubscriptionPeriod;
  planId: PlanId;
  currencyCode: string;
  remarks?: string;
}

export interface GetLeadsPaginationOptions {
  nextClientId?: string;
  nextClientValue?: string;
  hasMore?: boolean;
  totalCount?: number;
}

export enum LeadsTableColumns {
  NAME = 'Name',
  EMAIL = 'Email',
  PHONE_NUMBER = 'Phone Number',
  GST_NUMBER = 'GST Number',
  CREATED_ON = 'Created On',
  PLAN = 'Plan',
  SELLING_PRICE = 'Selling Price',
  ACTION = 'Action',
}

export interface GetReferralPartnerLeadsPaginatedRequest {
  sortFilter: SortFilter;
  searchFilter: string;
  limit: number;
  paginationOptions?: GetLeadsPaginationOptions;
}

export interface SortFilter {
  sortBy: SortFields;
  direction: SortDirection;
}

export enum SortFields {
  name = 'name',
  dateCreated = 'dateCreated'
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface GetReferralPartnerLeadsPaginatedResponse {
  customers: GetReferralPartnerLeadsProps[];
  paginationOptions: GetLeadsPaginationOptions;
}

export interface FilterRequestData {
  search?: string;
  sort?: SortFilter;
}
