/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, Button, Card, Chip, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
// import { getChannelDetails } from '../../../utils/getSetChannelDetails';
import { useAtomValue } from 'jotai';
import { orgProfileAtom } from '../states';
import ProfileCell from '../../organisations/components/ProfileCell';
import useNotifications from '../../../hooks/useNotifications';
import { formatCurrency } from '../../../utils/currencyFormatter';
import AssignCreditsModal from '../../wallet/components/AssignCreditsModal';
import useRefetchWalletCredits from '../../../hooks/useRefetchWalletCredits';
import { ensureHttps } from '../../../utils/url';
import { AssignCreditSteps } from '../../wallet/types';
import { isReferralPartnerCustomer } from '../../../utils';

const ProfileInfoHeader = () => {
  const orgProfileValue = useAtomValue(orgProfileAtom);
  const [assignCreditModalOpen, setAssignCreditModalOpen] = useState(false);
  const { addNotification } = useNotifications();
  const { refreshData } = useRefetchWalletCredits();
  const isReferralPartner = isReferralPartnerCustomer();

  const handleCopyLink = () => {
    const linkToCopy = ensureHttps(orgProfileValue?.data?.customerLoginUrl);
    if (linkToCopy) {
      navigator.clipboard
        .writeText(linkToCopy)
        .then(() => {
          addNotification({
            message: 'Link copied',
            type: 'success',
          });
        })
        .catch((err) => {
          addNotification({
            message: 'error in link copy',
            type: 'error',
          });
        });
    }
  };

  const renderChip = () => {
    if (
      orgProfileValue?.data?.subscribedPlan?.displayName ===
      'DoubleTick Enterprise Plan'
    ) {
      return (
        <Chip
          label={'Enterprise Plan'}
          css={css`
            height: 24px;
            color: #363636;
            font-size: 12px;
            font-weight: 500;
            background: #36363616;
          `}
        />
      );
    }

    if (
      orgProfileValue?.data?.subscribedPlan?.displayName ===
      'DoubleTick Standard Plan'
    ) {
      return (
        <Chip
          label={'Standard Plan'}
          css={css`
            height: 24px;
            color: #4a3211;
            font-size: 12px;
            font-weight: 500;
            background: #4a321129;
          `}
        />
      );
    }

    if (
      orgProfileValue?.data?.subscribedPlan?.displayName ===
      'DoubleTick Pro Plan'
    ) {
      return (
        <Chip
          label={'Pro Plan'}
          css={css`
            height: 24px;
            color: #2e6644;
            font-size: 12px;
            font-weight: 500;
            background: #2e664429;
          `}
        />
      );
    }
    return (
      <Chip
        label={orgProfileValue?.data?.subscribedPlan?.displayName}
        css={css`
          height: 24px;
          color: #4a3211;
          font-size: 12px;
          font-weight: 500;
          background: #4a321129;
        `}
      />
    );
  };

  return (
    <Grid container>
      <Grid item xs>
        <Box>
          <ProfileCell
            name={orgProfileValue?.data?.subscribedPlan?.displayName ?? ''}
            height={'80px'}
            width={'80px'}
            hideName
          />
        </Box>
        <Typography
          css={css`
            color: #2d2d2d;
            font-size: 20px;
            margin-top: 8px;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 8px;
          `}
        >
          {orgProfileValue?.data?.org?.name}
          {renderChip()}
        </Typography>
        {/* <Typography
          css={css`
            color: #2d2d2d;
            font-size: 14px;
            font-weight: 400;
          `}
        >
          Last active 20 mins ago
        </Typography> */}
      </Grid>
      <Grid item xs>
        <Card
          css={css`
            padding: 20px;
          `}
        >
          <Grid
            container
            alignItems={'center'}
            gap={2}
            css={css`
              padding: 2px 12px;
              background: rgba(33, 150, 243, 0.04);
              border-radius: 12px;
            `}
          >
            <Grid item>
              <Typography
                css={css`
                  color: #2d2d2d;
                  font-size: 14px;
                  font-weight: 500;
                `}
              >
                {formatCurrency(
                  orgProfileValue?.data?.wallet?.balance ?? 0,
                  orgProfileValue?.data?.wallet?.currencyCode
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                css={css`
                  color: #2d2d2d;
                  font-size: 14px;
                  font-weight: 500;
                  padding: 6px;
                `}
              >
                Current Balance
              </Typography>
            </Grid>
            <Grid item xs></Grid>
            {!isReferralPartner && (
              <Grid item>
                <Button onClick={() => setAssignCreditModalOpen(true)}>
                  Assign credits
                </Button>
              </Grid>
            )}
          </Grid>
        </Card>
        <Card
          css={css`
            margin-top: 8px;
          `}
        >
          <Grid
            container
            alignItems={'center'}
            css={css`
              padding: 2px 12px;
              background: rgba(33, 150, 243, 0.04);
              border-radius: 12px;
            `}
          >
            <Grid item></Grid>
            <Grid item>
              <Typography
                css={css`
                  color: #2d2d2d;
                  font-size: 14px;
                  font-weight: 500;
                `}
              >
                {ensureHttps(orgProfileValue?.data?.customerLoginUrl)}
              </Typography>
            </Grid>
            <Grid item xs></Grid>
            <Grid item>
              <Button onClick={handleCopyLink}>Share link</Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {assignCreditModalOpen ? (
        <AssignCreditsModal
          open={assignCreditModalOpen}
          step={AssignCreditSteps.ASSIGN_CREDIT}
          organisation={orgProfileValue?.data}
          handleClose={() => {
            refreshData();
            setAssignCreditModalOpen(false);
          }}
        />
      ) : null}
    </Grid>
  );
};

export default ProfileInfoHeader;
