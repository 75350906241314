import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { Country } from '../typings';
import { getCountryFromSymbol } from '../../../utils/countries-mapper';

const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();

const validateAndTruncatePhoneNumber = ({
  phone,
  countryCode,
  countrySymbol,
}: {
  phone: string;
  countryCode: string;
  countrySymbol: string;
}) => {
  const parsedNumber = phoneUtil.parse(phone, countryCode);
  const number = phoneUtil.format(parsedNumber, PhoneNumberFormat.E164);
  return number;
};

export default validateAndTruncatePhoneNumber;

export const validatePhoneNumber = ({
  phone,
  countryCode,
  setCountry,
}: {
  phone: string;
  countryCode: string;
  setCountry?: (value: Country) => void;
}) => {
  try {
    const parsedNumber = phoneUtil.parseAndKeepRawInput(phone, countryCode);
    const countrySymbol = parsedNumber.getCountryCode();
    const isValidNumber = phoneUtil.isValidNumber(parsedNumber);
    const country = getCountryFromSymbol(`+${countrySymbol}`, countryCode);
    if (country && typeof setCountry === 'function') {
      setCountry(country);
    }
    return isValidNumber;
  } catch (error) {
    return false;
  }
};

export const parsePhoneNumber = (phone: string) => {
  try {
    const number = phoneUtil.parseAndKeepRawInput(phone);
    return {
      countryCode: number.getCountryCode(),
      phone: number.getNationalNumber(),
    };
  } catch (error) {
    return {
      countryCode: undefined,
      phone: undefined,
    };
  }
};
