/** @jsxImportSource @emotion/react */
import {
  TextField,
  InputAdornment,
  Grid,
  Button,
  MenuItem,
  Select,
  OutlinedInput,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SwapVertRoundedIcon from '@mui/icons-material/SwapVertRounded';
import { css } from '@emotion/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import { useReducerAtom } from 'jotai/utils';
import { LeadsSortTypes } from '../../typings';
import { isAddLeadModalOpenAtom, leadsSearchTermAtom, LeadsSortAtomReducer, leadsSortTermAtom } from '../../state';
import debounce from '../../../../utils/debounce';

const SearchAndFilters = () => {
  const navigate = useNavigate();
  const [selectedSort, dispatch] = useReducerAtom(
    leadsSortTermAtom,
    LeadsSortAtomReducer
  );
  const setSearchValue = useSetAtom(leadsSearchTermAtom);
  const debouncedSetSearchValue = debounce(setSearchValue, 300);
  const setIsAddLeadModalOpen = useSetAtom(isAddLeadModalOpenAtom);
  useEffect(() => {
    return () => {
      setSearchValue('');
      dispatch({ type: LeadsSortTypes.reset });
    };
  }, [setSearchValue, dispatch]);

  const options = [
    {
      name: 'Newest first',
      value: LeadsSortTypes.newest_first,
    },
    {
      name: 'Oldest first',
      value: LeadsSortTypes.oldest_first,
    },
    {
      name: 'A-Z',
      value: LeadsSortTypes.aToz,
    },
    {
      name: 'Z-A',
      value: LeadsSortTypes.zToa,
    },
  ];

  return (
    <Grid container gap={'12px'} mb={'12px'} alignItems="center">
      {/* search */}
      <Grid item xs={2.5}>
        <TextField
          id="search-input"
          placeholder="Search by name"
          variant="outlined"
          onChange={(e) => debouncedSetSearchValue(e.target.value)}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {/* sort */}
      <Grid item xs={2.5}>
        <Select
          id="leads-search-input-id"
          variant="outlined"
          size="small"
          notched={false}
          value={selectedSort.key}
          onChange={(e) => dispatch({ type: e.target.value as LeadsSortTypes })}
          css={css`
            background: #fff;
          `}
          displayEmpty
          input={
            <OutlinedInput
              fullWidth
              startAdornment={
                <InputAdornment position="start">
                  <SwapVertRoundedIcon />
                </InputAdornment>
              }
              label="Select"
            />
          }
        >
          <MenuItem value="" disabled>
            <Typography color={'#aeaeae'}>Sort</Typography>
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs />
      <Grid item>
        <Button
          onClick={() => setIsAddLeadModalOpen(true)}
          variant="contained"
        >
          Add Lead
        </Button>
      </Grid>
    </Grid>
  );
};

export default SearchAndFilters;
