import { css } from "@emotion/react";

export const LEADS_TABLE_COLUMN_WIDTHS = {
  NAME: {
    minWidth: '165px',
    width: '100%',
  },
  EMAIL: {
    width: '165px',
    minWidth: '165px',
    maxWidth: '165px',
  },
  PHONE_NUMBER: {
    width: '156px',
    minWidth: '156px',
    maxWidth: '156px',
  },
  GST_NUMBER: {
    width: '168px',
    minWidth: '168px',
    maxWidth: '168px',
  },
  CREATED_ON: {
    width: '126px',
    minWidth: '126px',
    maxWidth: '126px',
  },
  PLAN: {
    width: '200px',
    minWidth: '200px',
    maxWidth: '200px',
  },
  SELLING_PRICE: {
    width: '140px',
    minWidth: '140px',
    maxWidth: '140px',
  },
  REMARKS: {
    width: '300px',
    minWidth: '300px',
    maxWidth: '300px',
  },
  ACTION: {
    width: '62px',
    minWidth: '62px',
    maxWidth: '62px',
  },
} as const; 

export const leadsTableHeaderStyles = css`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.17px;
`;

export const leadsTableCellStyles = css`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
`;
