import { Country } from '../../../types';
import { ReferralPartnerStateType } from '../../onboarding/types';

export interface ReferralPartnerOnboardingPersonalDetails {
  fullName?: string;
  email?: string;
  address?: string;
  country?: Country;
  state?: string;
  city?: string;
  pincode?: string;
  pan?: File | string | null;
  countryCode?: string;
  panNumber?: string;
  taxIdentificationNumber?: string;
  taxIdentificationDocument?: File | string | null;
  addressProof?: File | string | null;
}

export interface ReferralPartnerOnboardingBusinessDetails {
  companyName?: string;
  businessPersonName?: string;
  businessContactDetails?: string;
  businessEmailId?: string;
  gstNumber?: string;
  gstCertificate?: File | string | null;
  hasMsmeRegistration?: boolean;
  msmeRegistrationCertificate?: File | string | null;
  hasGst?: boolean;
  noEstablishmentDeclaration?: File | string | null;
  form10f?: File | string | null;
}

export interface ReferralPartnerOnboardingBankDetails {
  bankName?: string;
  bankAddress?: string;
  bankAccountNumber?: string;
  ifscCode?: string;
  cancelChequeScan?: File | string | null;
  swiftCode?: string;
  accountHolderName?: string;
  additionalInformation?: string;
}

export enum ReferralPartnerType {
  INDIVIDUAL = 'INDIVIDUAL',
  BUSINESS = 'BUSINESS',
}

export interface GetChannelPartnerReferralDetailsResponse {
  fullName?: string;
  email?: string;
  address?: string;
  countryCode?: string;
  state?: string;
  city?: string;
  pinCode?: string;
  addressProofUrl?: string;
  panNumber?: string;
  panProofUrl?: string;
  taxIdNumber?: string;
  taxIdProofUrl?: string;
  contactName?: string;
  contactNumber?: string;
  contactEmail?: string;
  referralPartnerType?: ReferralPartnerType;
  companyName?: string;
  gstNumber?: string;
  gstCertificateUrl?: string;
  msmeCertificateUrl?: string;
  noPermEstProofUrl?: string;
  form10FProofUrl?: string;
  bankName?: string;
  bankAddress?: string;
  bankAccountNumber?: string;
  cancelledChequeUrl?: string;
  channelPartnerState?: ReferralPartnerStateType;
  additionalInfo?: string;
  accountHolderName?: string;
  swiftCode?: string;
  ifscCode?: string;
}
