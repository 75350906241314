/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, Button, ButtonBase, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

interface BottomActionBarProps {
  show: boolean;
  isLoadingAPI: boolean;
  handleSubmit: () => void;
  onCancel: () => void;
}

const BottomActionBar = ({
  onCancel,
  isLoadingAPI,
  handleSubmit,
  show,
}: BottomActionBarProps) => {
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitialRender(false);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  if (isInitialRender) {
    return null;
  }
  return (
    <Box
      css={css`
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        border-top: 1px solid #e0e0e9;
        padding: 16px;
        padding-right: 80px;
        background: #fbfbfc;
        display: flex;
        justify-content: flex-end;
        transform: translateY(${show ? '0' : '100%'});
        opacity: ${show ? '1' : '0'};
        transition: all 0.3s ease-in-out;
        visibility: ${show ? 'visible' : 'hidden'};
      `}
    >
      <ButtonBase
        onClick={onCancel}
        disabled={isLoadingAPI}
        css={css`
          display: flex;
          height: 40px;
          padding: 12px 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 4px;
          border: 1px solid #e0e0e9;
          background: #fbfbfc;
          margin-right: 20px;
          transition: all 0.2s ease-in-out;
          &:hover {
            background: #f5f5f5;
            border-color: #d0d0d9;
          }
          &:active {
            background: #e8e8e8;
          }
        `}
      >
        <Typography
          css={css`
            color: var(--Neutral-Primary, #2d2d2d);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          `}
        >
          Cancel
        </Typography>
      </ButtonBase>
      <Button
        variant="contained"
        onClick={handleSubmit}
        disabled={isLoadingAPI}
        css={css`
          height: 40px;
          padding: 12px 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 4px;
          background: #3f906d;
          max-height: 40px;
          width: max-content;
          transition: all 0.2s ease-in-out;
          &:hover {
            background: #358960;
          }
          &:active {
            background: #2d7452;
          }
        `}
      >
        <Typography
          css={css`
            color: var(--primary-contrast, #fff);
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 160%;
          `}
        >
          Save Changes
        </Typography>
      </Button>
    </Box>
  );
};

export default BottomActionBar;
