import { atom } from 'jotai';
import { PlanUnit, SelectedReferralLeadsPlan } from '../typings/plan';
import { atomWithReset } from 'jotai/utils';
import {
  GetLeadsPaginationOptions,
  GetReferralPartnerLeadsProps,
  LeadsSortActionsType,
  LeadsSortTermAtomType,
  LeadsSortTypes,
  ReferralPartnerLeadFormData,
  ReferralPartnerLeadFormErrorData,
  SortDirection,
  SortFields,
} from '../typings';
import {
  getCountryCodeFromLocalStorage,
  getCountryFromCountryCode,
  getDefaultCountry,
} from '../../../utils/countries-mapper';
import { createPaginatedRequestAtom } from '../../../utils/atoms/request-atom';

export const selectedReferralLeadsPlanAtom = atom<SelectedReferralLeadsPlan>({
  id: null,
  numUsers: 0,
  label: '',
  unit: PlanUnit.MONTH,
  period: 1,
  planId: null,
  currencyCode: null,
});

export const newReferralPartnerLeadAtom =
  atomWithReset<ReferralPartnerLeadFormData>({
    orgName: '',
    fullName: '',
    email: '',
    phoneNumber: '',
    gstNumber: '',
    saleAmount: '',
    country:
      getCountryFromCountryCode(getCountryCodeFromLocalStorage()) ??
      getDefaultCountry(),
    remarks: '',
  });

export const newReferralPartnerLeadFormErrorFieldsInitialState = {
  fullName: null,
  email: null,
  phoneNumber: null,
  gstNumber: null,
  saleAmount: null,
  country: null,
  remarks: null,
};

export const newReferralPartnerLeadFormErrorsAtom =
  atomWithReset<ReferralPartnerLeadFormErrorData>(newReferralPartnerLeadFormErrorFieldsInitialState);

export const plansDataLoadingAtom = atom<boolean>(false);

export const totalReferralLeadsAtom = atom<number>(0);

export const leadsSearchTermAtom = atom<string>('');

export const leadsSortTermAtom = atomWithReset<LeadsSortTermAtomType>({
  key: LeadsSortTypes.newest_first,
  sortFilter: SortFields.dateCreated,
  direction: SortDirection.DESC,
});

export const leadsListAtom = createPaginatedRequestAtom<GetReferralPartnerLeadsProps, GetLeadsPaginationOptions>();

export const LeadsSortAtomReducer = (
  prev: LeadsSortTermAtomType,
  action: LeadsSortActionsType
): LeadsSortTermAtomType => {
  if (action.type === LeadsSortTypes.newest_first) {
    return {
      ...prev,
      sortFilter: SortFields.dateCreated,
      direction: SortDirection.DESC,
      key: action.type,
    };
  }
  if (action.type === LeadsSortTypes.oldest_first) {
    return {
      ...prev,
      sortFilter: SortFields.dateCreated,
      direction: SortDirection.ASC,
      key: action.type,
    };
  }
  if (action.type === LeadsSortTypes.aToz) {
    return {
      ...prev,
      sortFilter: SortFields.name,
      direction: SortDirection.ASC,
      key: action.type,
    };
  }
  if (action.type === LeadsSortTypes.zToa) {
    return {
      ...prev,
      sortFilter: SortFields.name,
      direction: SortDirection.DESC,
      key: action.type,
    };
  }
  if (action.type === LeadsSortTypes.reset) {
    return {
      sortFilter: SortFields.dateCreated,
      direction: SortDirection.DESC,
      key: LeadsSortTypes.newest_first,
    };
  }

  return prev;
};

export const isAddLeadModalOpenAtom = atom<boolean>(false);
