/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Box,
  Button,
  ButtonBase,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import WithLabel from '../../../../../components/input/WithLabel';
import { useAtom, useAtomValue } from 'jotai';
import {
  editReferralPartnerBankDetailsAtom,
  referralPartnerProfileDetailsAtom,
} from '../../states';
import { useMemo, useState } from 'react';
import useNotifications from '../../../../../hooks/useNotifications';
import { postReferralBankDetails } from '../../../../referral-partner-onboarding/requests';
import { handleApiError } from '../../../../../utils/error-handler';
import { getBankDetailsApiFormDataKeysToStateKeysMapping } from '../../../../referral-partner-onboarding/constants';
import { FileUploadField } from '../../../../../components/input/FileUploadField';
import areBankDetailsChanged from '../../utils/are-bank-details-changes';
import BottomActionBar from '../../../whitelabel/components/bottom-action-bar';

const EditReferralPartnerBankDetails = () => {
  const [editReferralPartnerBankDetails, setEditReferralPartnerBankDetails] =
    useAtom(editReferralPartnerBankDetailsAtom);
  const [
    { data: referralPartnerProfileDetails },
    setReferralPartnerProfileDetailsData,
  ] = useAtom(referralPartnerProfileDetailsAtom);
  const [isApiError, setIsApiError] = useState(false);
  const [isLoadingAPI, setIsLoadingAPI] = useState(false);
  const { addNotification } = useNotifications();

  const isInternational = referralPartnerProfileDetails?.countryCode !== 'IN';

  type ErrorFields = Record<string, string | null>;

  const errorFieldsInitialState: ErrorFields = {
    bankName: null,
    bankAddress: null,
    bankAccountNumber: null,
    ifscCode: null,
    swiftCode: null,
    accountHolderName: null,
    additionalInformation: null,
    cancelChequeScan: null,
  };

  const [errorFields, setErrorFields] = useState<ErrorFields>(
    errorFieldsInitialState
  );

  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorFields(prev => ({
      ...prev,
      [e.target.name]: null
    }));

    setEditReferralPartnerBankDetails({
      ...editReferralPartnerBankDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      setIsApiError(false);
      setIsLoadingAPI(true);
      const response = await postReferralBankDetails(
        editReferralPartnerBankDetails,
        isInternational
      );
      setIsLoadingAPI(false);
      if (response.success) {
        addNotification({
          message: 'Bank details updated successfully',
          type: 'success',
        });
        setReferralPartnerProfileDetailsData((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            ...response,
          },
        }));
        return;
      }
      setIsApiError(true);
      addNotification({
        message: 'An error occurred. Please try again',
        type: 'error',
      });
    } catch (e) {
      setIsLoadingAPI(false);
      setIsApiError(true);
      const bankDetailsMapping =
        getBankDetailsApiFormDataKeysToStateKeysMapping(isInternational);
      handleApiError({
        error: e,
        mappingObject: bankDetailsMapping as unknown as Record<string, string>,
        errorFieldsInitialState,
        setErrorFields,
        addNotification,
        isInternational,
      });
    }
  };

  const handleFileUpload = (file: File | null, type: string) => {
    setErrorFields(prev => ({
      ...prev,
      [type]: null
    }));

    setEditReferralPartnerBankDetails({
      ...editReferralPartnerBankDetails,
      [type]: file,
    });
  };

  const areFieldsChanged = useMemo(() => {
    if (referralPartnerProfileDetails) {
      return areBankDetailsChanged(
        referralPartnerProfileDetails,
        editReferralPartnerBankDetails
      );
    }
    return false;
  }, [referralPartnerProfileDetails, editReferralPartnerBankDetails]);

  const resetFields = () => {
    setEditReferralPartnerBankDetails({
      bankName: referralPartnerProfileDetails?.bankName,
      bankAddress: referralPartnerProfileDetails?.bankAddress,
      bankAccountNumber: referralPartnerProfileDetails?.bankAccountNumber,
      ifscCode: referralPartnerProfileDetails?.ifscCode,
      swiftCode: referralPartnerProfileDetails?.swiftCode,
      accountHolderName: referralPartnerProfileDetails?.accountHolderName,
      additionalInformation: referralPartnerProfileDetails?.additionalInfo,
      cancelChequeScan: referralPartnerProfileDetails?.cancelledChequeUrl,
    });
    setErrorFields(errorFieldsInitialState);
  };
  return (
    <Box
      css={css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        padding-bottom: 24px;
      `}
    >
      <Box
        css={css`
          display: flex;
          width: 100%;
          padding: 18px 20px;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          border-radius: 8px;
          border: 1px solid var(--Neutral-Stroke, #e0e0e9);
          background: #fff;
        `}
      >
        <Typography
          css={css`
            color: #2d2d2d;
            font-size: 16px;
            font-weight: 600;
          `}
        >
          Bank Account Details
        </Typography>
        <FormGroup
          css={css`
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;
          `}
        >
          <WithLabel label="Bank Name">
            <TextField
              name="bankName"
              error={!!errorFields.bankName}
              helperText={errorFields.bankName || ''}
              value={editReferralPartnerBankDetails.bankName}
              placeholder="Enter bank name"
              onChange={handleTextInputChange}
            />
          </WithLabel>
          <WithLabel label="Bank Address">
            <TextField
              name="bankAddress"
              error={!!errorFields.bankAddress}
              helperText={errorFields.bankAddress || ''}
              value={editReferralPartnerBankDetails.bankAddress}
              placeholder="Enter bank address"
              onChange={handleTextInputChange}
            />
          </WithLabel>
          {isInternational ? (
            <WithLabel label="Account Holder Name">
              <TextField
                name="accountHolderName"
                error={!!errorFields.accountHolderName}
                helperText={errorFields.accountHolderName || ''}
                value={editReferralPartnerBankDetails.accountHolderName}
                placeholder="Enter account holder name"
                onChange={handleTextInputChange}
              />
            </WithLabel>
          ) : null}
          <WithLabel label="Bank Account Number">
            <TextField
              type="number"
              name="bankAccountNumber"
              error={!!errorFields.bankAccountNumber}
              helperText={errorFields.bankAccountNumber || ''}
              value={editReferralPartnerBankDetails.bankAccountNumber}
              placeholder="Enter account number"
              onChange={handleTextInputChange}
            />
          </WithLabel>
          {!isInternational ? (
            <WithLabel label="IFSC Code">
              <TextField
                name="ifscCode"
                error={!!errorFields.ifscCode}
                helperText={errorFields.ifscCode || ''}
                value={editReferralPartnerBankDetails.ifscCode}
                placeholder="Enter IFSC Code"
                onChange={handleTextInputChange}
              />
            </WithLabel>
          ) : (
            <>
              <WithLabel label="SWIFT Code">
                <TextField
                  name="swiftCode"
                  error={!!errorFields.swiftCode}
                  helperText={errorFields.swiftCode || ''}
                  value={editReferralPartnerBankDetails.swiftCode}
                  placeholder="Enter SWIFT Code"
                  onChange={handleTextInputChange}
                />
              </WithLabel>
                <WithLabel label="Additional Information">
                  <Typography
                    css={css`
                      color: var(--Neutral-Secondary, #7a7a7a);
                      font-family: Inter;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                    `}
                  >
                    Provide any specific instructions or additional codes
                    required for processing international remittances.
                  </Typography>
                  <TextField
                    name="additionalInformation"
                    value={
                      editReferralPartnerBankDetails.additionalInformation ?? ''
                    }
                    placeholder="Write additional information here"
                    onChange={handleTextInputChange}
                    multiline
                    rows={4}
                    error={!!errorFields.additionalInformation}
                    helperText={errorFields.additionalInformation || ''}
                  />
                </WithLabel>
            </>
          )}
          {!isInternational ? (
            <FileUploadField
              label="Cancel Cheque Scan"
              helperText="Upload 1 supported file. Max 10 MB."
              onChange={(file) => handleFileUpload(file, 'cancelChequeScan')}
              accept="image/*,.pdf"
              errorMsg={errorFields.cancelChequeScan || ''}
              file={editReferralPartnerBankDetails.cancelChequeScan}
            />
          ) : null}
        </FormGroup>
      </Box>
      <BottomActionBar
        isLoadingAPI={isLoadingAPI}
        handleSubmit={handleSubmit}
        onCancel={resetFields}
        show={areFieldsChanged}
      />
    </Box>
  );
};

export default EditReferralPartnerBankDetails;