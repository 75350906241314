import { lazy, Suspense } from 'react';
import { Navigate, Route } from 'react-router-dom';
import WithSideBar from '../../../HOC/WithSideBar';
import Organisations from '../../../modules/organisations';
import ReferralLeads from '../../../modules/referral-leads/components';
// import InvoiceWorkingPage from '../../../modules/payout-commissions/components/invoive-working-page';
import WalletPage from '../../../modules/wallet';
import OrgProfile from '../../../modules/orgProfile';
import { ROUTES } from '../routes';
import AddOrganisation from '../../../modules/addOrganisation';
import LoginComponent from '../../../modules/login';
import { ReferralPartnerStateType } from '../../../modules/onboarding/types';
import EditReferralPartnerProfile from '../../../modules/edit-profile/referral/components';
import EditWhitelabelPartnerProfile from '../../../modules/edit-profile/whitelabel/components';
// import PayoutCommissionsScreen from '../../../modules/payout-commissions';
// import Loader from '../../../../../shared/components/molecules/loader';

const HomeScreenLazyComponentV1 = lazy(() => import('../../../modules/home'));

const OnboardPartnerLazyComponentV1 = lazy(
  () => import('../../../modules/onboarding')
);

const ReferralPartnerOnboardingLazyComponent = lazy(
  () => import('../../../modules/referral-partner-onboarding')
);

// const OrganisationsPageLazyComponentV1 = lazy(
//   () => import('../../../modules/organisations')
// );

export default function getRoutesV1() {
  const loader = <>Loading</>;
  return [
    <Route
      key={ROUTES.onboardPage}
      path={ROUTES.onboardPage}
      element={
        <Suspense fallback={loader}>
          <OnboardPartnerLazyComponentV1 />
        </Suspense>
      }
    />,
    <Route
      key={`${ROUTES.REFERRAL_PARTNER_ONBOARDING}/*`}
      path={`${ROUTES.REFERRAL_PARTNER_ONBOARDING}/*`}
      element={
        <Suspense fallback={loader}>
          <ReferralPartnerOnboardingLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key={`${ROUTES.EDIT_REFERRAL_PARTNER_PROFILE}/*`}
      path={`${ROUTES.EDIT_REFERRAL_PARTNER_PROFILE}/*`}
      element={
        <Suspense fallback={loader}>
          <WithSideBar>
            <EditReferralPartnerProfile />
          </WithSideBar>
        </Suspense>
      }
    />,
    <Route
      key={`${ROUTES.EDIT_WHITELABEL_PARTNER_PROFILE}/*`}
      path={`${ROUTES.EDIT_WHITELABEL_PARTNER_PROFILE}/*`}
      element={
        <Suspense fallback={loader}>
          <WithSideBar>
            <EditWhitelabelPartnerProfile />
          </WithSideBar>
        </Suspense>
      }
    />,
    <Route
      key={ROUTES.homePage}
      path={ROUTES.homePage}
      element={
        <Suspense fallback={loader}>
          <WithSideBar>
            <HomeScreenLazyComponentV1 />
          </WithSideBar>
        </Suspense>
      }
    />,
    <Route
      key={ROUTES.organisationPage}
      path={ROUTES.organisationPage}
      element={
        <Suspense fallback={loader}>
          <WithSideBar>
            <Organisations />
          </WithSideBar>
        </Suspense>
      }
    />,
    <Route
      key={ROUTES.LEADS_PAGE}
      path={ROUTES.LEADS_PAGE}
      element={
        <Suspense fallback={loader}>
          <WithSideBar>
            <ReferralLeads />
          </WithSideBar>
        </Suspense>
      }
    />,
    // <Route
    //   key={ROUTES.PAYOUT_COMMISSIONS_PAGE}
    //   path={ROUTES.PAYOUT_COMMISSIONS_PAGE}
    //   element={
    //     <Suspense fallback={loader}>
    //       <WithSideBar>
    //         <PayoutCommissionsScreen />
    //       </WithSideBar>
    //     </Suspense>
    //   }
    // />,
    // <Route
    //   key={ROUTES.INVOICE_WORKING_PAGE}
    //   path={ROUTES.INVOICE_WORKING_PAGE}
    //   element={
    //     <Suspense fallback={loader}>
    //       <WithSideBar>
    //         <InvoiceWorkingPage />
    //       </WithSideBar>
    //     </Suspense>
    //   }
    // />,
    <Route
      key={ROUTES.addOrganisation}
      path={ROUTES.addOrganisation}
      element={
        <Suspense fallback={loader}>
          <AddOrganisation />
        </Suspense>
      }
    />,
    <Route
      key={ROUTES.orgProfilePage}
      path={ROUTES.orgProfilePage}
      element={
        <Suspense fallback={loader}>
          <WithSideBar>
            <OrgProfile />
          </WithSideBar>
        </Suspense>
      }
    />,
    <Route
      key={ROUTES.walletPage}
      path={ROUTES.walletPage}
      element={
        <Suspense fallback={loader}>
          <WithSideBar>
            <WalletPage />
          </WithSideBar>
        </Suspense>
      }
    />,
    <Route
      key={'auth_default'}
      path="*"
      element={<Navigate to={ROUTES.homePage} replace />}
    />,
  ];
}

export function getUnprotectedRoutesV1() {
  const loader = <>Loading</>;
  return [
    <Route
      key={ROUTES.loginPage}
      path={ROUTES.loginPage}
      element={
        <Suspense fallback={loader}>
          <LoginComponent />
        </Suspense>
      }
    />,
    <Route
      path="*"
      key={'unprotected_default'}
      element={<Navigate to={ROUTES.loginPage} replace />}
    />,
  ];
}
