/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import {
  Box,
  Card,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { css } from '@emotion/react';
import {
  DTPlanId,
  PlanPrice,
  PlanUnit,
  ProductPlans,
} from '../../typings/plan';
import { formatCurrency } from '../../../../utils/currencyFormatter';
import { useAtom } from 'jotai';
import { selectedReferralLeadsPlanAtom } from '../../state';

const getUnitText = (unit: PlanUnit, period: number) => {
  if (unit === PlanUnit.MONTH) {
    if (period === 1) {
      return 'month';
    }
    return `quarter`;
  }
  if (unit === PlanUnit.YEAR) {
    return 'year';
  }
  return 'year';
};

interface PlanDetailsInputProps {
  planDetails: (PlanPrice & ProductPlans & { planId: DTPlanId })[];
}

const PlanDetailsInput: React.FC<PlanDetailsInputProps> = ({ planDetails }) => {
  const [selectedPlan, setSelectedPlan] = useAtom(
    selectedReferralLeadsPlanAtom
  );

  const options = planDetails
    .map((option) => {
      let amount = option.amount;
      if (!isNaN(option.discountAmount)) {
        amount -= option.discountAmount;
      }
      return {
        value: option.id,
        label: option.displayName,
        numUsers: option.numUsers,
        feeText:
          amount === 0
            ? 'Contact us'
            : `${formatCurrency(amount, option.currencyCode)} per ${getUnitText(option.unit, option.period!)}`,
        unit: option.unit,
        period: option.period,
        planId: option.planId,
        currencyCode: option.currencyCode,
      };
    })
    .filter((option) => option !== null);

  const handlePlanSelect = useCallback(
    (
      id: string,
      numUsers: number,
      label: string,
      unit: PlanUnit,
      period: number,
      planId: DTPlanId,
      currencyCode: string
    ) => {
      setSelectedPlan({
        ...selectedPlan,
        id,
        numUsers,
        label,
        unit,
        period,
        planId,
        currencyCode,
      });
    },
    [setSelectedPlan, selectedPlan]
  );

  useEffect(() => {
    if (options && options.length > 0) {
      setSelectedPlan({
        ...selectedPlan,
        id: options[0]!.value,
        numUsers: options[0]!.numUsers,
        label: options[0]!.label,
        unit: options[0]!.unit,
        period: options[0]!.period!,
        planId: options[0]!.planId!,
        currencyCode: options[0]!.currencyCode!,
      });
    }
  }, [planDetails]); // Trigger this when `options` change

  if (options.length === 0) {
    return <Typography>No options available</Typography>;
  }

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <Stack direction={'column'}>
        <RadioGroup
          aria-label="options"
          name="options1"
          css={css`
            display: flex;
            flex-direction: row;
            gap: 12px;
          `}
          value={selectedPlan.id}
        >
          {options.map((option) => {
            if (!option) {
              return null;
            }
            return (
              <Card
                key={option.value}
                css={css(
                  `
                  min-width: 220px;
                  padding: 4px 10px;
                  cursor: pointer;
                `,
                  selectedPlan.id === option.value &&
                    `
                    border: 1px solid #9AE9C4;
                background-color: #EBFBF3;
                `
                )}
                onClick={() =>
                  handlePlanSelect(
                    option.value,
                    option.numUsers,
                    option.label,
                    option.unit,
                    option.period!,
                    option.planId!,
                    option.currencyCode!
                  )
                }
              >
                <FormControlLabel
                  value={option.value}
                  sx={{ height: '30px' }}
                  control={
                    <Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />
                  } // Adjust the size here
                  label={
                    <span
                      css={css`
                        color: #2d2d2d;
                        font-size: 14px;
                        font-weight: 600;
                      `}
                    >
                      {option.label}
                    </span>
                  }
                />

                <Typography
                  ml={'25px'}
                  css={css`
                    color: #2d2d2d;
                    font-size: 14px;
                    font-weight: 400;
                  `}
                >
                  {option.feeText}
                </Typography>
              </Card>
            );
          })}
        </RadioGroup>
      </Stack>
    </div>
  );
};

export default PlanDetailsInput;
