/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, BoxProps, Typography } from '@mui/material';
import React from 'react';

interface WithLabelProps {
  children: React.ReactNode;
  label: string;
  mt?: string;
  subtitle?: string;
  subtitleAlignment?: 'horizontal' | 'vertical';
  containerStyle?: React.CSSProperties;
}

const WithLabel: React.FC<WithLabelProps> = ({
  children,
  label,
  mt,
  subtitle,
  subtitleAlignment = 'vertical',
  containerStyle,
}) => {
  return (
    <Box
      display={'flex'}
      textAlign={'left'}
      flexDirection={'column'}
      gap={'6px'}
      mt={mt}
      style={containerStyle}
    >
      <Box
        display={'flex'}
        flexDirection={subtitleAlignment === 'horizontal' ? 'row' : 'column'}
        gap={'6px'}
        textAlign={'left'}
      >
        <label
          css={css`
            color: #585858;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          `}
        >
          {label}
        </label>
        {subtitle ? (
          <Typography
            css={css`
              color: var(--Neutral-Secondary-Text, #585858);
              font-family: Inter;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding: 0px;
            `}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          ></Typography>
        ) : null}
      </Box>
      {children}
    </Box>
  );
};

export default WithLabel;
