/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, Card, Grid, Radio, Tab, Tabs, Typography, Alert, Button } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  DTPlanId,
  GetProductPlans,
  PlanPrice,
  PlanUnit,
  ProductPlans,
} from '../../typings/plan';
import usePlanData from '../../../../hooks/usePlanData';
import Loader from '../../../../components/loader';
import PlanDetailsInput from '../plan-details-input';
import { plansDataLoadingAtom } from '../../state';
import { useSetAtom } from 'jotai';
import RefreshIcon from '@mui/icons-material/Refresh';

type OrganizedPlanType = ProductPlans & PlanPrice & {
  planId: DTPlanId;
}; 

interface OrganizedPricePlanType {
  monthly: OrganizedPlanType[];
  quarterly: OrganizedPlanType[];
  yearly: OrganizedPlanType[];
}

function transformPlans(
  plansData: GetProductPlans | null
): OrganizedPricePlanType {
  const result: OrganizedPricePlanType = {
    monthly: [],
    quarterly: [],
    yearly: [],
  };

  if (plansData && 'plans' in plansData) {
    plansData?.plans.forEach((plan) => {
      plan.price.forEach((price) => {
        const transformedPlan: OrganizedPlanType = {
          ...plan,
          planId: plan.id as DTPlanId,
          ...price,
        };

        if (price.unit === PlanUnit.MONTH && price.period === 1) {
          result.monthly.push(transformedPlan);
        } else if (price.unit === PlanUnit.MONTH && price.period === 3) {
          result.quarterly.push(transformedPlan);
        } else if (price.unit === PlanUnit.YEAR && price.period === 1) {
          result.yearly.push(transformedPlan);
        }
      });
    });
  }

  return result;
}

interface PlanCardProps {
  title: string;
  price: string;
  selected: boolean;
  onClick: () => void;
}

const PlanCard: React.FC<PlanCardProps> = ({
  title,
  price,
  selected,
  onClick,
}) => (
  <Card
    onClick={onClick}
    css={css`
      padding: 16px;
      cursor: pointer;
      border: 1px solid ${selected ? '#3F906D' : '#e0e0e9'};
      background: ${selected ? 'rgba(63, 144, 109, 0.04)' : '#fff'};
      display: flex;
      justify-content: space-between;
      align-items: center;
    `}
  >
    <Box>
      <Typography
        css={css`
          color: #2d2d2d;
          font-size: 16px;
          font-weight: 500;
        `}
      >
        {title}
      </Typography>
      <Typography
        css={css`
          color: #2d2d2d;
          font-size: 14px;
          font-weight: 400;
        `}
      >
        {price}
      </Typography>
    </Box>
    <Radio checked={selected} />
  </Card>
);

function CustomTabPanel(props: {
  children?: React.ReactNode;
  value: number;
  index: number;
}) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`plan-tabpanel-${index}`}
      aria-labelledby={`plan-tab-${index}`}
      css={css`
        margin-top: 16px;
      `}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const LeadPlanTabs: FC<{ countryCode?: string }> = ({ countryCode }) => {
  const [tabValue, setTabValue] = useState(0);
  const setPlansDataLoading = useSetAtom(plansDataLoadingAtom);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const { loading, error, data: plansData, reload } = usePlanData(countryCode ?? 'IN');

  useEffect(() => {
    setPlansDataLoading(loading);
  }, [loading]);

  const handleRetry = () => {
    reload();
  };

  const organizedPlans: OrganizedPricePlanType = useMemo(() => {
    if (plansData) {
      return transformPlans(plansData);
    }
    return {
      monthly: [],
      quarterly: [],
      yearly: [],
    };
  }, [plansData]);

  const tabs = [
    { label: 'MONTHLY', plans: organizedPlans.monthly },
    { label: 'QUARTERLY', plans: organizedPlans.quarterly },
    { label: 'YEARLY', plans: organizedPlans.yearly },
  ];

  if (loading) {
    return (
      <Box mt={'16px'} width={'100%'}>
        <Loader size={24} secondary={'Loading plans...'} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box mt={'16px'} width={'100%'}>
        <Alert 
          severity="error"
          css={css`
            background-color: rgba(211, 47, 47, 0.05);
            color: #d32f2f;
            .MuiAlert-icon {
              color: #d32f2f;
            }
          `}
          action={
            <Button
              size="small"
              onClick={handleRetry}
              startIcon={<RefreshIcon css={css`color: #d32f2f;`} />}
              css={css`
                text-transform: none;
                min-width: 32px;
                padding: 4px 8px;
                color: #d32f2f;
              `}
            >
              Retry
            </Button>
          }
        >
          Failed to load plans. Please try again.
        </Alert>
      </Box>
    );
  }

  return (
    <Box>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        css={css`
          border-bottom: 1px solid #e0e0e9;
          min-height: 36px;
          .MuiTabs-indicator {
            background-color: #3f906d;
          }
        `}
      >
        {tabs
          .filter((tab) => tab.plans.length > 0)
          .map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              {...a11yProps(index)}
              sx={{ '&.Mui-selected': { color: '#3F906D' } }}
            />
          ))}
      </Tabs>
      {tabs
        .filter((tab) => tab.plans.length > 0)
        .map((tab, index) => (
          <CustomTabPanel key={index} value={tabValue} index={index}>
            <PlanDetailsInput key={index} planDetails={tab.plans} />
          </CustomTabPanel>
        ))}
    </Box>
  );
};

export default LeadPlanTabs;
