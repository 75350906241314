/** @jsxImportSource @emotion/react */
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  css,
  Box,
  Typography,
  TableFooter,
  IconButton,
  Chip,
  Tooltip,
} from '@mui/material';
import React, { ForwardedRef, forwardRef, useCallback, useState } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { MoreVert } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Loader from '../../../../components/loader';
import useNotifications from '../../../../hooks/useNotifications';
import { formatShortDate } from '../../../../utils/date';
import usePaginatedQuery, {
  UsePaginatedQueryResult,
} from '../../../../hooks/usePaginatedQuery';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  leadsListAtom,
  leadsSearchTermAtom,
  leadsSortTermAtom,
  totalReferralLeadsAtom,
} from '../../state';
import {
  GetLeadsPaginationOptions,
  GetReferralPartnerLeadsProps,
  LeadsTableColumns,
} from '../../typings';
import getLeadsAPI from '../../request/get-leads';
import ActionButton from '../../../../components/action-button';
import { ReactComponent as DeleteIcon } from '../../../../../icons/svgs/delete.svg';
import DeleteLeadConfirmationModal from '../delete-lead-confirmation-modal';
import { deleteReferralLeadApi } from '../../request';
import { getChannelDetails } from '../../../../utils/getSetChannelDetails';
import { formatPlanDisplayText } from '../../utils';
import { formatCurrency } from '../../../../utils/currencyFormatter';
import {
  LEADS_TABLE_COLUMN_WIDTHS,
  leadsTableCellStyles,
  leadsTableHeaderStyles,
} from '../../constants/table-columns';
import QSTooltip from '../../../../components/tooltip';
import { getCountryFromCountryCode } from '../../../../utils/countries-mapper';
import TypographyWithEllipsisTooltip from '../../../../components/typography-with-ellipsis';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

const TableComponents = {
  Scroller: forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => (
    <TableContainer {...props} ref={ref} />
  )),
  Table: (props: any) => (
    <Table {...props} style={{ borderCollapse: 'separate' }} />
  ),
  TableHead: TableHead as any,
  TableRow: TableRow as any,
  TableBody: forwardRef((props, ref: ForwardedRef<HTMLTableSectionElement>) => (
    <TableBody {...props} ref={ref} />
  )),
  TableFoot: forwardRef((props, ref: ForwardedRef<HTMLTableSectionElement>) => (
    <TableFooter
      {...props}
      ref={ref}
      style={{
        position: 'unset',
      }}
    />
  )),
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  })
    .format(date)
    .replace(/\//g, '/');
};

const LeadsListTable = () => {
  const leadsSearchValue = useAtomValue(leadsSearchTermAtom);
  const leadsSortValue = useAtomValue(leadsSortTermAtom);
  const setTotalLeads = useSetAtom(totalReferralLeadsAtom);
  const [selectedLead, setSelectedLead] =
    useState<GetReferralPartnerLeadsProps | null>(null);
  const { addNotification } = useNotifications();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);

  const memoizedComponents = React.useMemo(
    () => ({
      Scroller: forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => (
        <TableContainer {...props} ref={ref} component={Paper} />
      )),
      Table: (props: any) => (
        <Table {...props} style={{ borderCollapse: 'separate' }} />
      ),
      TableHead: TableHead as any,
      TableRow: TableRow as any,
      TableBody: forwardRef(
        (props, ref: ForwardedRef<HTMLTableSectionElement>) => (
          <TableBody {...props} ref={ref} />
        )
      ),
      TableFoot: forwardRef(
        (props, ref: ForwardedRef<HTMLTableSectionElement>) => (
          <TableFooter
            {...props}
            ref={ref}
            style={{
              position: 'unset',
            }}
          />
        )
      ),
      EmptyPlaceholder: () => <>...</>,
    }),
    []
  );

  const virtuosoStyle = React.useMemo(
    () => ({
      height: '68vh',
      width: '100%',
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
      backgroundColor: '#fff',
    }),
    []
  );

  const virtuosoCss = React.useMemo(
    () => css`
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;

      table {
        border-spacing: 0;
        width: 100%;
      }
    `,
    []
  );

  const handleDeleteConfirmationModalCancelClick = () => {
    setShowDeleteConfirmationModal(false);
  };

  const handleDeleteConfirmationModalOkClick = async () => {
    const channelDetails = getChannelDetails();
    if (!selectedLead || !channelDetails?.id) {
      return;
    }
    try {
      await deleteReferralLeadApi({
        leadId: selectedLead.id,
        channelPartnerId: channelDetails.id,
      });

      leads?.splice(
        leads.findIndex((lead) => lead.id === selectedLead.id),
        1
      );
      setTotalLeads((prev) => prev - 1);

      addNotification({
        message: 'Lead deleted successfully',
        type: 'success',
      });
    } catch (e) {
      console.log(e);
      addNotification({
        message: (e as Error).message || 'Something went wrong',
        type: 'error',
      });
    } finally {
      setShowDeleteConfirmationModal(false);
    }
  };

  const getter = useCallback(
    async (paginationData?: GetLeadsPaginationOptions) => {
      const response = await getLeadsAPI(
        {
          search: leadsSearchValue,
          sort: {
            sortBy: leadsSortValue.sortFilter,
            direction: leadsSortValue.direction,
          },
        },
        paginationData
      );
      setTotalLeads(response.paginationOptions.totalCount ?? 0);
      return {
        data: response.customers,
        paginationData: {
          hasMore: response.paginationOptions.hasMore,
          nextClientId: response.paginationOptions.nextClientId,
          nextClientValue: response.paginationOptions.nextClientValue,
          totalCount: response.paginationOptions.totalCount,
        },
      } as UsePaginatedQueryResult<
        GetReferralPartnerLeadsProps,
        GetLeadsPaginationOptions
      >;
    },
    [
      leadsSearchValue,
      leadsSortValue.sortFilter,
      leadsSortValue.direction,
      setTotalLeads,
    ]
  );

  const {
    requestData: { loading, error, data: leads, isFetchingMore },
    fetchMore: fetchMoreLeads,
  } = usePaginatedQuery<
    GetReferralPartnerLeadsProps,
    GetLeadsPaginationOptions
  >({
    requestAtom: leadsListAtom,
    getter: getter,
    cacheData: false,
  });

  const memoizedHeader = React.useMemo(
    () => (
      <TableHead>
        <TableRow
          css={css`
            background: var(--grey-50, #fafafa);
            th {
              font-weight: 500;
              color: #2d2d2d;
              padding: 16px;
              box-sizing: border-box;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          `}
        >
          <TableCell
            css={css`
              width: ${LEADS_TABLE_COLUMN_WIDTHS.NAME.width};
              min-width: ${LEADS_TABLE_COLUMN_WIDTHS.NAME.minWidth};
              box-sizing: border-box;
              padding: 16px;
            `}
          >
            Name
          </TableCell>
          {/* <TableCell
            css={css`
              width: ${LEADS_TABLE_COLUMN_WIDTHS.EMAIL.width};
              min-width: ${LEADS_TABLE_COLUMN_WIDTHS.EMAIL.minWidth};
              max-width: ${LEADS_TABLE_COLUMN_WIDTHS.EMAIL.maxWidth};
              box-sizing: border-box;
              padding: 16px;
            `}
          >
            Email
          </TableCell> */}
          {/* <TableCell
            css={css`
              width: ${LEADS_TABLE_COLUMN_WIDTHS.PHONE_NUMBER.width};
              min-width: ${LEADS_TABLE_COLUMN_WIDTHS.PHONE_NUMBER.minWidth};
              max-width: ${LEADS_TABLE_COLUMN_WIDTHS.PHONE_NUMBER.maxWidth};
              box-sizing: border-box;
              padding: 16px;
            `}
          >
            Phone Number
          </TableCell> */}
          <TableCell
            css={css`
              width: ${LEADS_TABLE_COLUMN_WIDTHS.GST_NUMBER.width};
              min-width: ${LEADS_TABLE_COLUMN_WIDTHS.GST_NUMBER.minWidth};
              max-width: ${LEADS_TABLE_COLUMN_WIDTHS.GST_NUMBER.maxWidth};
              box-sizing: border-box;
              padding: 16px;
            `}
          >
            GST Number
          </TableCell>
          <TableCell
            css={css`
              width: ${LEADS_TABLE_COLUMN_WIDTHS.CREATED_ON.width};
              min-width: ${LEADS_TABLE_COLUMN_WIDTHS.CREATED_ON.minWidth};
              max-width: ${LEADS_TABLE_COLUMN_WIDTHS.CREATED_ON.maxWidth};
              box-sizing: border-box;
              padding: 16px;
            `}
          >
            Created On
          </TableCell>
          <TableCell
            css={css`
              width: ${LEADS_TABLE_COLUMN_WIDTHS.PLAN.width};
              min-width: ${LEADS_TABLE_COLUMN_WIDTHS.PLAN.minWidth};
              max-width: ${LEADS_TABLE_COLUMN_WIDTHS.PLAN.maxWidth};
              box-sizing: border-box;
              padding: 16px;
            `}
          >
            Plan
          </TableCell>
          <TableCell
            css={css`
              width: ${LEADS_TABLE_COLUMN_WIDTHS.SELLING_PRICE.width};
              min-width: ${LEADS_TABLE_COLUMN_WIDTHS.SELLING_PRICE.minWidth};
              max-width: ${LEADS_TABLE_COLUMN_WIDTHS.SELLING_PRICE.maxWidth};
              box-sizing: border-box;
              padding: 16px;
            `}
            align="right"
          >
            Selling Price
          </TableCell>
          <TableCell
            css={css`
              width: ${LEADS_TABLE_COLUMN_WIDTHS.REMARKS.width};
              min-width: ${LEADS_TABLE_COLUMN_WIDTHS.REMARKS.minWidth};
              max-width: ${LEADS_TABLE_COLUMN_WIDTHS.REMARKS.maxWidth};
            `}
          >
            Remarks
          </TableCell>
          <TableCell
            css={css`
              width: ${LEADS_TABLE_COLUMN_WIDTHS.ACTION.width};
              min-width: ${LEADS_TABLE_COLUMN_WIDTHS.ACTION.minWidth};
              max-width: ${LEADS_TABLE_COLUMN_WIDTHS.ACTION.maxWidth};
              box-sizing: border-box;
              padding: 16px;
            `}
          >
            {''}
          </TableCell>
        </TableRow>
      </TableHead>
    ),
    []
  );

  const renderHeader = useCallback(() => memoizedHeader, [memoizedHeader]);

  const renderTableRows = () => {
    if (loading) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={8}>
              <Box
                css={css`
                  height: 300px;
                  width: 100%;
                `}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={'8px'}
              >
                <Loader />
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    let emptyTableInfo = !leadsSearchValue
      ? 'No leads added yet'
      : 'No leads found';
    if (error) {
      emptyTableInfo = 'An error occurred. Please try again';
    }

    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={8}>
            <Box
              css={css`
                height: 300px;
                margin: auto;
              `}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={'8px'}
            >
              <Typography
                css={css`
                  color: rgba(0, 0, 0, 0.87);
                  font-size: 14px;
                  font-weight: 400;
                `}
              >
                {emptyTableInfo}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const getDataRowMap = useCallback(
    (index: number, lead: GetReferralPartnerLeadsProps) => {
      return (
        <>
          <TableRow
            key={lead.id}
            css={css`
              td {
                padding: 16px;
                box-sizing: border-box;
                word-break: break-word;
                white-space: normal;
              }
            `}
          >
            <TableCell
              css={css`
                width: ${LEADS_TABLE_COLUMN_WIDTHS.NAME.width};
                min-width: ${LEADS_TABLE_COLUMN_WIDTHS.NAME.minWidth};
                box-sizing: border-box;
                padding: 16px;
              `}
            >
              <Box
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 4px;
                  flex: 1 0 0;
                `}
              >
                <Typography
                  css={css`
                    color: rgba(0, 0, 0, 0.87);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 143%;
                    letter-spacing: 0.17px;
                  `}
                >
                  {lead.name}
                </Typography>
                <Box
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: start;
                    gap: 8px;
                  `}
                >
                  <EmailOutlinedIcon
                    css={css`
                      color: #7a7a7a;
                      width: 16px;
                      height: 16px;
                      margin-top: 4px;
                    `}
                  />
                  <Typography
                    css={css`
                      color: #7a7a7a;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 143%;
                      letter-spacing: 0.17px;
                    `}
                  >
                    {lead.email}
                  </Typography>
                </Box>
                <Box
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                  `}
                >
                  <LocalPhoneOutlinedIcon
                    css={css`
                      color: #7a7a7a;
                      width: 16px;
                      height: 16px;
                    `}
                  />
                  <Typography
                    css={css`
                      color: #7a7a7a;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                    `}
                  >
                    {getCountryFromCountryCode(lead.countryCode)?.symbol}{' '}
                    {lead.phone}
                  </Typography>
                </Box>
              </Box>
            </TableCell>
            {/* <TableCell
              css={css`
                width: ${LEADS_TABLE_COLUMN_WIDTHS.EMAIL.width};
                min-width: ${LEADS_TABLE_COLUMN_WIDTHS.EMAIL.minWidth};
                max-width: ${LEADS_TABLE_COLUMN_WIDTHS.EMAIL.maxWidth};
              `}
            >
              <Typography css={leadsTableCellStyles}>{lead.email}</Typography>
            </TableCell>
            <TableCell
              css={css`
                width: ${LEADS_TABLE_COLUMN_WIDTHS.PHONE_NUMBER.width};
                min-width: ${LEADS_TABLE_COLUMN_WIDTHS.PHONE_NUMBER.minWidth};
                max-width: ${LEADS_TABLE_COLUMN_WIDTHS.PHONE_NUMBER.maxWidth};
              `}
            >
              <Typography css={leadsTableCellStyles}>
                {getCountryFromCountryCode(lead.countryCode)?.symbol}{' '}
                {lead.phone}
              </Typography>
            </TableCell> */}
            <TableCell
              css={css`
                width: ${LEADS_TABLE_COLUMN_WIDTHS.GST_NUMBER.width};
                min-width: ${LEADS_TABLE_COLUMN_WIDTHS.GST_NUMBER.minWidth};
                max-width: ${LEADS_TABLE_COLUMN_WIDTHS.GST_NUMBER.maxWidth};
              `}
            >
              <Typography css={leadsTableCellStyles}>
                {lead.gstNumber || '-'}
              </Typography>
            </TableCell>
            <TableCell
              css={css`
                width: ${LEADS_TABLE_COLUMN_WIDTHS.CREATED_ON.width};
                min-width: ${LEADS_TABLE_COLUMN_WIDTHS.CREATED_ON.minWidth};
                max-width: ${LEADS_TABLE_COLUMN_WIDTHS.CREATED_ON.maxWidth};
              `}
            >
              <Typography css={leadsTableCellStyles}>
                {formatDate(lead.dateCreated)}
              </Typography>
            </TableCell>
            <TableCell
              css={css`
                width: ${LEADS_TABLE_COLUMN_WIDTHS.PLAN.width};
                min-width: ${LEADS_TABLE_COLUMN_WIDTHS.PLAN.minWidth};
                max-width: ${LEADS_TABLE_COLUMN_WIDTHS.PLAN.maxWidth};
              `}
            >
              <Box
                css={css`
                  display: flex;
                  padding: 6px 12px;
                  align-items: center;
                  gap: 8px;
                  border-radius: 12px;
                  background: var(
                    --_components-input-filled-hoverFill,
                    rgba(30, 41, 59, 0.09)
                  );
                  width: max-content;
                  max-width: 100%;
                `}
              >
                <TypographyWithEllipsisTooltip
                  css={css`
                    color: #2d2d2d;
                    text-align: right;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    max-width: 100%;
                  `}
                >
                  {formatPlanDisplayText(
                    lead.subscriptionPeriod,
                    lead.pitchedPlan
                  )}
                </TypographyWithEllipsisTooltip>
              </Box>
            </TableCell>
            <TableCell
              css={css`
                width: ${LEADS_TABLE_COLUMN_WIDTHS.SELLING_PRICE.width};
                min-width: ${LEADS_TABLE_COLUMN_WIDTHS.SELLING_PRICE.minWidth};
                max-width: ${LEADS_TABLE_COLUMN_WIDTHS.SELLING_PRICE.maxWidth};
              `}
              align="right"
            >
              <Typography css={leadsTableCellStyles}>
                {lead.quotedPrice
                  ? formatCurrency(lead.quotedPrice, lead.currencyCode)
                  : '-'}
              </Typography>
            </TableCell>
            <TableCell
              css={css`
                width: ${LEADS_TABLE_COLUMN_WIDTHS.REMARKS.width};
                min-width: ${LEADS_TABLE_COLUMN_WIDTHS.REMARKS.minWidth};
                max-width: ${LEADS_TABLE_COLUMN_WIDTHS.REMARKS.maxWidth};
              `}
            >
              <TypographyWithEllipsisTooltip 
                css={leadsTableCellStyles}
                maxLines={2}
                showArrow={true}
                placement="left"
              >
                {lead.remarks || '-'}
              </TypographyWithEllipsisTooltip>
            </TableCell>
            <TableCell
              css={css`
                width: ${LEADS_TABLE_COLUMN_WIDTHS.ACTION.width};
                min-width: ${LEADS_TABLE_COLUMN_WIDTHS.ACTION.minWidth};
                max-width: ${LEADS_TABLE_COLUMN_WIDTHS.ACTION.maxWidth};
              `}
            >
              <ActionButton
                Icon={DeleteIcon}
                onClick={() => {
                  setSelectedLead(lead);
                  setShowDeleteConfirmationModal(true);
                }}
                tooltip="Delete"
              />
            </TableCell>
          </TableRow>
        </>
      );
    },
    []
  );

  const renderLoaderInFooter = () => {
    if (isFetchingMore) {
      return (
        <TableRow>
          <TableCell colSpan={8}>
            <Box
              css={css`
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              <Loader />
            </Box>
          </TableCell>
        </TableRow>
      );
    }
    return null;
  };

  if (!loading && !error && Array.isArray(leads) && leads.length > 0) {
    return (
      <>
        <TableVirtuoso
          style={virtuosoStyle}
          css={virtuosoCss}
          data={leads}
          fixedHeaderContent={renderHeader}
          totalCount={(leads ?? []).length}
          itemContent={getDataRowMap}
          endReached={fetchMoreLeads}
          components={memoizedComponents}
          fixedFooterContent={renderLoaderInFooter}
          overscan={15}
          increaseViewportBy={{ top: 300, bottom: 300 }}
        />
        <DeleteLeadConfirmationModal
          open={showDeleteConfirmationModal}
          onCancelClick={handleDeleteConfirmationModalCancelClick}
          onOkClick={handleDeleteConfirmationModalOkClick}
          selectedLead={selectedLead}
        />
      </>
    );
  }

  return (
    <TableContainer
      component={Paper}
      css={css`
        border-radius: 12px;
        table {
          table-layout: fixed;
          width: 100%;
        }
        .MuiTableCell-root {
          box-sizing: border-box;
          padding: 16px;
        }
        .MuiTableCell-head {
          background: var(--grey-50, #fafafa);
          font-weight: 500;
          color: #2d2d2d;
        }
      `}
    >
      <Table
        sx={{ minWidth: 650, width: '100%' }}
        aria-label="leads table"
      >
        <TableHead>
          <TableRow>
            <TableCell
              css={css`
                width: 100%;
                min-width: ${LEADS_TABLE_COLUMN_WIDTHS.NAME.minWidth};
              `}
            >
              Name
            </TableCell>
            <TableCell
              css={css`
                width: ${LEADS_TABLE_COLUMN_WIDTHS.GST_NUMBER.width};
                min-width: ${LEADS_TABLE_COLUMN_WIDTHS.GST_NUMBER.minWidth};
                max-width: ${LEADS_TABLE_COLUMN_WIDTHS.GST_NUMBER.maxWidth};
              `}
            >
              GST Number
            </TableCell>
            <TableCell
              css={css`
                width: ${LEADS_TABLE_COLUMN_WIDTHS.CREATED_ON.width};
                min-width: ${LEADS_TABLE_COLUMN_WIDTHS.CREATED_ON.minWidth};
                max-width: ${LEADS_TABLE_COLUMN_WIDTHS.CREATED_ON.maxWidth};
              `}
            >
              Created On
            </TableCell>
            <TableCell
              css={css`
                width: ${LEADS_TABLE_COLUMN_WIDTHS.PLAN.width};
                min-width: ${LEADS_TABLE_COLUMN_WIDTHS.PLAN.minWidth};
                max-width: ${LEADS_TABLE_COLUMN_WIDTHS.PLAN.maxWidth};
              `}
            >
              Plan
            </TableCell>
            <TableCell
              css={css`
                width: ${LEADS_TABLE_COLUMN_WIDTHS.SELLING_PRICE.width};
                min-width: ${LEADS_TABLE_COLUMN_WIDTHS.SELLING_PRICE.minWidth};
                max-width: ${LEADS_TABLE_COLUMN_WIDTHS.SELLING_PRICE.maxWidth};
              `}
              align="right"
            >
              Selling Price
            </TableCell>
            <TableCell
              css={css`
                width: ${LEADS_TABLE_COLUMN_WIDTHS.REMARKS.width};
                min-width: ${LEADS_TABLE_COLUMN_WIDTHS.REMARKS.minWidth};
                max-width: ${LEADS_TABLE_COLUMN_WIDTHS.REMARKS.maxWidth};
              `}
            >
              Remarks
            </TableCell>
            <TableCell
              css={css`
                width: ${LEADS_TABLE_COLUMN_WIDTHS.ACTION.width};
                min-width: ${LEADS_TABLE_COLUMN_WIDTHS.ACTION.minWidth};
                max-width: ${LEADS_TABLE_COLUMN_WIDTHS.ACTION.maxWidth};
              `}
            >
              {''}
            </TableCell>
          </TableRow>
        </TableHead>
        {renderTableRows()}
      </Table>
    </TableContainer>
  );
};

export default LeadsListTable;