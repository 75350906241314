/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo } from 'react';
import { isReferralPartnerCustomer } from '../../../../utils';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../routes/auth-routes/routes';
import {
  GetChannelPartnerReferralDetailsResponse,
  ReferralPartnerType,
} from '../../../referral-partner-onboarding/types';
import useQuery from '../../../../hooks/useQuery';
import {
  editReferralPartnerBankDetailsAtom,
  editReferralPartnerBusinessDetailsAtom,
  editReferralPartnerPersonalDetailsAtom,
  referralPartnerProfileDetailsAtom,
} from '../states';
import { getReferralPartnerAllDetails } from '../../../referral-partner-onboarding/requests';
import Loader from '../../../../components/loader';
import { css } from '@emotion/react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import withHeader from '../../../../HOC/WithHeader';
import EditReferralPartnerPersonalDetails from './personal-details';
import EditReferralPartnerBusinessDetails from './business-details';
import EditReferralPartnerBankDetails from './bank-details';
import { useAtomValue, useSetAtom } from 'jotai';
import { getCountryFromCountryCode } from '../../../../utils/countries-mapper';

const businessTabs = [
  { label: 'Profile Details', index: 0, id: 'profile-details' },
  { label: 'Business Details', index: 1, id: 'business-details' },
  { label: 'Bank Details', index: 2, id: 'bank-details' },
];

const individualTabs = [
  { label: 'Profile Details', index: 0, id: 'profile-details' },
  { label: 'Bank Details', index: 1, id: 'bank-details' },
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const { data: referralPartnerProfileDetailsData } = useAtomValue(referralPartnerProfileDetailsAtom)
  const isIndividual = referralPartnerProfileDetailsData?.referralPartnerType === ReferralPartnerType.INDIVIDUAL

  const renderTabBody = () => {
    if (index === 0) {
      return <EditReferralPartnerPersonalDetails />;
    }
    if (index === 1 && !isIndividual) {
      return <EditReferralPartnerBusinessDetails />;
    }else if(index === 1 && isIndividual){
      return <EditReferralPartnerBankDetails />
    }
    
    if (index === 2 && !isIndividual) {
      return <EditReferralPartnerBankDetails />;
    }
    return null;
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      css={css`
        width: 100%;
        max-width: 800px;
        height: 100%;
      `}
    >
      {renderTabBody()}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const EditReferralPartnerProfile = () => {
  const isReferralPartner = isReferralPartnerCustomer();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const setEditReferralPartnerPersonalDetails = useSetAtom(
    editReferralPartnerPersonalDetailsAtom
  );
  const setEditReferralPartnerBusinessDetails = useSetAtom(
    editReferralPartnerBusinessDetailsAtom
  );
  const setEditReferralPartnerBankDetails = useSetAtom(
    editReferralPartnerBankDetailsAtom
  );

  const {
    requestData: { loading, error, data: referralPartnerProfileDetailsData },
  } = useQuery<GetChannelPartnerReferralDetailsResponse>({
    requestAtom: referralPartnerProfileDetailsAtom,
    queryFunction: getReferralPartnerAllDetails,
    cacheData: true,
  });

  const getSetReferralPartnerProfileDetails = useCallback(() => {
    setEditReferralPartnerPersonalDetails({
      address: referralPartnerProfileDetailsData?.address,
      countryCode: referralPartnerProfileDetailsData?.countryCode ?? 'IN',
      state: referralPartnerProfileDetailsData?.state,
      city: referralPartnerProfileDetailsData?.city,
      pincode: referralPartnerProfileDetailsData?.pinCode,
      email: referralPartnerProfileDetailsData?.email,
      taxIdentificationNumber: referralPartnerProfileDetailsData?.taxIdNumber,
      addressProof: referralPartnerProfileDetailsData?.addressProofUrl,
      fullName: referralPartnerProfileDetailsData?.fullName,
      panNumber: referralPartnerProfileDetailsData?.panNumber,
      pan: referralPartnerProfileDetailsData?.panProofUrl,
      taxIdentificationDocument:
        referralPartnerProfileDetailsData?.taxIdProofUrl,
      country: getCountryFromCountryCode(
        referralPartnerProfileDetailsData?.countryCode ?? 'IN'
      ),
    });

    if (referralPartnerProfileDetailsData?.bankAccountNumber) {
      setEditReferralPartnerBankDetails({
        accountHolderName: referralPartnerProfileDetailsData?.accountHolderName,
        bankAccountNumber: referralPartnerProfileDetailsData?.bankAccountNumber,
        bankAddress: referralPartnerProfileDetailsData?.bankAddress,
        bankName: referralPartnerProfileDetailsData?.bankName,
        cancelChequeScan: referralPartnerProfileDetailsData?.cancelledChequeUrl,
        swiftCode: referralPartnerProfileDetailsData?.swiftCode,
        ifscCode: referralPartnerProfileDetailsData?.ifscCode,
        additionalInformation:
          referralPartnerProfileDetailsData?.additionalInfo,
      });
    }

    if (
      referralPartnerProfileDetailsData?.referralPartnerType ===
      ReferralPartnerType.BUSINESS
    ) {
      setEditReferralPartnerBusinessDetails({
        companyName: referralPartnerProfileDetailsData?.companyName,
        businessPersonName: referralPartnerProfileDetailsData?.contactName,
        businessContactDetails:
          referralPartnerProfileDetailsData?.contactNumber,
        businessEmailId: referralPartnerProfileDetailsData?.contactEmail,
        hasGst: referralPartnerProfileDetailsData?.gstCertificateUrl
          ? true
          : false,
        gstCertificate: referralPartnerProfileDetailsData?.gstCertificateUrl,
        gstNumber: referralPartnerProfileDetailsData?.gstNumber,
        form10f: referralPartnerProfileDetailsData?.form10FProofUrl,
        hasMsmeRegistration:
          referralPartnerProfileDetailsData?.msmeCertificateUrl ? true : false,
        msmeRegistrationCertificate:
          referralPartnerProfileDetailsData?.msmeCertificateUrl,
        noEstablishmentDeclaration:
          referralPartnerProfileDetailsData?.noPermEstProofUrl,
      });
    }
  }, [
    referralPartnerProfileDetailsData,
    setEditReferralPartnerPersonalDetails,
    setEditReferralPartnerBusinessDetails,
    setEditReferralPartnerBankDetails,
  ]);

  const tabs = useMemo(() => {
    return referralPartnerProfileDetailsData?.referralPartnerType === ReferralPartnerType.INDIVIDUAL ? individualTabs : businessTabs;
  }, [referralPartnerProfileDetailsData]);

  useEffect(() => {
    if (!isReferralPartner) {
      navigate(ROUTES.homePage);
    }
  }, [isReferralPartner, navigate]);

  useEffect(() => {
    if (!loading && referralPartnerProfileDetailsData) {
      getSetReferralPartnerProfileDetails();
    }
  }, [loading, referralPartnerProfileDetailsData]);

  if (loading) {
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          height: calc(100vh - 64px);
          width: 100%;
        `}
      >
        <Loader size={32} secondary={'Loading...'} />
      </div>
    );
  }

  return (
    <Box
      css={css`
        display: flex;
        flex-direction: column;
        height: calc(100vh - 64px); /* Subtract header height */
        width: 100%;
      `}
    >
      {/* Fixed Header Section */}
      <Box
        css={css`
          padding: 40px 80px 0;
          background: #fbfbfc;
          z-index: 1;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        `}
      >
        <Typography
          css={css`
            color: var(--Neutral-Primary, #2d2d2d);
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
            letter-spacing: -0.24px;
            margin-bottom: 20px;
            align-self: flex-start;
            max-width: 800px;
            width: 100%;
            align-self: center;
          `}
        >
          Edit Profile
        </Typography>
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}
          css={css`
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 800px;
          `}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{ style: { backgroundColor: '#3F906D' } }}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.index}
                label={tab.label.toUpperCase()}
                {...a11yProps(tab.index)}
                sx={{ '&.Mui-selected': { color: '#3F906D' } }}
              />
            ))}
          </Tabs>
        </Box>
      </Box>

      {/* Scrollable Content Section */}
      <Box
        css={css`
          flex: 1;
          overflow-y: auto;
          padding: 24px 80px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        `}
      >
        {tabs.map((tab) => (
          <CustomTabPanel key={tab.index} value={value} index={tab.index}>
            {tab.label}
          </CustomTabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default withHeader(EditReferralPartnerProfile);
