export const formatPlanDisplayText = (subscriptionPeriod: string, planId: string) => {
  if (!subscriptionPeriod || !planId) {
    return '-';
  }
  subscriptionPeriod = subscriptionPeriod.toLocaleLowerCase();
  planId = planId.toLocaleLowerCase();
  subscriptionPeriod = subscriptionPeriod.charAt(0).toUpperCase() + subscriptionPeriod.slice(1);
  planId = planId.charAt(0).toUpperCase() + planId.slice(1);
  return `${planId} - ${subscriptionPeriod}`;
};
