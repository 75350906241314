import { atom } from 'jotai';
import {
  GetChannelPartnerReferralDetailsResponse,
  ReferralPartnerOnboardingBankDetails,
  ReferralPartnerOnboardingBusinessDetails,
  ReferralPartnerOnboardingPersonalDetails,
} from '../../../referral-partner-onboarding/types';
import {
  getCountryCodeFromLocalStorage,
  getCountryFromCountryCode,
} from '../../../../utils/countries-mapper';
import { createRequestAtom } from '../../../../utils/atoms/request-atom';

export const editReferralPartnerPersonalDetailsAtom =
  atom<ReferralPartnerOnboardingPersonalDetails>({
    fullName: '',
    email: '',
    address: '',
    country: getCountryFromCountryCode(
      getCountryCodeFromLocalStorage() ?? 'IN'
    )!,
    state: '',
    city: '',
    pincode: '',
    pan: null,
    panNumber: '',
    countryCode: getCountryFromCountryCode(
      getCountryCodeFromLocalStorage() ?? 'IN'
    )!.countryCode,
    taxIdentificationNumber: '',
    taxIdentificationDocument: null,
    addressProof: null,
  });

export const editReferralPartnerBankDetailsAtom =
  atom<ReferralPartnerOnboardingBankDetails>({
    bankName: '',
    bankAddress: '',
    bankAccountNumber: '',
    ifscCode: '',
    cancelChequeScan: null,
    swiftCode: '',
    accountHolderName: '',
    additionalInformation: '',
  });

export const editReferralPartnerBusinessDetailsAtom =
  atom<ReferralPartnerOnboardingBusinessDetails>({
    companyName: '',
    businessPersonName: '',
    businessContactDetails: '',
    businessEmailId: '',
    gstNumber: '',
    gstCertificate: null as File | null,
    hasMsmeRegistration: false,
    msmeRegistrationCertificate: null as File | null,
    hasGst: false,
    noEstablishmentDeclaration: null as File | null,
    form10f: null as File | null,
  });

export const referralPartnerProfileDetailsAtom =
  createRequestAtom<GetChannelPartnerReferralDetailsResponse>();
