/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Tooltip, Typography, TypographyProps } from '@mui/material';
import { ElementType, ReactNode, useEffect, useRef, useState } from 'react';

export const isTextRTL = (text: string) => {
  const rtlCharsPattern = /[\u0590-\u05FF\u0600-\u06FF]/;
  return rtlCharsPattern.test(text);
};

export const isTextOverflowing = (element: HTMLElement): boolean => {
  return element.scrollWidth > element.clientWidth;
};

export type TypographyWithEllipsisTooltipProps<P extends ElementType<any>> =
  TypographyProps<
    P,
    {
      children?: ReactNode;
      component?: P;
      showArrow?: boolean;
      maxLines?: number;
      placement?: 'top' | 'bottom' | 'left' | 'right';
    }
  >;

function TypographyWithEllipsisTooltip<P extends ElementType<any> = 'span'>({
  sx,
  children,
  component,
  showArrow = false,
  maxLines,
  placement = 'top',
  ...props
}: TypographyWithEllipsisTooltipProps<P>) {
  const textRef = useRef<HTMLElement>(null);
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);

  useEffect(() => {
    if (!textRef.current) return;

    const checkOverflow = () => {
      if (textRef.current) {
        if (maxLines) {
          const lineHeight = parseInt(window.getComputedStyle(textRef.current).lineHeight);
          const maxHeight = lineHeight * maxLines;
          setIsOverflowing(textRef.current.scrollHeight > maxHeight);
        } else {
          setIsOverflowing(isTextOverflowing(textRef.current));
        }
      }
    };
    checkOverflow();
    const resizeObserver = new ResizeObserver(checkOverflow);
    if (textRef.current) {
      resizeObserver.observe(textRef.current);
    }
    return () => {
      if (textRef.current) {
        resizeObserver.unobserve(textRef.current);
      }
    };
  }, [children, maxLines, textRef.current?.clientWidth, textRef.current?.scrollWidth]);

  if (!children) return null;

  let direction: string | undefined;

  if (typeof children === 'string') {
    direction = isTextRTL(children) ? 'rtl' : 'ltr';
  }

  const renderText = (text: ReactNode) => {
    return (
      <Typography
        component={component}
        sx={{
          ...sx,
        }}
        {...props}
        dir={props.dir ? props.dir : direction}
        ref={textRef}
        css={css`
          color: #2d2d2d;
          font-family: Inter;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          ${maxLines ? `
            display: -webkit-box;
            -webkit-line-clamp: ${maxLines};
            -webkit-box-orient: vertical;
            overflow: hidden;
          ` : `
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
        `}
      >
        {text}
      </Typography>
    );
  };

  if (isOverflowing) {
    return (
      <Tooltip placement={placement} title={children} arrow={showArrow}>
        {renderText(children)}
      </Tooltip>
    );
  }

  return renderText(children);
}

export default TypographyWithEllipsisTooltip;
