/** @jsxImportSource @emotion/react */
import {
  Box,
  Button,
  ButtonBase,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { css } from '@emotion/react';
import WithLabel from '../../../../../components/input/WithLabel';
import { useAtom, useAtomValue } from 'jotai';
import {
  editWhitelabelPartnerProfileErrorAtom,
  editWhitelabelPartnerProfileLoadingAtom,
  editWhitelabelPartnerWebsiteInfoAtom,
  errorFieldsAtom,
  whitelabelPartnerProfileDetailsAtom,
} from '../../state';
import useNotifications from '../../../../../hooks/useNotifications';
import { useDropzone } from 'react-dropzone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PhoneDropdown from '../../../../countryPicker';
import { DISABLED_INPUT_STYLE } from '../../constants';
import InfoOutlineIcon from '@mui/icons-material/InfoOutlined';

const WhitelabelPartnerWebsiteInfo = () => {
  const [
    editWhitelabelPartnerWebsiteInfo,
    setEditWhitelabelPartnerWebsiteInfo,
  ] = useAtom(editWhitelabelPartnerWebsiteInfoAtom);
  const { addNotification } = useNotifications();
  const [isApiError, setIsApiError] = useAtom(
    editWhitelabelPartnerProfileErrorAtom
  );
  const [isLoading, setIsLoading] = useAtom(
    editWhitelabelPartnerProfileLoadingAtom
  );
  const whitelabelPartnerProfileDetailsData = useAtomValue(
    whitelabelPartnerProfileDetailsAtom
  );
  const [errorFields, setErrorFields] = useAtom(errorFieldsAtom);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) return;
      setEditWhitelabelPartnerWebsiteInfo({
        ...editWhitelabelPartnerWebsiteInfo,
        logo: acceptedFiles[0],
      });
    },
    [editWhitelabelPartnerWebsiteInfo, setEditWhitelabelPartnerWebsiteInfo]
  );

  const removeLogoImage = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setEditWhitelabelPartnerWebsiteInfo({
      ...editWhitelabelPartnerWebsiteInfo,
      logo: null,
    });
  };

  const {
    getRootProps,
    getInputProps,
    open: selectExcelFile,
  } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    // maxSize: 1 * 1024 * 1024, // 3MB in bytes
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorFields((prev) => ({
      ...prev,
      [e.target.name]: null,
    }));
    setEditWhitelabelPartnerWebsiteInfo({
      ...editWhitelabelPartnerWebsiteInfo,
      [e.target.name]: e.target.value,
    });
  };

  const showInvalidLogo = () => {
    if (isApiError && !editWhitelabelPartnerWebsiteInfo?.logo) {
      return true;
    }
    return false;
  };

  const renderLogoInput = () => {
    let logoUrl = editWhitelabelPartnerWebsiteInfo?.logo;
    try {
      if (typeof editWhitelabelPartnerWebsiteInfo?.logo === 'object') {
        logoUrl = URL.createObjectURL(editWhitelabelPartnerWebsiteInfo.logo!);
      }
    } catch (e) {
      console.log(e);
    }

    if (logoUrl) {
      return (
        <Box
          css={css`
            border: 1px solid rgba(0, 0, 0, 0.12);
            border-radius: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background-color: #fbfbfb;
            position: relative;
            padding: 16px;
            width: 100%;
          `}
          {...getRootProps()}
        >
          <img
            height={'auto'}
            width={'120px'}
            alt="logo"
            src={logoUrl as string}
          />
          <ButtonBase
            onClick={removeLogoImage}
            css={css`
              display: flex;
              height: 40px;
              padding: 8px 16px 8px 12px;
              align-items: center;
              gap: 8px;
              border-radius: 4px;
              border: 1px solid #3f906d;
            `}
          >
            <EditOutlinedIcon style={{ color: '#3F906D', fontSize: '18px' }} />
            <Typography
              css={css`
                color: #3f906d;
                text-align: center;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
              `}
            >
              Change
            </Typography>
          </ButtonBase>
        </Box>
      );
    }
    return (
      <Box
        css={css`
          display: flex;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          width: 100%;
        `}
      >
        <Box
          css={css`
            border: ${!!errorFields.logo
              ? '1px dashed #C21313'
              : '1px dashed rgba(0, 0, 0, 0.12)'};
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            height: 150px;
            align-items: center;
            justify-content: center;
            background-color: #fbfbfb;
            cursor: pointer;
            flex: 1;
          `}
          {...getRootProps()}
          onClick={selectExcelFile}
        >
          <UploadFileIcon
            css={css`
              color: #3F906D;
            `}
          />
          <Typography
            css={css`
              font-size: 14px;
              padding: 12px 0px 4px 0px;
            `}
          >
            <Button
              css={css`
                color: #3F906D;
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
              `}
            >
              <u>Click to upload logo</u>
            </Button>
            or drag and drop
          </Typography>
          <Typography
            css={css`
              color: rgba(0, 0, 0, 0.6);
              text-align: center;
              font-size: 14px;
              font-weight: 400;
            `}
          >
            Supported formats are .jpg, .png <br />
            Maximum size file size 2MB and image size 512x512
          </Typography>
          <input {...getInputProps()} />
        </Box>
        <ButtonBase
          onClick={() => {
            setErrorFields((prev) => ({
              ...prev,
              logo: null,
            }));
            setEditWhitelabelPartnerWebsiteInfo((prev) => ({
              ...prev,
              logo:
                whitelabelPartnerProfileDetailsData.data?.currentStateMetadata
                  ?.SET_PARTNER_DETAILS?.logoUrl ?? null,
            }));
          }}
          css={css`
            display: flex;
            height: 40px;
            padding: 12px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 4px;
            border: 1px solid #e0e0e9;
            background: #fbfbfc;
            margin-right: 20px;
            transition: all 0.2s ease-in-out;
            &:hover {
              background: #f5f5f5;
              border-color: #d0d0d9;
            }
            &:active {
              background: #e8e8e8;
            }
          `}
        >
          <Typography
            css={css`
              color: var(--Neutral-Primary, #2d2d2d);
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            `}
          >
            Cancel
          </Typography>
        </ButtonBase>
      </Box>
    );
  };

  return (
    <Box
      css={css`
        display: flex;
        width: 100%;
        max-width: 800px;
        padding: 18px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        border-radius: 8px;
        border: 1px solid var(--Neutral-Stroke, #e0e0e9);
        background: #fff;
      `}
    >
      <Typography
        css={css`
          color: #2d2d2d;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        `}
      >
        Company Information
      </Typography>
      <FormGroup
        css={css`
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: 100%;
        `}
      >
        <WithLabel label="Logo">
          {renderLogoInput()}
          {!!errorFields.logo ? (
            <Typography
              css={css`
                font-size: 0.75rem;
                color: #c21313;
              `}
            >
              {errorFields.logo}
            </Typography>
          ) : null}
        </WithLabel>
        <WithLabel label="Company name">
          <TextField
            placeholder="Enter your company name"
            name="name"
            value={editWhitelabelPartnerWebsiteInfo.name}
            error={!!errorFields.name}
            helperText={errorFields.name}
            onChange={handleTextInputChange}
          />
        </WithLabel>
        <Box
          css={css`
            display: flex;
            gap: 12px;
            width: 100%;
          `}
        >
          <WithLabel
            label="WhatsApp Support number"
            containerStyle={{ flex: 1 }}
          >
            <Stack flexDirection={'row'} gap={1}>
              <PhoneDropdown
                containerHeight={'29px'}
                ignoreCountryCode
                value={editWhitelabelPartnerWebsiteInfo.savedCountry}
                onChange={() => {}}
                disabled
              />
              <TextField
                placeholder="Enter your company support number"
                name="supportNumber"
                type="number"
                fullWidth
                value={editWhitelabelPartnerWebsiteInfo.supportNumber}
                onChange={handleTextInputChange}
                disabled
                sx={DISABLED_INPUT_STYLE}
              />
            </Stack>
          </WithLabel>
          <WithLabel label="Custom domain" containerStyle={{ flex: 1 }}>
            <TextField
              placeholder="www.companyname.com"
              name="domain"
              type="url"
              value={editWhitelabelPartnerWebsiteInfo.domain}
              onChange={handleTextInputChange}
              disabled
              sx={DISABLED_INPUT_STYLE}
              fullWidth
            />
          </WithLabel>
        </Box>
        <Box
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6px;
            align-self: flex-start;
            margin-top: -6px;
          `}
        >
          <InfoOutlineIcon
            style={{
              fontSize: '16px',
              color: '#7A7A7A',
            }}
          />
          <Typography
            css={css`
              color: var(--Neutral-Secondary, #7a7a7a);
              font-family: Inter;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            `}
          >
            This field is linked to your account creation and cannot be edited.
          </Typography>
        </Box>
      </FormGroup>
    </Box>
  );
};

export default WhitelabelPartnerWebsiteInfo;
