import { businessDetailsApiFormDataKeysToStateKeysMapping } from '../../../referral-partner-onboarding/constants';
import {
  GetChannelPartnerReferralDetailsResponse,
  ReferralPartnerOnboardingBusinessDetails,
} from '../../../referral-partner-onboarding/types';
import { compareChanges } from '../../../../utils/compare-changes';

const areBusinessDetailsChanged = (
  referralPartnerProfileDetails: GetChannelPartnerReferralDetailsResponse,
  editReferralPartnerBusinessDetails: ReferralPartnerOnboardingBusinessDetails
): boolean => {
  // Convert the mapping to the correct type
  const apiMapping = businessDetailsApiFormDataKeysToStateKeysMapping;
  const mapping: Partial<Record<keyof GetChannelPartnerReferralDetailsResponse, keyof ReferralPartnerOnboardingBusinessDetails>> = {};
  
  // Type-safe way to build the mapping
  Object.entries(apiMapping).forEach(([key, value]) => {
    if (value in editReferralPartnerBusinessDetails) {
      mapping[key as keyof GetChannelPartnerReferralDetailsResponse] = value as keyof ReferralPartnerOnboardingBusinessDetails;
    }
  });

  return compareChanges({
    apiResponse: referralPartnerProfileDetails,
    editForm: editReferralPartnerBusinessDetails,
    mapping,
    customMappings: {
      // Add computed fields
      hasGst: (api) => !!api.gstCertificateUrl,
      hasMsmeRegistration: (api) => !!api.msmeCertificateUrl,
    },
    copyMissingFields: true,
    treatEmptyAsNull: true
  });
};

export default areBusinessDetailsChanged;
