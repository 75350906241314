/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Box,
  Button,
  ButtonBase,
  FormControlLabel,
  FormGroup,
  Link,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import WithLabel from '../../../../../components/input/WithLabel';
import {
  editReferralPartnerBusinessDetailsAtom,
  referralPartnerProfileDetailsAtom,
} from '../../states';
import { useAtom, useAtomValue } from 'jotai';
import useNotifications from '../../../../../hooks/useNotifications';
import { useMemo, useState } from 'react';
import { postReferralBusinessDetails } from '../../../../referral-partner-onboarding/requests';
import { handleApiError } from '../../../../../utils/error-handler';
import { businessDetailsApiFormDataKeysToStateKeysMapping } from '../../../../referral-partner-onboarding/constants';
import { ReferralPartnerType } from '../../../../referral-partner-onboarding/types';
import {
  FileUploadField,
  labelStyles,
} from '../../../../../components/input/FileUploadField';
import { openPopup } from '../../../../../utils/popup';
import areBusinessDetailsChanged from '../../utils/are-business-details-changed';
import BottomActionBar from '../../../whitelabel/components/bottom-action-bar';
const EditReferralPartnerBusinessDetails = () => {
  const [
    editReferralPartnerBusinessDetails,
    setEditReferralPartnerBusinessDetails,
  ] = useAtom(editReferralPartnerBusinessDetailsAtom);
  const [
    { data: referralPartnerProfileDetails },
    setReferralPartnerProfileDetailsData,
  ] = useAtom(referralPartnerProfileDetailsAtom);
  const { addNotification } = useNotifications();
  const [isApiError, setIsApiError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isInternational = referralPartnerProfileDetails?.countryCode !== 'IN';

  type ErrorFields = Record<string, string | null>;

  const errorFieldsInitialState: ErrorFields = {
    companyName: null,
    businessPersonName: null,
    businessContactDetails: null,
    businessEmailId: null,
    gstNumber: null,
    gstCertificate: null,
    msmeRegistrationCertificate: null,
    noEstablishmentDeclaration: null,
    form10f: null,
  };

  const [errorFields, setErrorFields] = useState<ErrorFields>(
    errorFieldsInitialState
  );

  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorFields(prev => ({
      ...prev,
      [e.target.name]: null
    }));

    setEditReferralPartnerBusinessDetails({
      ...editReferralPartnerBusinessDetails,
      [e.target.name]: e.target.value,
    });
  };

  const areFieldsChanged = useMemo(() => {
    if (referralPartnerProfileDetails) {
      return areBusinessDetailsChanged(
        referralPartnerProfileDetails,
        editReferralPartnerBusinessDetails
      );
    }
    return false;
  }, [referralPartnerProfileDetails, editReferralPartnerBusinessDetails]);

  const resetFields = () => {
    setEditReferralPartnerBusinessDetails({
      companyName: referralPartnerProfileDetails?.companyName,
      businessPersonName: referralPartnerProfileDetails?.contactName,
      businessContactDetails: referralPartnerProfileDetails?.contactNumber,
      businessEmailId: referralPartnerProfileDetails?.contactEmail,
      hasGst: referralPartnerProfileDetails?.gstCertificateUrl ? true : false,
      gstCertificate: referralPartnerProfileDetails?.gstCertificateUrl,
      gstNumber: referralPartnerProfileDetails?.gstNumber,
      form10f: referralPartnerProfileDetails?.form10FProofUrl,
      hasMsmeRegistration: referralPartnerProfileDetails?.msmeCertificateUrl
        ? true
        : false,
      msmeRegistrationCertificate:
        referralPartnerProfileDetails?.msmeCertificateUrl,
      noEstablishmentDeclaration:
        referralPartnerProfileDetails?.noPermEstProofUrl,
    });
    setErrorFields(errorFieldsInitialState);
  };

  const handleFileUpload = (file: File | null, type: string) => {
    setErrorFields(prev => ({
      ...prev,
      [type]: null
    }));

    setEditReferralPartnerBusinessDetails({
      ...editReferralPartnerBusinessDetails,
      [type]: file,
    });
  };

  const handleGstChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isGstYes = e.target.value === 'yes';
    
    setErrorFields(prev => ({
      ...prev,
      gstNumber: null,
      gstCertificate: null
    }));

    setEditReferralPartnerBusinessDetails({
      ...editReferralPartnerBusinessDetails,
      hasGst: isGstYes,
      gstNumber: isGstYes ? editReferralPartnerBusinessDetails.gstNumber || '' : '',
      gstCertificate: isGstYes ? editReferralPartnerBusinessDetails.gstCertificate || null : null,
    });
  };

  const handleMsmeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isMsmeYes = e.target.value === 'yes';

    setErrorFields(prev => ({
      ...prev,
      msmeRegistrationCertificate: null
    }));

    setEditReferralPartnerBusinessDetails({
      ...editReferralPartnerBusinessDetails,
      hasMsmeRegistration: isMsmeYes,
      msmeRegistrationCertificate: isMsmeYes ? editReferralPartnerBusinessDetails.msmeRegistrationCertificate || null : null,
    });
  };

  const validateFields = () => {
    let isValid = true;
    let updatedErrorFields = { ...errorFields };
    if (!isInternational) {
      if (
        editReferralPartnerBusinessDetails.hasGst &&
        !editReferralPartnerBusinessDetails.gstNumber?.trim()
      ) {
        updatedErrorFields = {
          ...updatedErrorFields,
          gstNumber: 'GST number is required',
        };
        isValid = false;
      } else {
        updatedErrorFields = { ...updatedErrorFields, gstNumber: null };
      }

      if (
        editReferralPartnerBusinessDetails.hasGst &&
        !editReferralPartnerBusinessDetails.gstCertificate
      ) {
        updatedErrorFields = {
          ...updatedErrorFields,
          gstCertificate: 'GST certificate is required',
        };
        isValid = false;
      } else {
        updatedErrorFields = { ...updatedErrorFields, gstCertificate: null };
      }

      if (
        editReferralPartnerBusinessDetails.hasMsmeRegistration &&
        !editReferralPartnerBusinessDetails.msmeRegistrationCertificate
      ) {
        updatedErrorFields = {
          ...updatedErrorFields,
          msmeRegistrationCertificate:
            'MSME registration certificate is required',
        };
        isValid = false;
      } else {
        updatedErrorFields = {
          ...updatedErrorFields,
          msmeRegistrationCertificate: null,
        };
      }
    }
    setErrorFields(updatedErrorFields);
    return isValid;
  };

  const saveAndHandleNext = async () => {
    try {
      setIsApiError(false);
      if (!validateFields()) {
        return;
      }
      setIsLoading(true);
      const response = await postReferralBusinessDetails(
        editReferralPartnerBusinessDetails,
        isInternational
      );
      setIsLoading(false);
      if (response.success) {
        addNotification({
          message: 'Business details updated successfully',
          type: 'success',
        });
        setReferralPartnerProfileDetailsData((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            ...response,
          },
        }));
        return;
      }
      setIsApiError(true);
      addNotification({
        message: 'Something went wrong, Please try again',
        type: 'error',
      });
    } catch (e) {
      setIsLoading(false);
      setIsApiError(true);
      handleApiError({
        error: e,
        mappingObject:
          businessDetailsApiFormDataKeysToStateKeysMapping as unknown as Record<
            string,
            string
          >,
        errorFieldsInitialState,
        setErrorFields,
        addNotification,
        isInternational,
      });
    }
  };

  if (
    referralPartnerProfileDetails?.referralPartnerType !==
    ReferralPartnerType.BUSINESS
  ) {
    return null;
  }

  return (
    <Box
      css={css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        padding-bottom: ${areFieldsChanged ? '104px' : '24px'};
      `}
    >
      <Box
        css={css`
          display: flex;
          width: 100%;
          padding: 18px 20px;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          border-radius: 8px;
          border: 1px solid var(--Neutral-Stroke, #e0e0e9);
          background: #fff;
        `}
      >
        <Typography
          css={css`
            color: #2d2d2d;
            font-size: 16px;
            font-weight: 600;
          `}
        >
          Business Contact & Registration Details
        </Typography>
        <FormGroup
          css={css`
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;
          `}
        >
          <WithLabel label="Company name">
            <TextField
              placeholder="Enter your company name"
              name="companyName"
              value={editReferralPartnerBusinessDetails.companyName}
              error={!!errorFields.companyName}
              helperText={errorFields.companyName || ''}
              onChange={handleTextInputChange}
            />
          </WithLabel>
          <WithLabel label="Business Person Name">
            <TextField
              placeholder="Enter business contact person name"
              name="businessPersonName"
              value={editReferralPartnerBusinessDetails.businessPersonName}
              error={!!errorFields.businessPersonName}
              helperText={errorFields.businessPersonName || ''}
              onChange={handleTextInputChange}
            />
          </WithLabel>
          <WithLabel
            label="Business Contact Details (Telephone/Mobile)"
            subtitle="(e.g. +919011368877)"
            subtitleAlignment="horizontal"
          >
            <TextField
              type="number"
              placeholder="Enter business contact details"
              name="businessContactDetails"
              value={editReferralPartnerBusinessDetails.businessContactDetails}
              error={!!errorFields.businessContactDetails}
              helperText={errorFields.businessContactDetails || ''}
              onChange={handleTextInputChange}
            />
          </WithLabel>
          <WithLabel label="Business email ID">
            <TextField
              placeholder="Enter business contact person email ID"
              name="businessEmailId"
              value={editReferralPartnerBusinessDetails.businessEmailId}
              error={!!errorFields.businessEmailId}
              helperText={errorFields.businessEmailId || ''}
              onChange={handleTextInputChange}
            />
          </WithLabel>
          {isInternational ? (
            <>
              <FileUploadField
                label="No Permanent Establishment Declaration in India. (On Letterhead)"
                helperText="Upload 1 supported file. Max 10 MB."
                onChange={(file) =>
                  handleFileUpload(file, 'noEstablishmentDeclaration')
                }
                accept="image/*,.pdf"
                direction="vertical"
                errorMsg={errorFields.noEstablishmentDeclaration || ''}
                file={
                  editReferralPartnerBusinessDetails.noEstablishmentDeclaration
                }
              >
                <Button
                  variant="text"
                  css={css`
                    padding: 8 22px;
                    border-radius: 4px;
                    color: #538d6f;
                    border-color: #538d6f;
                    font-size: 14px;
                    font-weight: 500;
                    text-transform: none;
                    &:hover {
                      border-color: #538d6f;
                      background: rgba(83, 141, 111, 0.04);
                    }
                    width: max-content;
                  `}
                  onClick={() => {
                    openPopup(
                      'https://doubletick-static-data.s3.ap-south-1.amazonaws.com/partners/NO+PE+Declaration+Draft.docx'
                    );
                  }}
                >
                  Download Format File
                </Button>
              </FileUploadField>
              <Box>
                <Typography css={labelStyles}>Form 10F (Optional)</Typography>
                <Typography
                  css={css`
                    color: var(--Neutral-Primary, #2d2d2d);
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding-top: 8px;
                  `}
                >
                  Required only if you refer clients based in India. Otherwise,
                  you can ignore it.
                </Typography>
                <Typography
                  css={css`
                    color: var(--Neutral-Primary, #2d2d2d);
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding-top: 8px;
                  `}
                >
                  <span
                    css={css`
                      color: var(--Neutral-Primary, #2d2d2d);
                      font-family: Inter;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: normal;
                    `}
                  >
                    Steps:
                  </span>{' '}
                  Fill out the form online on the India Income Tax portal.
                </Typography>
                <Typography
                  css={css`
                    color: var(--Neutral-Primary, #2d2d2d);
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  `}
                >
                  Format and guide:{' '}
                  <Link
                    css={css`
                      color: var(--info-main, #0288d1);
                      font-family: Inter;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-decoration-line: underline;
                      text-decoration-style: solid;
                      text-decoration-skip-ink: none;
                      text-decoration-thickness: auto;
                      text-underline-offset: auto;
                      text-underline-position: from-font;
                    `}
                    href="https://www.youtube.com/watch?v=IHy3TwQTChQ"
                    color="primary"
                  >
                    Watch tutorial
                  </Link>
                </Typography>
                <Typography
                  css={css`
                    color: var(--Neutral-Primary, #2d2d2d);
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding-top: 8px;
                  `}
                >
                  Portal to get Form 10F:{' '}
                  <Link
                    css={css`
                      color: var(--info-main, #0288d1);
                      font-family: Inter;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      text-decoration-line: underline;
                      text-decoration-style: solid;
                      text-decoration-skip-ink: none;
                      text-decoration-thickness: auto;
                      text-underline-offset: auto;
                      text-underline-position: from-font;
                    `}
                    href="https://www.incometax.gov.in/iec/foportal/"
                    target="_blank"
                    color="primary"
                  >
                    e-Filing Portal
                  </Link>
                </Typography>
                <Typography
                  css={css`
                    color: var(--Neutral-Primary, #2d2d2d);
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding-top: 8px;
                  `}
                >
                  Upload 1 supported file. Max 10 MB.
                </Typography>
                <FileUploadField
                  onChange={(file) => handleFileUpload(file, 'form10f')}
                  accept="image/*,.pdf"
                  direction="vertical"
                  file={editReferralPartnerBusinessDetails.form10f}
                  errorMsg={errorFields.form10f || ''}
                />
              </Box>
            </>
          ) : (
            <>
              <WithLabel label="Do you have GST?">
                <RadioGroup
                  row
                  name="hasGst"
                  value={
                    editReferralPartnerBusinessDetails.hasGst ? 'yes' : 'no'
                  }
                  onChange={handleGstChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </WithLabel>
              {editReferralPartnerBusinessDetails.hasGst && (
                <>
                  <WithLabel label="Business GST number">
                    <TextField
                      placeholder="Enter your GST number"
                      name="gstNumber"
                      value={editReferralPartnerBusinessDetails.gstNumber}
                      error={!!errorFields.gstNumber}
                      helperText={errorFields.gstNumber || ''}
                      onChange={handleTextInputChange}
                    />
                  </WithLabel>
                  <FileUploadField
                    label="Upload GST Certificate"
                    helperText="Upload 1 supported file: PDF or image. Max 10 MB."
                    onChange={(file) =>
                      handleFileUpload(file, 'gstCertificate')
                    }
                    accept="image/*,.pdf"
                    direction="vertical"
                    errorMsg={errorFields.gstCertificate || ''}
                    file={editReferralPartnerBusinessDetails.gstCertificate}
                  />
                </>
              )}
              <WithLabel label="Do you have MSME registration?">
                <RadioGroup
                  row
                  name="hasMsmeRegistration"
                  value={
                    editReferralPartnerBusinessDetails.hasMsmeRegistration
                      ? 'yes'
                      : 'no'
                  }
                  onChange={handleMsmeChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </WithLabel>
              {editReferralPartnerBusinessDetails.hasMsmeRegistration && (
                <FileUploadField
                  label="MSME Registration Certificate"
                  helperText="Upload 1 supported file: PDF or image. Max 10 MB."
                  onChange={(file) =>
                    handleFileUpload(file, 'msmeRegistrationCertificate')
                  }
                  accept="image/*,.pdf"
                  direction="vertical"
                  errorMsg={errorFields.msmeRegistrationCertificate || ''}
                  file={
                    editReferralPartnerBusinessDetails.msmeRegistrationCertificate
                  }
                />
              )}
            </>
          )}
        </FormGroup>
      </Box>
      <BottomActionBar
        isLoadingAPI={isLoading}
        handleSubmit={saveAndHandleNext}
        onCancel={resetFields}
        show={areFieldsChanged}
      />
    </Box>
  );
};

export default EditReferralPartnerBusinessDetails;
