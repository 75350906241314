export type APIErrorData = {
  status: number;
  statusCode: number;
  reason: string;
  error: string;
  extraData?: Record<string, string | number>;
  message: string;
};

export type ErrorMessage = {
  property: string;
  message: string | string[];
}[] | string;

export default class APIError extends Error {
  status: number;
  statusCode: number;
  reason: string;
  error: string;
  extraData?: Record<string, string | number>;
  originalMessage: string | ErrorMessage[];

  constructor({
    status,
    statusCode,
    reason,
    error,
    extraData,
    message,
  }: APIErrorData) {
    super(message as string);
    this.status = status;
    this.statusCode = statusCode;
    this.reason = reason;
    this.error = error;
    this.extraData = extraData;
    this.originalMessage = message;
  }
}
