/** @jsxImportSource @emotion/react */
import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import withHeader from '../../../HOC/WithHeader';
import SearchAndFilters from './search-and-filters';
import LeadsSubHeader from './header';
import LeadsListTable from './leads-list-table';
import AddLeadModal from './add-lead-modal';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  isAddLeadModalOpenAtom,
  newReferralPartnerLeadAtom,
  newReferralPartnerLeadFormErrorsAtom,
} from '../state';
import { useResetAtom } from 'jotai/utils';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes/auth-routes/routes';
import { isReferralPartnerCustomer } from '../../../utils';

const LeadsPage = () => {
  const isAddLeadModalOpen = useAtomValue(isAddLeadModalOpenAtom);
  const setIsAddLeadModalOpen = useSetAtom(isAddLeadModalOpenAtom);
  const resetLeadForm = useResetAtom(newReferralPartnerLeadAtom);
  const resetLeadFormErrors = useResetAtom(
    newReferralPartnerLeadFormErrorsAtom
  );
  const navigate = useNavigate();
  const isReferralPartner = isReferralPartnerCustomer();

  useEffect(() => {
    if (!isReferralPartner) {
      navigate(ROUTES.homePage);
    }
  }, [isReferralPartner, navigate]);

  return (
    <div
      css={css`
        height: calc(100vh - 60px);
      `}
    >
      <LeadsSubHeader />
      <Box
        css={css`
          margin: 20px 80px;
        `}
      >
        <Typography
          css={css`
            color: #7a7a7a;
            font-size: 14px;
            font-weight: 500;
            padding-top: 24px;
            padding-bottom: 12px;
          `}
        >
          Create, organize, and effectively manage your leads
        </Typography>
        <SearchAndFilters />
        <LeadsListTable />
      </Box>
      <AddLeadModal
        open={isAddLeadModalOpen}
        handleClose={() => {
          setIsAddLeadModalOpen(false);
          resetLeadForm();
          resetLeadFormErrors();
        }}
      />
    </div>
  );
};

export default withHeader(LeadsPage);
