import APIError from '../infra/rest/errors/api-error';
import { Dispatch, SetStateAction } from 'react';

interface ErrorMessage {
  property: string;
  message: string | string[];
}

type NotificationType = 'error' | 'success' | 'info' | 'warning';

export const handleApiError = ({
  error,
  mappingObject,
  errorFieldsInitialState,
  setErrorFields,
  addNotification,
  isInternational = false,
}: {
  error: unknown;
  mappingObject: Record<string, string> | ((isInternational: boolean) => Record<string, string>);
  errorFieldsInitialState: Record<string, string | null>;
  setErrorFields: Dispatch<SetStateAction<Record<string, string | null>>>;
  addNotification: (notification: { message: string; type: NotificationType }) => void;
  isInternational?: boolean;
}) => {
  // Cast the unknown error to APIError type
  const apiError = error as APIError;
  const errorMessage = apiError.originalMessage;
  
  // Get the actual mapping object, handling both direct object and function cases
  const actualMappingObject = typeof mappingObject === 'function' ? mappingObject(isInternational) : mappingObject;

  // Handle array of field-specific errors
  if (Array.isArray(errorMessage)) {
    const updatedErrorFields = { ...errorFieldsInitialState };

    /**
     * Type guard to ensure the error message has the expected structure
     * Checks if the message object has both 'property' and 'message' fields
     */
    const isValidErrorMessage = (msg: unknown): msg is ErrorMessage => {
      return typeof msg === 'object' && msg !== null && 'property' in msg && 'message' in msg;
    };

    // Process each error message in the array
    errorMessage.forEach((errorMsg) => {
      if (isValidErrorMessage(errorMsg)) {
        // Get the form field name corresponding to the API error field
        const mappedKey = actualMappingObject[errorMsg.property];
        
        // Only set error if the mapping exists and the field exists in our form
        if (mappedKey && mappedKey in updatedErrorFields) {
          // If message is an array, take the first message, otherwise use the message as is
          const value = Array.isArray(errorMsg.message) ? errorMsg.message[0] : errorMsg.message;
          updatedErrorFields[mappedKey] = value;
        }
      }
    });
    
    // Update the form's error state with all mapped errors
    setErrorFields(updatedErrorFields);
  } else {
    // Handle generic error case
    addNotification({
      message: (error as Error).message ?? 'An error occurred. Please try again',
      type: 'error',
    });
    // Reset all error fields to initial state
    setErrorFields(errorFieldsInitialState);
  }
}; 